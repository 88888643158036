import { Link, Route, Routes } from "react-router-dom"

import logo from '../../assets/images/LOGO_WEB_CAP_small.png'

import '../../assets/css/logo.css'

import { Empresas } from "../empresas/Empresas"
import { Usuarios } from "../usuarios/Usuarios"
import { Empleados } from "../empleados/Empleados"
import { Vehiculos } from "../vehiculos/Vehiculos"
import { Credenciales } from "../credenciales/Credenciales"
import { useState } from "react"
import { Accesos } from "../accesos/Accesos"
import { Externos } from "../externos/Externos"
import { useNavigate } from 'react-router-dom';
import { Dialog } from "primereact/dialog"
import { Password } from "primereact/password"
import { FloatLabel } from "primereact/floatlabel"
import { Divider } from "primereact/divider"
import { Button } from "primereact/button"
import LoginService from "../services/LoginService"

export const Home = () => {
  const user = JSON.parse(localStorage.getItem('usuario'));
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [password, setPassword] = useState({
    newPass: '',
    messageNewPass: '',
    checkPass: '',
    messageCheckPass: '',
    errorPasswordsMatch: ''
  });
  const [changePasswordDialog, setChangePasswordDialog] = useState({
    visible: false,
    icon: '',
    text: '',
    mode: '',
    resultMessage: ''
  });


  const navigate = useNavigate();

  const toggleMenu = () => {
    setIsCollapsed(!isCollapsed);
  };

  const handleMouseEnter = () => {
    if (isCollapsed) {
      setIsHovered(true);
    }
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const handleLogout = (e) => {
    e.preventDefault();

    // Borrar storage
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    localStorage.removeItem('expiracion');
    navigate('/login');
    // Recargar la página para iniciar una nueva sesión
    window.location.reload();
  };

  const onChangePassword = (e) => {

    e.preventDefault();

    setChangePasswordDialog({
      visible: true,
      icon: '',
      text: '',
      mode: 'formChangePassword',
      resultMessage: ''
    });

    setPassword({
      newPass: '',
      messageNewPass: '',
      checkPass: '',
      messageCheckPass: ''
    });
  }

  const onSavePasswords = async () => {

    // validar campos vacíos
    if (password.newPass === '') {

      setPassword({
        ...password,
        messageNewPass: 'Por favor ingrese nueva contraseña',
        messageCheckPass: '',
        errorPasswordsMatch: ''
      });
      return;

    }

    if (password.checkPass === '') {

      setPassword({
        ...password,
        messageNewPass: '',
        messageCheckPass: 'Por favor confirme nueva contraseña',
        errorPasswordsMatch: ''
      });
      return;

    }

    // paswords match
    if (password.newPass !== password.checkPass) {

      setPassword({
        ...password,
        messageNewPass: '',
        messageCheckPass: '',
        errorPasswordsMatch: 'Las contraseñas no coinciden'
      });
      return;

    } else {
      setPassword({
        ...password,
        messageNewPass: '',
        messageCheckPass: '',
        errorPasswordsMatch: ''
      });

    }

    // guardar
    const formLogin = {
      email     : user.email,
      password  : password.newPass,
    }

    const api = await LoginService.change(formLogin);

    if(api.data.status !== ''){
    // reportar resultados
      setChangePasswordDialog({
        ...changePasswordDialog,
        mode: 'resultChangePassword',
        icon: api.data.status === 'success' ? 'success' : api.data.status === 'error' ? 'error' : '',
        resultMessage: api.data.message
      });

    }else {
          // reportar resultados
          setChangePasswordDialog({
            ...changePasswordDialog,
            mode: 'resultChangePassword',
            icon: 'pi pi-times',
            resultMessage: 'Ha ocurrido una excepción, no ha sido posible realizar el cambio de contraseña.'
          });
    }


  }


  const footerFormChangePassword = (
    <>
      <Divider />
      <p className="mt-2">Sugerencias contraseña fuerte</p>
      <ul className="pl-2 ml-2 mt-0 line-height-3">
        <li>Al menos una minúscula</li>
        <li>Al menos una mayúscula</li>
        <li>Al menos un número</li>
        <li>Mínimo 8 characters</li>
      </ul>
    </>
  );

  const footerContent = (
    <div>

      {changePasswordDialog.mode === 'formChangePassword' ?
        <div>
          <Button label="Cancelar" icon="pi pi-times" className="p-button-text" onClick={() => setChangePasswordDialog({ ...changePasswordDialog, visible: false })} />
          <Button label="Grabar" icon="pi pi-save" onClick={onSavePasswords} />
        </div>
        : ''}

      {changePasswordDialog.mode === 'resultChangePassword' ?
              <div>
                <Button label="Aceptar" onClick={() => setChangePasswordDialog({ ...changePasswordDialog, visible: false })} />
              </div>
              : ''}

    </div>
  );

  const styles = {
    errors: {
      color: 'red',
      fontSize: '12px',
      fontWeight: 'bold',
    }
  };


  return (
    <div className={`vertical light ${isCollapsed ? 'collapsed' : ''} ${isHovered ? 'hover' : ''}`}>
      <div className="wrapper">
        <nav className="topnav navbar navbar-light">
          <button type="button" className="navbar-toggler text-muted mt-2 p-0 mr-3 collapseSidebar" onClick={toggleMenu}>
            <i className="fe fe-menu navbar-toggler-icon"></i>
          </button>

          <ul className="nav">
            <li className="nav-item nav-notif">
              <a className="nav-link text-muted my-2" href="./#" data-toggle="modal" data-target=".modal-notif">
                <span className="fe fe-bell fe-16"></span>
                <span className="dot dot-md bg-success"></span>
              </a>
            </li>
            <li className="nav-item dropdown">
              <span className="nav-link dropdown-toggle text-muted pr-0" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                <span className="avatar avatar-sm mt-2">
                  <img src="/assets/avatars/user.png" alt="..." className="avatar-img rounded-circle" />
                </span>
              </span>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
                <button onClick={onChangePassword} className="dropdown-item">Cambiar contraseña</button>
                <button onClick={handleLogout} className="dropdown-item">Cerrar sesión</button>

              </div>
            </li>
          </ul>
        </nav>

        <aside className="sidebar-left border-right bg-white shadow" id="leftSidebar" data-simplebar onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <a className="btn collapseSidebar toggle-btn d-lg-none text-muted ml-2 mt-3" data-toggle="toggle">
            <i className="fe fe-x"><span className="sr-only"></span></i>
          </a>
          <nav className="vertnav navbar navbar-light">
            {/* nav bar */}
            <div className="w-100 mb-4 d-flex">
              <a className="navbar-brand mx-auto mt-2 flex-fill text-center" href="./index.html">
                {!isCollapsed ?
                  <img src={logo} style={{ backgroundSize: 'contain', width: 205 }} title="SISTEMA CAP" alt="Descripción de la imagen" className="logoHome" />
                  :
                  isHovered ?
                    <img src={logo} style={{ backgroundSize: 'contain', width: 205 }} title="SISTEMA CAP" alt="Descripción de la imagen" className="logoHome" />
                    :
                    ''
                }
              </a>
            </div>
            <ul className="navbar-nav flex-fill w-100 mb-2">
              <li className="nav-item dropdown">
                <a href="#dashboard" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle nav-link">
                  <i className="fe fe-home fe-16"></i>
                  <span className="ml-3 item-text">Dashboard</span><span className="sr-only">(current)</span>
                </a>
                <ul className="collapse list-unstyled pl-4 w-100" id="dashboard">
                  <li className="nav-item active">
                    <a className="nav-link pl-3" href="./index.html"><span className="ml-1 item-text">Default</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link pl-3" href="./dashboard-analytics.html"><span className="ml-1 item-text">Analytics</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link pl-3" href="./dashboard-sales.html"><span className="ml-1 item-text">E-commerce</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link pl-3" href="./dashboard-saas.html"><span className="ml-1 item-text">Saas Dashboard</span></a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link pl-3" href="./dashboard-system.html"><span className="ml-1 item-text">Systems</span></a>
                  </li>
                </ul>
              </li>
            </ul>

            <p className="text-muted nav-heading mt-1 mb-1">
              <span>Administración y RRHH</span>
            </p>

            <ul className="navbar-nav flex-fill w-100 mb-2">
              <li className="nav-item dropdown">
                <a href="#ui-elements" data-toggle="collapse" aria-expanded="true" className="dropdown-toggle nav-link">
                  <i className="fe fe-box fe-16"></i>
                  <span className="ml-3 item-text">Administración y RRHH</span>
                </a>
                <ul className="collapse list-unstyled pl-4 w-100 show" id="ui-elements">
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/empresas'><span className="ml-1 item-text">Empresas</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/usuarios'><span className="ml-1 item-text">Usuarios</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/empleados'><span className="ml-1 item-text">Empleados</span></Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link pl-3" to='/externos'><span className="ml-1 item-text">Externos</span></Link>
                  </li> */}
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/vehiculos'><span className="ml-1 item-text">Vehículos</span></Link>
                  </li>
                </ul>
              </li>

              <li className="nav-item dropdown">
                <a href="#ui-credenciales" data-toggle="collapse" aria-expanded="false" className="dropdown-toggle nav-link">
                  <i className="fe fe-box fe-16"></i>
                  <span className="ml-3 item-text">Credenciales</span>
                </a>
                <ul className="collapse list-unstyled pl-4 w-100 show" id="ui-credenciales">
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/cindefinido'><span className="ml-1 item-text">Contrato Indefinidos</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/cpfijo'><span className="ml-1 item-text">Contratos Plazo Fijo</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/cexternos'><span className="ml-1 item-text">Externos</span></Link>
                  </li>
                  {/* <li className="nav-item">
                    <Link className="nav-link pl-3" to='/cproveedor'><span className="ml-1 item-text">Proveedores</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/ccliente'><span className="ml-1 item-text">Clientes</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/cvisita'><span className="ml-1 item-text">Visitas</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/cdescarte'><span className="ml-1 item-text">Descarte</span></Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link pl-3" to='/cconsumo'><span className="ml-1 item-text">Consumo</span></Link>
                  </li> */}

                </ul>
              </li>
            </ul>

            <p className="text-muted nav-heading mt-1 mb-1">
              <span>Control de Presencia</span>
            </p>

            <ul className="navbar-nav flex-fill w-100 mb-2">
              <li className="nav-item w-100">
                <Link className="nav-link pl-3" to='/accesos'>
                  <i className="fe fe-box fe-16"></i>
                  <span className="ml-3 item-text">Control de accesos</span>
                </Link>
              </li>
            </ul>

            <p className="text-muted nav-heading mt-1 mb-1">
              <span>Documentation</span>
            </p>
            <ul className="navbar-nav flex-fill w-100 mb-2">
              <li className="nav-item w-100">
                <a className="nav-link" href="../docs/index.html">
                  <i className="fe fe-help-circle fe-16"></i>
                  <span className="ml-3 item-text">Manual de usuario</span>
                </a>
              </li>
            </ul>

          </nav>
        </aside>
        <main role="main" className="main-content">
          <div className="container-fluid">

            <Routes>
              <Route path="/empresas" element={<Empresas />} />
              <Route path="/usuarios" element={<Usuarios />} />
              <Route path="/empleados" element={<Empleados />} />
              <Route path="/externos" element={<Externos />} />
              <Route path="/vehiculos" element={<Vehiculos />} />
              <Route path="/cindefinido" element={<Credenciales tipo={1} />} />
              <Route path="/cpfijo" element={<Credenciales tipo={2} />} />
              <Route path="/cproveedor" element={<Credenciales tipo={3} />} />
              <Route path="/ccliente" element={<Credenciales tipo={4} />} />
              <Route path="/cvisita" element={<Credenciales tipo={5} />} />
              <Route path="/cdescarte" element={<Credenciales tipo={6} />} />
              <Route path="/cconsumo" element={<Credenciales tipo={7} />} />
              <Route path="/cexternos" element={<Credenciales tipo={9999} />} />
              <Route path="/accesos" element={<Accesos />} />
            </Routes>


          </div>

        </main>
      </div>

      <div>
        <Dialog
          header="Formulario cambio de contraseña"
          visible={changePasswordDialog.visible}
          onHide={() => setChangePasswordDialog({ ...changePasswordDialog, visible: false })}
          footer={footerContent}
        >
          <div>
            {changePasswordDialog.mode === 'formChangePassword' ?
              <div className="col-12" style={{ display: "flex", flexDirection: 'column', alignItems: 'center' }}>
                <div className="row col-12 mt-4 mb-0 ml-0 mr-0 p-0">
                  <div className='col-12 m-0 p-0' style={{ width: '100%' }}>
                    <FloatLabel>
                      <Password
                        id="newPassword"
                        feedback={true}
                        promptLabel="Ingrese nueva contraseña"
                        weakLabel="Débil"
                        mediumLabel="Moderada"
                        strongLabel="Fuerte"
                        value={password.newPass}
                        onChange={(e) => setPassword({ ...password, newPass: e.target.value })}
                        toggleMask
                        footer={footerFormChangePassword}
                        unstyled={false}
                        inputStyle={{ width: '100%' }}
                        style={{ width: '100%' }}
                      />
                      <label for="newPassword">Nueva contraseña</label>
                    </FloatLabel>
                    {<p style={styles.errors}>{password.messageNewPass}</p>}
                  </div>
                </div>

                <div className="row col-12 mt-3 mb-0 ml-0 mr-0 p-0">
                  <div className='col-12  m-0 p-0'>
                    <FloatLabel>
                      <Password
                        id="checkPassword"
                        feedback={true}
                        promptLabel="Confirme contraseña"
                        weakLabel="Débil"
                        mediumLabel="Moderada"
                        strongLabel="Fuerte"
                        value={password.checkPass}
                        onChange={(e) => setPassword({ ...password, checkPass: e.target.value })}
                        toggleMask
                        footer={footerFormChangePassword}
                        inputStyle={{ width: '100%' }}
                        style={{ width: '100%' }}
                      />
                      <label for="checkPassword">Confirme contraseña</label>
                    </FloatLabel>
                    <p style={styles.errors}>{password.messageCheckPass}</p>
                  </div>
                </div>

                {password.errorPasswordsMatch !== '' ?
                <div style={{display: 'flex', flexDirection:'column', alignSelf: 'flex-start'}} className="ml-0 pl-0">
                  <p style={styles.errors} className="ml-0">{password.errorPasswordsMatch}</p>
                </div>
                  
                  : ''
                }
              </div>

              : ''}

            {changePasswordDialog.mode === 'resultChangePassword' ?
              <div className="row col-12">
                <div className="col-2">
                  {changePasswordDialog.icon === 'success' ? 
                    <i className="pi pi-check" style={{fontSize: 30, color: 'green', marginTop: '5px'}}></i>
                    : 
                    <i className="pi pi-check" style={{fontSize: 30, color: 'green', marginTop: '5px'}}></i>
                  }
                </div>
                <div className="col-10">
                  <p>{changePasswordDialog.resultMessage}</p>
                </div>
              </div>
              : ''}
          </div>

        </Dialog>
      </div>
    </div>
  )
}


