import React from 'react';
import cliente from './baseURL.tsx';

class TiposContratosService extends React.Component {

    obtener = async () => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('tcontratos/obtener', {}, config);

            return response;


        } catch (error) {
            console.error('Error al obtener tipos de contratos', error);
            return false;
        }
    };

    obtenerExternos = async() => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('tcontratos/externos/obtener', {}, config);

            return response;


        } catch (error) {
            console.error('Error al obtener tipos de externos', error);
            return false;
        }

    }
}

export default new TiposContratosService();