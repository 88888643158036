import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode'; // Cambiado de qrcode.react a qrcode
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from '../../assets/images/alpina.png'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const CredencialBusPDF = ({ generateCredencial }) => {

  const [busId, setBusId] = useState('');
  const [patente, setPatente] = useState('');
  const [logoEmpresa, setLogoEmpresa] = useState('');
  const [generaState, setGeneraState] = useState(false);


  const generatePDF = async () => {
    try {
      // Generar el código QR como un data URL
      const qrDataURL = await QRCode.toDataURL(patente.toString(), {
        errorCorrectionLevel: 'H', // Nivel de corrección de errores alto para mayor calidad
        margin: 1, // Margen mínimo
        scale: 8, // Ajusta la escala para obtener un código QR de alta calidad
      });

      // Convertir las dimensiones de centímetros a puntos
      const anchoEnCentimetros = 15;
      const altoEnCentimetros = 10;

      const anchoEnPuntos = (anchoEnCentimetros / 2.54) * 72;
      const altoEnPuntos = (altoEnCentimetros / 2.54) * 72;

      // Convertir las dimensiones de las columnas y filas a puntos
      const anchosColumnasEnCm = [3, 3, 3, 3.4, 0.3, 0.05, 0.04];
      const altoFilasEnCm = [1.2, 2.4, 3, 0.1, 0.01, 0.01, 0.01];

      const anchosColumnasEnPuntos = anchosColumnasEnCm.map(ancho => (ancho / 2.54) * 72);
      const altoFilasEnPuntos = altoFilasEnCm.map(alto => (alto / 2.54) * 72);

      // Definir la estructura del documento PDF
      const documentDefinition = {
        pageSize: {
          width: anchoEnPuntos,
          height: altoEnPuntos,
        },
        pageMargins: [0, 0, 0, 0],
        content: [
          // { text: `Nombre: ${nombreEmpleado}`, fontSize: 14, margin: [0, 0, 0, 20] },
          // { image: qrDataURL, width: 100, margin: [0, 0, 0, 20] },
          {
            table: {
              widths: anchosColumnasEnPuntos,
              heights: altoFilasEnPuntos,
              margin: [0, 0, 0, 0],
              padding: [0, 0, 0, 0],
              body: [
                [
                  {
                    image: logo,
                    colSpan: 4,
                    width: 200,
                    height: 60,
                    alignment: 'center',
                    margin: [0, 5, 0, 0],
                    padding: [0, 0, 0, 0],
                    border: [true, true, false, false]
                  },
                  '',
                  '',
                  '',
                  { text: '', fillColor: '#007634', border: [false, true, false, false] },
                  { text: '', border: [false, true, false, false] },
                  { text: '', fillColor: '#CD2529', border: [false, true, true, false] }
                ],
                [
                  { 
                    text: 'PATENTE BUS', 
                    fontSize: 25,
                    bold: true, 
                    colSpan: 2, 
                    alignment: 'center',
                    margin: [0, 30, 8, 0],
                    border: [true, false, false, false] 
                  },
                  { text: '', border: [false, false, false, false] },
                  {
                    image: qrDataURL,
                    colSpan: 2,
                    rowSpan: 5,
                    width: 170,
                    margin: [0, 10, 0, 0],
                    alignment: 'center',
                    border: [false, false, false, false]
                  },
                  { text: '', border: [false, false, false, false] },
                  {
                    text: '',
                    fillColor: '#007634',
                    color: 'white',
                    border: [false, false, false, false]
                  },
                  { text: '', border: [false, false, false, false] },
                  {
                    text: '',
                    fillColor: '#CD2529',
                    border: [false, false, true, false]
                  }
                ],
                [
                  { 
                    text: `${patente}`, 
                    colSpan: 2, 
                    fontSize: 35,
                    bold: true, 
                    alignment: 'center',
                    border: [true, false, false, false] 
                  },
                  { text: '', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', fillColor: '#CD2529', border: [false, false, true, false] }
                ],
                [
                  {
                    text: ``,
                    colSpan: 4,
                    fontSize: 8,
                    alignment: 'center',
                    margin: [0, 15, 8, 10],
                    border: [true, false, false, false]
                  },
                  { text: '', border: [false, false, false, false], colSpan: 3 },
                  '',
                  '',
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', fillColor: '#CD2529', border: [false, false, true, false] }
                ],
                [
                  {
                    text: ``,
                    fillColor: '#007634',
                    color: 'white',
                    fontSize: 20,
                    colSpan: 4,
                    rowSpan: 2,
                    alignment: 'center',
                    bold: true,
                    margin: [0, 4, 0, 0],
                    border: [true, false, false, false]
                  },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  {
                    image: qrDataURL,
                    colSpan: 4,
                    rowSpan: 5,
                    width: 50,
                    height: 50,
                    alignment: 'center',
                    fillColor: '#007634',
                    margin: [0, 4, 4, 0],
                    border: [false, false, true, false]
                  },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, true, false] },
                ],
                [
                  { text: '', fillColor: '#007634', border: [true, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, true, false] }
                ],
                [
                  {
                    text: '',
                    fillColor: '#007634',
                    colSpan: 3,
                    color: 'white',
                    alignment: 'center',
                    fontSize: 7,
                    border: [true, false, false, false]
                  },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, true, false] }
                ],
                [
                  { text: '', fillColor: '#007634', border: [true, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, true, false] }
                ],
              ],
            },
          },
        ]
      };

      // Generar el PDF y abrirlo en una nueva ventana
      pdfMake.createPdf(documentDefinition).open();
      setGeneraState(false);
    } catch (error) {
      //console.error('Error al generar el código QR y el PDF', error);
      setGeneraState(false);
    }
  };

  useEffect(() => {

    console.log(generateCredencial);
    if (generateCredencial.load === true) {
      setBusId(generateCredencial.id);
      setPatente(generateCredencial.placa_patente);
      setGeneraState(true);

    }

  }, [generateCredencial]);

  useEffect(() => {
    // reset form
    if (generaState) {
      generatePDF();
    }

  }, [generaState]);


}



