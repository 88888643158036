import React, { useEffect, useState, useRef } from 'react'
import UsuariosService from '../services/UsuariosService'
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Tag } from 'primereact/tag';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Card } from 'primereact/card';
import { format } from 'date-fns';

import AuthService from '../services/AuthService';
import { DataForm } from './DataForm';
import { DeleteForm } from './DeleteForm';

// import "primereact/resources/themes/lara-light-cyan/theme.css";

export const Usuarios = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated);
  const [load, setLoad] = useState(true);
  const [usuarios, setUsuarios] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusDeleteDialog, setStatusDeleteDialog] = useState(false);

  const dt = useRef(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });


  const rutTemplate = (rowData) => {
    return <span>{rowData.rut}-{rowData.dv}</span>;
  };

  const rolTemplate = (rowData) => {
    return <span><Tag severity="success" value={rowData.tipo_usuario} rounded></Tag></span>
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editEmpresa(rowData)} />
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteUsuario(rowData)} />
      </React.Fragment>
    );
  };


  const obtener = async () => {

    if (isAuthenticated) {

      const respuesta = await UsuariosService.obtener();

      if(respuesta){
        setUsuarios(respuesta.data.usuarios);
      }
      
    } else {

      window.location.reload();
    }
  }

  const addUsuario = () => {
    setStatusDialog(true);
    setStatusDeleteDialog(false);
    setEditing(false);
    setUsuario(null);

  }

  const onHideDialog = () => {
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setStatusDialog(false);
  };

  const onHideDeleteDialog = () => {
    setStatusDeleteDialog(false);
    setStatusDialog(false);
  };

  const onRefresh = (e) => {

    setLoad(e);
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setDeleting(false);
    setEditing(false);
  }

  const onDeleteRefresh = (e) => {

    setLoad(e);
    setStatusDeleteDialog(false);
    setDeleting(false);
    setStatusDialog(false);
  }


  const editEmpresa = (rowData) => {

    setStatusDialog(true);
    setStatusDeleteDialog(false);
    setEditing(true);
    setDeleting(false);
    setUsuario(rowData);
  }

  const confirmDeleteUsuario = (rowData) => {

    setStatusDeleteDialog(true);
    setDeleting(true);
    setUsuario(rowData);

  }

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
  };

  //const exportColumns = empresas.map((col) => ({ title: col.header, dataKey: col.field }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const generateExportFileName = () => {
    return `Usuarios_${format(new Date(), 'yyyyMMdd_HHmmss')}`;
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <span className="p-input-icon-left">
        <IconField iconPosition='left'>
          <InputIcon className="pi pi-search" />
          <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
        </IconField>
      </span>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button label="Nuevo" icon="pi pi-plus" severity="success" onClick={() => addUsuario()} />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2">
        <Button type="button" icon="pi pi-file-excel" label='Exportar' rounded onClick={() => exportCSV()} data-pr-tooltip="CSV" />
      </div>
    );
  };

  const header = renderHeader();

  useEffect(() => {
    // Este bloque de código se ejecutará cada vez que cambie AuthService.isAuthenticated
    if (AuthService.isAuthenticated) {
      // Lógica cuando isAuthenticated es true
    } else {
      // Lógica cuando isAuthenticated es false
      window.location.reload();
    }
  }, [AuthService.isAuthenticated]);

  useEffect(() => {

    if (load === true) {
      obtener();

      setLoad(false);
    }

  }, [load]);



  return (
    <div className='row justify-content-center'>
      <div className='col-12'>
        <h1>Usuarios</h1>
        <p>Lista de usuarios registrados</p>
        <div className='row'>
          <div className='col-12 my-4'>

            <Card>

              <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

              <DataTable
                ref={dt}
                value={usuarios}
                header={header}
                exportFilename={generateExportFileName()}
                csvSeparator=';'
                emptyMessage="No existen usuarios ingresados"
                filters={filters}
                paginator rows={10}
                rowsPerPageOptions={[10, 25, 50]}
                size='small'
              >
                <Column field="name" header="Nombre"></Column>
                <Column body={rutTemplate} header="Rut"></Column>
                <Column field="rut" header="Rut" style={{ display: 'none' }} exportable={false}></Column>
                <Column field="telefono_movil" header="Teléfono"></Column>
                <Column field="email" header="Correo Electrónico"></Column>
                <Column field="nombre_empresa" header="Empresa"></Column>
                <Column body={rolTemplate} header="Rol"
                  headerStyle={{}}
                  alignHeader={'center'}
                  bodyStyle={{ textAlign: 'center' }}
                ></Column>
                <Column
                  body={actionBodyTemplate}
                  header="Acciones"
                  exportable={false}
                  style={{ minWidth: '12rem' }}
                  alignHeader={'center'}
                  bodyStyle={{ textAlign: 'center' }}
                ></Column>
              </DataTable>

            </Card>

            <DataForm
              statusDialog={statusDialog}
              onHideDialog={onHideDialog}
              onRefresh={onRefresh}
              dataForm={usuario}
              editing={editing}
              headerTitle={editing ? 'Editar usuario' : 'Nuevo usuario'}
              loadForm={true}
            />

            <DeleteForm
              visibleDeleteDialog={statusDeleteDialog}
              onHideDeleteDialog={onHideDeleteDialog}
              onDeleteRefresh={onDeleteRefresh}
              dataForm={usuario}
              deleting={deleting}
              loadForm={true}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
