import React from 'react';
import cliente from './baseURL.tsx';

class CredencialesService extends React.Component {
  
    obtener = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('credenciales/obtener', {data}, config);

            return response;

                
        } catch (error) {
            console.error('Error al obtener credenciales', error);
            return false;
        }
  };

    crear = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('credenciales/crear', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al crear empresa', error);
            return false;
        }
    };


    actualizar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empresas/actualizar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al actualizar empresa', error);
            return false;
        }
    };
    
    
    eliminar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('credenciales/eliminar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al eliminar credencial', error);
            return false;
        }
    }; 
    
    
    asignar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('credenciales/asignar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al asinar credencial', error);
            return false;
        }
    };


    asignarExterno = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('credenciales/asignar/externo', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al asinar credencial', error);
            return false;
        }
    };

    desasociar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('credenciales/desasociar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al desasociar credencial', error);
            return false;
        }
    };

    eliminar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('credenciales/eliminar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al desasociar credencial', error);
            return false;
        }
    };

}

export default new CredencialesService();