import React, { useEffect, useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';
import { Toast } from 'primereact/toast';
import { InputNumber } from 'primereact/inputnumber';
import CredencialesService from '../services/CredencialesService';
import TiposContratosService from '../services/TiposContratosService';
import { Dropdown } from 'primereact/dropdown';

export const NewForm = ({ statusNewDialog, onHideNewDialog, tipoContrato, onRefresh }) => {


    const [value1, setValue1] = useState(0);
    const [credencialDialog, setCredencialDialog] = useState(false);
    const [titulo, setTitulo] = useState('');
    const [tcontratos, setTContratos] = useState(null);
    const [tcontratoSelected, setTContratoSelected] = useState(null);
    const [tcontratoError, setTContratoError] = useState('');

    const [submitted, setSubmitted] = useState(false);

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const [formData, setFormData] = useState({
        cantidad: '',
        contrato_tipo_id: tipoContrato,
        disponible: 1,
        created_id: user ? user.id : ''
    });


    const hideDialog = () => {
        setSubmitted(false);
        setCredencialDialog(false);
        onHideNewDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };

    const obtenerTiposContratosExternos = async () => {

        const response = await TiposContratosService.obtenerExternos();

        if (response) {
            setTContratos(response.data.tcontratos);
        }
    }

    const changeTContrato = (e) => {
        //setFirstLoad(false);
        setTContratoSelected(e.value);
    };

    const resetForm = () => {

        setValue1(0);
    }


    const save = async (e) => {

        e.preventDefault();

        //setSubmitted(true);
        console.log(formData);
        // valida rol


        // validación exitosa
        let api = '';

        api = await CredencialesService.crear(formData);


        if (api) {
            if (api.data.status === 'success') {
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(true);
                setSubmitted(false);
                setCredencialDialog(false);
                onHideNewDialog();
            } else {
                toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(false);
                setSubmitted(false);
                setCredencialDialog(false);
                onHideNewDialog();
            }
        }



    }


    const footerContent = (

        <div className='row'>

            <div className='col-md-7 text-muted' style={{ textAlign: 'left' }}>
            </div>
            <div className='col-md-5' style={{ textAlign: 'right' }}>
                <Button label="Cancelar" icon="pi pi-times" onClick={onHideNewDialog} className="p-button-text" />
                <Button label="Grabar" icon="pi pi-save" onClick={save} />
            </div>
        </div>

    );

    useEffect(() => {

        // Actualizar empresa change
        setCredencialDialog(statusNewDialog);

    }, [statusNewDialog])


    useEffect(() => {

        // Actualizar empresa change
        setFormData((prevData) => ({ ...prevData, cantidad: value1 }));

    }, [value1]);

    useEffect(() => {

        // Actualizar empresa change
        setFormData((prevData) => ({ ...prevData, contrato_tipo_id: tipoContrato }));


    }, [tipoContrato]);

    useEffect(() => {

        // Actualizar empresa change
       if (parseInt(tipoContrato) === 9999) {
        setFormData((prevData) => ({ ...prevData, contrato_tipo_id: tcontratoSelected  ? tcontratoSelected.id : ''}));
       }

    }, [tcontratoSelected]);


    useEffect(() => {

        // Actualizar empresa change

        console.log(formData);

    }, [formData])


    useEffect(() => {

        // definir títulos
        if (parseInt(tipoContrato) === 1) {
            setTitulo("Generar credenciales para Contratos Indefinidos");
        }

        if (parseInt(tipoContrato) === 2) {
            setTitulo("Generar credenciales para Contratos Plazo Fijo");
        }

        if (parseInt(tipoContrato) === 3) {
            setTitulo("Generar credenciales para Proveedores");
        }

        if (parseInt(tipoContrato) === 4) {
            setTitulo("Generar credenciales para Clientes");
        }

        if (parseInt(tipoContrato) === 5) {
            setTitulo("Generar credenciales para Visitas");
        }

        if (parseInt(tipoContrato) === 6) {
            setTitulo("Generar credenciales para Descarte");
        }

        if (parseInt(tipoContrato) === 7) {
            setTitulo("Generar credenciales para Consumo");
        }

        if (parseInt(tipoContrato) === 9999) {
            setTitulo("Generar credenciales Genéricas");
            obtenerTiposContratosExternos();
        }

    }, [tipoContrato]);

    useEffect(() => {

        resetForm();

    }, [statusNewDialog])


    return (
        <div>
            <Toast ref={toast} />
            <Dialog header={titulo} maximizable visible={credencialDialog} footer={footerContent} onHide={hideDialog} style={{ width: '50vw' }}>

                <form>

                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mb-1">
                                <div className="flex-auto">
                                    <label htmlFor="horizontal-buttons" className="font-bold block mb-2">Cantidad de credenciales</label>
                                    <InputNumber
                                        inputId="horizontal-buttons"
                                        value={value1}
                                        onValueChange={(e) => setValue1(e.value)}
                                        showButtons
                                        buttonLayout="horizontal"
                                        step={1}
                                        decrementButtonClassName="p-button-danger"
                                        incrementButtonClassName="p-button-success"
                                        incrementButtonIcon="pi pi-plus"
                                        decrementButtonIcon="pi pi-minus"
                                        mode="decimal" />
                                </div>

                            </div>
                        </div>


                        {parseInt(tipoContrato) === 9999 ?
                            <div className='col-6'>
                                <div className="form-group mb3">
                                    <label htmlFor="id_empresa" className="font-bold">Tipo de credencial genérica</label>
                                    <Dropdown
                                        id="tcredencial"
                                        name="tcredencial"
                                        value={tcontratoSelected}
                                        onChange={changeTContrato}
                                        options={tcontratos}
                                        optionLabel={(option) => `${option.nombre}`}
                                        filter placeholder="Seleccione tipo de credencial"
                                        className="w-full" />
                                    {tcontratoError.status === 'error' && <small className="p-error">{tcontratoError.message}</small>}
                                </div>
                            </div>
                            : ''}

                    </div>

                </form>
            </Dialog>
        </div>
    )
}
