import { Routes, Route } from 'react-router-dom';
import { Home } from '../components/home/Home.jsx';
import { Login } from '../components/login/Login.jsx';



export const AppRouter = () => {
  return (
    <>

            <Routes >
                <Route path='/' component={<Home />} /> 
                <Route path='/home' component={<Home />} /> 
                <Route path='/login' elemen={<Login />} /> 
            </Routes >   
    </>
  )
}
