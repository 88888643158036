import React from 'react';
import cliente from './baseURL.tsx';

class VehiculosService extends React.Component {
  
    obtener = async () => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('vehiculos/obtener', {}, config);

            return response;

                
        } catch (error) {
            console.error('Error al obtener vehiculos', error);
            return false;
        }
    };

    crear = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('vehiculos/crear', {data}, config);

            return response;

                
        } catch (error) {
            console.error('Error al crear vehículo', error);
            return false;
        }
    };


    actualizar = async (data, file) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('vehiculos/actualizar', {data}, config);

            return response;

                
        } catch (error) {
            console.error('Error al actualizar vehículo', error);
            return false;
        }
    };
    
    // servicio único para buses
    asociarLector = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };
            console.log(data);
            const response = await cliente.post('vehiculos/lector/asociar', {data}, config);
            return response;
                
        } catch (error) {
            console.error('Error al asociar lector de credencial a bus', error);
            return false;
        }
    };
    
    eliminar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('vehiculos/eliminar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al eliminar vehículo', error);
            return false;
        }
    };   
    

    eliminarLector = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('vehiculos/lector/eliminar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al eliminar vehículo', error);
            return false;
        }
    }; 

    obtenerCTCDisponibles = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empleados/CTCDisponibles', { 'contratoTipoId' : data }, config);

            return response;

                
        } catch (error) {
            console.error('Error al obtener empleados sin credenciales', error);
            return false;
        }
    };

    asignarAsientos = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('asientos/asignar', {data}, config);

            return response;

                
        } catch (error) {
            console.error('Error al asignar asiento', error);
            return false;
        }
    };

}

export default new VehiculosService();