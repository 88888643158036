import React, { useEffect, useState, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import EmpleadosService from '../services/EmpleadosService';

export const DeleteForm = ({ visibleDeleteDialog, onHideDeleteDialog, onDeleteRefresh, dataForm, deleting }) => {

    const [usuarioDeleteDialog, setUsuarioDeleteDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const reject = () => {
        onHideDeleteDialog();
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const accept = async () => {

        //event.preventDefault(); // evita refresco de navegador
        setSubmitted(true);
        console.log("eliminando");
        // console.log(formData);
        console.log(dataForm);

        setFormData((prevData) => (
            {
                ...prevData,
                id: dataForm.id,
                name: dataForm.name,
                rut: `${dataForm.rut}-${dataForm.dv}`,
                email: dataForm.email,
                telefono_movil: dataForm.telefono_movil,
                password: '',
                password_repeat: '',
                created_id: dataForm.created_id,
                created_at: dataForm.created_at,
                created_name: dataForm.created_name,
                updated_id: user ? user.id : '',
                updated_at: dataForm.updated_at,
                updated_name: dataForm.updated_name,
                deleted_id: user ? user.id : '',
                deleted_at: dataForm.deleted_at,
                deleted_name: dataForm.deleted_name


            }));

    }

    const confirm2 = (nombre) => {
        confirmDialog({
            message: `¿Desea eliminar al usuario ${nombre}?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };

    const [formData, setFormData] = useState({
        id: '',
        nombre: '',
        rut: '',
        direccion: '',
        telefono: '',
        created_id: '',
        created_at: '',
        created_name: '',
        updated_id: '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: '',
    });




    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDeleteDialog(false);
        onHideDeleteDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };



    useEffect(() => {

        setUsuarioDeleteDialog(visibleDeleteDialog);

        if (visibleDeleteDialog && dataForm && deleting) {

            // Carga los datos de selectedRecord a formData


            setFormData((prevData) => (
                {
                    ...prevData,
                    id: dataForm.id,
                    nombre: dataForm.nombre,
                    rut: `${dataForm.rut}-${dataForm.dv}`,
                    email: dataForm.email,
                    telefono_movil: dataForm.telefono_movil,
                    password: '',
                    password_repeat: '',
                    created_id: dataForm.created_id,
                    created_at: dataForm.created_at,
                    created_name: dataForm.created_name,
                    updated_id: user ? user.id : '',
                    updated_at: dataForm.updated_at,
                    updated_name: dataForm.updated_name,
                    deleted_id: user ? user.id : '',
                    deleted_at: dataForm.deleted_at,
                    deleted_name: dataForm.deleted_name
                }));

            confirm2(dataForm.nombre);


        }


    }, [visibleDeleteDialog, dataForm, deleting]);

    useEffect(() => {
        
        const eliminarEmpleado = async () => {
            if (submitted) {
                console.log("Eliminar");
                console.log(formData);
                setSubmitted(false);
    
                try {
                    const api = await EmpleadosService.eliminar(formData);
                    if (api.data.status === 'success') {
                        onDeleteRefresh(true);
                        setUsuarioDeleteDialog(false);
                        onHideDeleteDialog();
                        toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                    } else {
                        toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                        onDeleteRefresh(false);
                        setUsuarioDeleteDialog(false);
                        onHideDeleteDialog();
                    }
                } catch (error) {
                    console.error('Error al eliminar empleado:', error);
                    toast.current.show({ severity: 'error', summary: 'Mensaje', detail: 'Error al comunicarse con el servidor', life: 3000 });
                }
            }
        };

        eliminarEmpleado();

    }, [formData]);

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog visible={usuarioDeleteDialog} onHide={hideDialog} />
        </div>
    )
}
