import React, { useEffect, useRef, useState } from 'react'
import EmpleadosService from '../services/EmpleadosService';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import CredencialesService from '../services/CredencialesService';
import { Toast } from 'primereact/toast';
import TiposContratosService from '../services/TiposContratosService';
import { Checkbox } from 'primereact/checkbox';

export const AsignaCredencialForm = ({ credencialSel, onHideDialog, onRefresh }) => {

  const [credencialDialog, setCredencialDialog] = useState(false);
  const [codCredencial, setCodCredencial] = useState('');
  const [codTContrato, setCodTContrato] = useState('');
  const [empleados, setEmpleados] = useState(null);
  const [empleadoSelected, setEmpleadoSelected] = useState(null);
  const [chkNombreRut, setChkNombreRut] = useState(false);
  const [nombre, setNombre] = useState('');
  const [rut, setRut] = useState('');

  // validación de errores
  const [credencialError, setCredencialError] = useState('');
  const [empleadoError, setEmpleadoError] = useState('');
  const [rutError, setRutError] = useState('');
  const [nombreError, setNombreError] = useState('');

  const user = JSON.parse(localStorage.getItem('usuario'));
  const toast = useRef(null);

  const [formData, setFormData] = useState({
    credencial_id: '',
    empleado_id: parseInt(credencialSel.tipoC) === 9999 ? null : '',
    contrato_id: credencialSel.contrato_id,
    nombre: '',
    rut: '',
    updated_id: user ? user.id : ''
  });

  const obtenerCTCDisponibles = async (data) => {

    if (parseInt(credencialSel.tipoC) !== 9999) {
      const response = await EmpleadosService.obtenerCTCDisponibles(data);

      if (response) {
        setEmpleados(response.data.empleados);
      }
    }

  }


  const changeEmpleado = (e) => {
    //setFirstLoad(false);
    setEmpleadoSelected(e.value);
  };


  const save = async (e) => {

    e.preventDefault();

    if (formData.credencial_id === '') {

      setCredencialError({ status: 'error', message: `Ingrese N° Credencial ❌` });
      return;

    } else {

      setCredencialError({ status: '', message: `` });
    }

    if (formData.empleado_id === '') {

      setEmpleadoError({ status: 'error', message: `Seleccione Empleado ❌` });
      return;

    } else {

      setEmpleadoError({ status: '', message: `` });
    }

    const api = await CredencialesService.asignar(formData);

    console.log(api);
    if (api.data.status === 'success') {

      //onDeleteRefresh(true);
      //setUsuarioDeleteDialog(false);
      onRefresh(true);
      hideDialog();

      toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
    } else {
      toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
      //onDeleteRefresh(false);
      //setUsuarioDeleteDialog(false);
      onRefresh(false);
      hideDialog();
    }

  }

  const saveExterno = async (e) => {

    console.log(credencialSel);

    e.preventDefault();

    if (formData.credencial_id === '') {

      setCredencialError({ status: 'error', message: `Ingrese N° Credencial ❌` });
      return;

    } else {

      setCredencialError({ status: '', message: `` });
    }

    if (parseInt(credencialSel.tipoC) === 9999 && chkNombreRut) {
      if (formData.nombre === '') {
        setNombreError({ status: 'error', message: `Ingrese Nombre ❌` });
        return;
      } else {
        setNombreError({ status: '', message: `` });
      }

      if (formData.rut === '') {
        setRutError({ status: 'error', message: `Ingrese RUT ❌` });
        return;
      } else {
        setRutError({ status: '', message: `` });
      }
    } else {
      setNombreError({ status: '', message: `` });
      setRutError({ status: '', message: `` });
    }


    console.log(formData);
    const api = await CredencialesService.asignarExterno(formData);

    console.log(api);
    if (api.data.status === 'success') {

      //onDeleteRefresh(true);
      //setUsuarioDeleteDialog(false);
      onRefresh(true);
      hideDialog();

      toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
    } else {
      toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
      //onDeleteRefresh(false);
      //setUsuarioDeleteDialog(false);
      onRefresh(false);
      hideDialog();
    }

  }

  const hideDialog = () => {
    setCredencialDialog(false);
    onHideDialog();
  }

  const footerContent = (

    <div className='row'>

      <div className='col-md-7 text-muted' style={{ textAlign: 'left' }}>
        {/* {dataForm && dataForm.created_id !== null ? <small><i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i> {dataForm.created_name} el {dataForm.created_at}<br /></small> : ''}
            {dataForm && dataForm.updated_id !== null ? <small><i className='pi pi-user-edit' style={{ fontSize: '1rem' }}></i> {dataForm.updated_name} el {dataForm.updated_at}</small> : ''} */}
      </div>
      <div className='col-md-5' style={{ textAlign: 'right' }}>
        <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
        {parseInt(credencialSel.tipoC) !== 9999 ? <Button label="Grabar" icon="pi pi-save" onClick={save} /> : ''}
        {parseInt(credencialSel.tipoC) === 9999 ? <Button label="Grabar" icon="pi pi-save" onClick={saveExterno} /> : ''}
      </div>
    </div>

  );

  const formatRut = (rut) => {
    // Eliminar caracteres no numéricos
    const cleanedValue = rut.replace(/[^kK\d]/g, '');

    // Separar el RUT en parte numérica y dígito verificador
    const rutParteNumerica = cleanedValue.slice(0, -1);
    const rutDV = cleanedValue.slice(-1);

    // Aplicar formato con puntos y guión
    let formattedRut = '';
    let i = rutParteNumerica.length;

    while (i > 0) {
      formattedRut = rutParteNumerica.slice(Math.max(0, i - 3), i) + (formattedRut ? '.' + formattedRut : '');
      i -= 3;
    }

    return formattedRut + '-' + rutDV;
  };

  const onChangeRut = (e) => {
    console.log(e.target.value);

    // formatear RUT
    const inputRut = e.target.value;
    const formattedRut = formatRut(inputRut);

    // Actualizar el estado con el RUT formateado
    setFormData((prevData) => ({ ...prevData, rut: formattedRut }));

    // Validar el RUT al cambiar
    const isValid = validarRut(e.target.value);

    if (!isValid) {
      setRutError({ status: 'error', message: `RUT no válido ❌` });
    } else if (isValid) {
      setRutError({ status: 'success', message: `RUT válido ✅` });
    } else if (formData.rut === '') {
      setRutError({ status: 'success', message: `` });
    }
  }

  const validarRut = (rut) => {
    rut = rut.replace(/\./g, '').replace(/-/g, '');

    if (!/^[0-9]+[0-9kK]{1}$/.test(rut)) {
      return false;
    }

    const rutNumerico = parseInt(rut.slice(0, -1), 10);
    const verificador = rut.slice(-1).toLowerCase();
    const dvEsperado = calcularDigitoVerificador(rutNumerico);

    if (rut.length > 7) { // este validador permite que no entregue true antes de tiempo
      return dvEsperado === verificador;
    } else {
      return false;
    }

  };

  const calcularDigitoVerificador = (rutNumerico) => {
    let m = 0;
    let s = 1;

    for (; rutNumerico; rutNumerico = Math.floor(rutNumerico / 10)) {
      s = (s + rutNumerico % 10 * (9 - m++ % 6)) % 11;
    }

    return (s ? s - 1 : 'k').toString();
  };

  useEffect(() => {

    setFormData((prevData) => (
      {
        ...prevData,
        contrato_id: credencialSel.contrato_id,
        nombre: '',
        rut: ''
      }
    ));

    setCodCredencial(credencialSel.cod);
    setCodTContrato(credencialSel.tipoC);
    setChkNombreRut(false);
    setRutError('');
    setNombreError('');

    console.log(credencialSel);
    // obtener solo si son empleados
    if (parseInt(credencialSel.tipoC) !== 9999) { obtenerCTCDisponibles(credencialSel.tipoC); };
    setCredencialDialog(credencialSel.status);
  }, [credencialSel])

  useEffect(() => {

    // Actualizar empresa change
    setFormData((prevData) => ({ ...prevData, empleado_id: empleadoSelected }));

  }, [empleadoSelected])


  useEffect(() => {

    // Actualizar empresa change
    setFormData((prevData) => ({ ...prevData, credencial_id: codCredencial }));

  }, [codCredencial])

  return (
    <>
      <Toast ref={toast} />
      <Dialog header="Asignar credencial" maximizable visible={credencialDialog} footer={footerContent} onHide={hideDialog} style={{ width: '50vw' }}>
        <form>
          <div className='row'>
            <div className='col-5'>
              <div className="form-group mb3">
                <label htmlFor="id_empresa" className="font-bold">Credencial a Asignar</label>
                <InputText
                  value={String(codCredencial).padStart(7, '0')}
                  disabled={true}
                  className="w-full"
                />
                {credencialError.status === 'error' && <small className="p-error">{credencialError.message}</small>}
              </div>
            </div>


            {parseInt(credencialSel.tipoC) !== 9999 ?
              <div className='col-6'>
                <div className="form-group mb3">
                  <label htmlFor="id_empresa" className="font-bold">Empleado</label>
                  <Dropdown
                    id="empleado"
                    name="empleado"
                    value={empleadoSelected}
                    onChange={changeEmpleado}
                    options={empleados}
                    optionLabel={(option) => `${option.nombre_empleado} - ${option.rut_empleado}`}
                    filter placeholder="Seleccione empleado"
                    className="w-full" />
                  {empleadoError.status === 'error' && <small className="p-error">{empleadoError.message}</small>}
                </div>
              </div>
              : ''}

            {parseInt(credencialSel.tipoC) === 9999 ?
              <div className='col-6'>
                <div className="form-group mb3">
                  <label htmlFor="id_empresa" className="font-bold">Tipo Credencial</label>
                  <InputText
                    value={`GENÉRICA`}
                    disabled={true}
                    className="w-full"
                  />
                  {empleadoError.status === 'error' && <small className="p-error">{empleadoError.message}</small>}
                </div>
              </div>
              : ''}

          </div>

          {parseInt(credencialSel.tipoC) === 9999 ?
            <div className='row'>
              <div className='col-6'>
                <Checkbox inputId='nombre_rut' value={false} onChange={e => setChkNombreRut(e.checked)} checked={chkNombreRut} />
                <label htmlFor='nombre_rut' className='ml-2 mb-0'>¿Ingresar nombre y RUT?</label>
              </div>
            </div>
            : ''}

          {parseInt(credencialSel.tipoC) === 9999 && chkNombreRut ?
            <div className='row'>
              <div className='col-5'>
                <div className="form-group mb3">
                  <label htmlFor="id_empresa" className="font-bold">Nombre</label>
                  <InputText
                    id='nombre'
                    name='nombre'
                    value={formData.nombre}
                    onChange={(e) => setFormData((prevData) => ({ ...prevData, nombre: e.target.value }))}
                    className='w-full'
                  />
                  {nombreError.status === 'error' && <small className="p-error">{nombreError.message}</small>}
                </div>
              </div>

              <div className='col-6'>
                <label htmlFor="id_empresa" className="font-bold">RUT</label>
                <InputText
                  id="rut"
                  name='rut'
                  value={formData.rut}
                  onChange={onChangeRut}
                  required={parseInt(credencialSel.tipoC) === 9999 && chkNombreRut ? true : false}
                  className='w-full'
                />
                {rutError.status === 'error' && <small className="p-error">{rutError.message}</small>}
                {rutError.status === 'success' && <small className="p-messages-success" style={{ color: 'green' }}>{rutError.message}</small>}
              </div>
            </div>
            : ''}

        </form>
      </Dialog>

    </>
  )
}
