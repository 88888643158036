import React, { useEffect, useState, useRef } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import CredencialesService from '../services/CredencialesService';

export const DeleteForm = ({ credencialDel, onHideDeleteDialog, onDeleteRefresh }) => {

    const [deleteDialog, setDeleteDialog] = useState(false);
    const [credencialId, setCredencialId] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const reject = () => {
        onHideDeleteDialog();
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const accept = async() => {

        //event.preventDefault(); // evita refresco de navegador
        setSubmitted(true);
        console.log("eliminando");

        const params = {
            'credencial_id': credencialId,
        }
            
        const api = await CredencialesService.eliminar(params);

        console.log(api);
        if (api.data.status === 'success') {
        
            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
            onDeleteRefresh(true);

        } else {

            toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
            onDeleteRefresh(false);

        }

        onHideDeleteDialog();
        
    }


    const hideDialog = () => {
        setSubmitted(false);
        setDeleteDialog(false);
        onHideDeleteDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };


    useEffect(() => {

        setDeleteDialog(credencialDel.status);

    }, [credencialDel]);


    useEffect(() => {

        setCredencialId(credencialDel.credencial_id);

    }, [deleteDialog]);

    

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog 
                visible={deleteDialog} 
                onHide={hideDialog} 
                message={`¿Desea eliminar la credencial ${String(credencialId).padStart(7, '0')}?`} 
                header="Confirmación" 
                rejectClassName='p-button-danger mr-3'
                acceptLabel='Sí'
                rejectLabel='No'
                icon='pi pi-info-circle'
                accept={accept}
                reject={reject}
            />
        </div>
    )
}
