import React, { useEffect, useState, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import VehiculosService from '../services/VehiculosService';

export const DeleteForm = ({ visibleDeleteDialog, onHideDeleteDialog, onDeleteRefresh, dataForm, mode, deleting }) => {

    const [vehiculoDeleteDialog, setVehiculoDeleteDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const [formData, setFormData] = useState({
        id: null,
        lector_id: '',
        patente: '',
        deleted_id: null,
    });

    const reject = () => {
        onHideDeleteDialog();
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const accept = async () => {

        //event.preventDefault(); // evita refresco de navegador

        const api = await VehiculosService.eliminar(formData);

        if (api) {
            if (api.data.status === 'success') {

                onDeleteRefresh(true);
                setSubmitted(false);
                setVehiculoDeleteDialog(false);
                onHideDeleteDialog();

                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onDeleteRefresh(false);
                setSubmitted(false);
                setVehiculoDeleteDialog(false);
                onHideDeleteDialog();
            }
        }

    }

    const confirm2 = (nombre) => {
        confirmDialog({
            message: `¿Desea eliminar al vehiculo patente: ${nombre}?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject,
            acceptLabel: 'Si',
            rejectLabel: 'Cancelar'
        });
    };

    const confirmLector = (lectorId, patente) => {
        confirmDialog({
            message: `¿Desea eliminar el lector de credencial ${lectorId} asociado al bus con patente ${patente}?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept: acceptLector,
            reject,
            acceptLabel: 'Si',
            rejectLabel: 'Cancelar'
        });
    };

    const acceptLector = async () => {

        //event.preventDefault(); // evita refresco de navegador

        const api = await VehiculosService.eliminarLector(formData);

        if (api) {
            if (api.data.status === 'success') {

                onDeleteRefresh(true);
                setSubmitted(false);
                setVehiculoDeleteDialog(false);
                onHideDeleteDialog();

                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onDeleteRefresh(false);
                setSubmitted(false);
                setVehiculoDeleteDialog(false);
                onHideDeleteDialog();
            }
        }

    }


    const hideDialog = () => {
        setSubmitted(false);
        setVehiculoDeleteDialog(false);
        onHideDeleteDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };


    useEffect(() => {

        setVehiculoDeleteDialog(visibleDeleteDialog);

        if (visibleDeleteDialog && dataForm && deleting) {
            console.log("DataForm:", dataForm); // Verifica el valor de dataForm
            // Carga los datos de selectedRecord a formData
            console.log(formData);
            setFormData(prevData => ({
                ...prevData,
                id: dataForm.id || '',
                patente: dataForm.placa_patente || '',
                lector_id: dataForm.lector_id || '',
                deleted_id: user ? user.id : '',
            }));           

        }


    }, [visibleDeleteDialog, dataForm, deleting]);


    useEffect(() => {

        // Actualizar empresa change
        //setFormData((prevData) => ({ ...prevData, foto: selectedFile }));
        if (visibleDeleteDialog && dataForm && deleting) {
            if(mode === 'vehiculo'){
                confirm2(formData.patente);
            }
        
            if(mode === 'lector'){
                confirmLector(formData.lector_id, formData.patente);
            }
        }

    }, [formData])
    

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog visible={vehiculoDeleteDialog} onHide={hideDialog} />
        </div>
    )
}
