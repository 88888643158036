import React, { useEffect, useState, useRef } from 'react'
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Card } from 'primereact/card';
import { Dialog } from 'primereact/dialog';
import { Editor } from "primereact/editor";
import { Calendar } from "primereact/calendar";
import { addLocale } from 'primereact/api';
import AuthService from '../services/AuthService';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import AccesosService from '../services/AccesosService';
import EmpresasService from '../services/EmpresasService';
import AreasService from '../services/AreasService';
import { Tag } from 'primereact/tag';
import * as XLSX from 'xlsx';

// import "primereact/resources/themes/lara-light-cyan/theme.css";

export const Accesos = () => {

  const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated);
  const [load, setLoad] = useState(true);
  const [selectedDates, setSelectedDates] = useState(null);
  const [fecha1, setFecha1] = useState('');
  const [fecha2, setFecha2] = useState('');
  const [accesos, setAccesos] = useState([]);
  const [filteredAccesos, setFilteredAccesos] = useState([]);
  const [empresas, setEmpresas] = useState([]);
  const [empresasFilter, setEmpresasFilter] = useState(null);
  const [areas, setAreas] = useState([]);
  const [areasFilter, setAreasFilter] = useState(null);
  const [usuario, setUsuario] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusDeleteDialog, setStatusDeleteDialog] = useState(false);
  const [visibleMotivo, setVisibleMotivo] = useState(false);
  const [motivoBloqueo, setMotivoBloqueo] = useState('');
  const [visible, setVisible] = useState(false);
  const [tipoEmpleadosFilter, setTipoEmpleadosFilter] = useState(null);
  const [tipoEmpleados] = useState([
    { tipo: 'Todos', value: 'todos' },
    { tipo: 'Empleados internos', value: 'empleado' },
    { tipo: 'Externos', value: 'externo' },
  ]);
  const columnasEncabezado = [
    { field: 'nombre_empleado', header: 'Nombre' },
    { field: 'rut_dv', header: 'RUT' },
    { field: 'patente', header: 'Patente' },
    { field: 'tipo_persona', header: 'Tipo' },
    { field: 'nombre_empresa', header: 'Empresa' },
    { field: 'nombre_area', header: 'Área' },
    { field: 'fecha', header: 'Fecha' },
    { field: 'hora_ingreso', header: 'Ingreso' },
    { field: 'hora_salida', header: 'Salida' },
    { field: 'salida', header: 'Estado' },
    { field: 'observacion', header: 'Observación' },
  ];

  const dt = useRef(null);

  addLocale('es', {
    firstDayOfWeek: 1,
    showMonthAfterYear: true,
    dayNames: ['domingo', 'lunes', 'martes', 'miércoles', 'jueves', 'viernes', 'sábado'],
    dayNamesShort: ['dom', 'lun', 'mar', 'mié', 'jue', 'vie', 'sáb'],
    dayNamesMin: ['D', 'L', 'M', 'X', 'J', 'V', 'S'],
    monthNames: ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'],
    monthNamesShort: ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'sep', 'oct', 'nov', 'dic'],
    today: 'Hoy',
    clear: 'Limpiar'
  });

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nombre_empresa: { value: null, matchMode: FilterMatchMode.CONTAINS },
    nombre_area: { value: null, matchMode: FilterMatchMode.CONTAINS },
    tipo_persona: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const getCurrentDate = () => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
    const year = currentDate.getFullYear();

    const firstDayOfMonth = new Date(year, currentDate.getMonth(), 1);

    setSelectedDates([firstDayOfMonth, currentDate]);
    setFecha1(`01/${month}/${year}`);
    setFecha2(`${day}/${month}/${year}`);

  }

  const formatDate = (date) => {
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const onChangeFecha = (e) => {

    const _selectedDates = e.value;

    // //setFecha(e.value);
    if (_selectedDates[0] && _selectedDates[1]) {

      const formattedDate1 = formatDate(_selectedDates[0]);
      const formattedDate2 = formatDate(_selectedDates[1]);
      console.log(formattedDate1); // Esto imprimirá la fecha en el formato dd/mm/yyyy
      console.log(formattedDate2); // Esto imprimirá la fecha en el formato dd/mm/yyyy
      setFecha1(formattedDate1);
      setFecha2(formattedDate2);

    }

    setSelectedDates(_selectedDates);

  }

  const rutTemplate = (rowData) => {
    return <span>{rowData.rut}-{rowData.dv}</span>;
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const tipoPersonaTemplate = (rowData) => {
    return <span>{capitalizeFirstLetter(rowData.tipo_persona)}</span>;
  };

  const salidaEstadoTemplate = (rowData) => {
    return (<div style={{ textAlign: 'center' }}><Tag severity={parseInt(rowData.salida) === 0 ? 'info' : 'success'} value={parseInt(rowData.salida) === 0 ? 'Ingreso' : 'Salida'} rounded></Tag></div>);
  };

  const salidaTextoTemplate = (rowData) => {
    return <span>{rowData.salida === 0 ? 'Ingreso' : 'Salida'} </span>;
  };

  const observacionTemplate = (rowData) => {
    return (<>
      {rowData.observacion ?
        <div style={{ textAlign: 'center' }}>
          <Button icon="pi pi-comment" tooltip='VER OBSERVACIÓN' tooltipOptions={{ position: 'left' }} onClick={() => { setMotivoBloqueo(rowData.observacion); setVisibleMotivo(true) }} rounded />
        </div>
        :
        ''
      }
    </>);
  }



  const obtener = async () => {

    if (isAuthenticated) {

      //const respuesta = await EmpleadosService.obtener();
      const data = {
        'fecha1': fecha1,
        'fecha2': fecha2
      };
      const respuesta = await AccesosService.obtener(data);

      if (respuesta) {
        setAccesos(respuesta.data.accesos);
        setFilteredAccesos(respuesta.data.accesos);
      }

    } else {

      window.location.reload();
    }
  }

  const obtenerEmpresas = async () => {

    if (isAuthenticated) {

      //const respuesta = await EmpleadosService.obtener();
      const respuesta = await EmpresasService.obtener();

      if (respuesta) {
        setEmpresas(respuesta.data.empresas);
      }

    } else {

      window.location.reload();
    }
  }

  const obtenerAreas = async () => {

    if (isAuthenticated) {

      //const respuesta = await EmpleadosService.obtener();
      const respuesta = await AreasService.obtener();

      if (respuesta) {
        setAreas(respuesta.data.areas);
      }

    } else {

      window.location.reload();
    }
  }


  const onEmpresasChange = (e) => {

    setEmpresasFilter(e.value);
    let _filters = { ...filters };

    _filters['nombre_empresa'].value = e.value ? e.value.nombre : '';
    _filters['nombre_empresa'].matchMode = FilterMatchMode.CONTAINS;

    setFilters(_filters);

    // Filtrar los datos usando el valor del filtro global
    const filteredData = accesos.filter(acceso =>
      acceso.nombre_empresa.toLowerCase().includes(e.value ? e.value.nombre.toLowerCase() : '')
      // Agrega más condiciones según las columnas que desees filtrar
    );

    // Actualizar los datos filtrados
    setFilteredAccesos(filteredData);

  };


  const onTipoPersonasChange = (e) => {

    console.log(e.value);
    setTipoEmpleadosFilter(e.value);
    let _filters = { ...filters };

    const _value = e.value === 'empleado' ? 'empleado' : e.value === 'externo' ? 'empleado' : e.value === 'todos' ? '' : '';

    _filters['tipo_persona'].value = _value;
    _filters['tipo_persona'].matchMode = e.value === 'externo' ? FilterMatchMode.NOT_CONTAINS : FilterMatchMode.CONTAINS;

    setFilters(_filters);

    // Filtrar los datos usando el valor del filtro global
    const filteredData = accesos.filter(acceso => {
      const tipoPersona = acceso.tipo_persona.toLowerCase();

      if (_value === '') {
        // Si el valor de filtro global está vacío, se incluyen todas las entradas
        return true;
      } else if (e.value === 'externo') {
        // Si el valor de filtro global es "externo", se excluyen las entradas con tipo_persona "empleado"
        return tipoPersona !== 'empleado';
      } else {
        // Si el valor de filtro global no es vacío ni "externo", se filtran las entradas según el tipo_persona
        return tipoPersona.includes(_value.toLowerCase());
      }
    });

    // Actualizar los datos filtrados
    setFilteredAccesos(filteredData);

  };

  const onAreasChange = (e) => {


    setAreasFilter(e.value);
    let _filters = { ...filters };

    _filters['nombre_area'].value = e.value ? e.value.nombre : '';
    _filters['nombre_area'].matchMode = FilterMatchMode.CONTAINS;

    setFilters(_filters);

    // Filtrar los datos usando el valor del filtro global
    const filteredData = accesos.filter(acceso =>
      acceso.nombre_area.toLowerCase().includes(e.value ? e.value.nombre.toLowerCase() : '')
      // Agrega más condiciones según las columnas que desees filtrar
    );

    // Actualizar los datos filtrados
    setFilteredAccesos(filteredData);

  };



  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);

    // Filtrar los datos usando el valor del filtro global
    const filteredData = accesos.filter(acceso =>
      acceso.nombre_empleado.toLowerCase().includes(value.toLowerCase()) ||
      acceso.rut_dv.toLowerCase().includes(value.toLowerCase())
      // Agrega más condiciones según las columnas que desees filtrar
    );

    // Actualizar los datos filtrados
    setFilteredAccesos(filteredData);


  };

  //const exportColumns = empresas.map((col) => ({ title: col.header, dataKey: col.field }));

  // const exportCSV = () => {
  //   const rows = dt.current.exportCSV().split('\n').map(row => row.split(','));
  //   const ws = XLSX.utils.aoa_to_sheet(rows);
  //   const wb = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  //   XLSX.writeFile(wb, 'data.xlsx');
  // };


  const exportExcel = () => {


    if (!filteredAccesos) {
      console.error("Los datos exportados no están en el formato esperado.");
      return;
    }


    const headerColumns = columnasEncabezado.map(col => col.header); // Usa los nombres amigables de las columnas de encabezado
    const rows = filteredAccesos.map(row => [
      row.nombre_empleado,
      row.rut_dv,
      row.patente,
      row.tipo_persona,
      row.nombre_empresa,
      row.nombre_area,
      row.fecha,
      row.hora_ingreso,
      row.hora_salida,
      parseInt(row.salida) === 0 ? 'Ingreso' : 'Salida',
      row.observacion
    ]);

    const ws = XLSX.utils.aoa_to_sheet([headerColumns, ...rows]);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Accesos');

    XLSX.writeFile(wb, `Control Accesos ${fecha1} - ${fecha2}.xlsx`);
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <>

        <Calendar className='mr-2' locale='es' value={selectedDates} onChange={onChangeFecha} dateFormat="dd/mm/yy" selectionMode='range' showIcon />
        {accesos ?
          <Dropdown
            options={empresas}
            optionLabel='nombre'
            value={empresasFilter}
            onChange={onEmpresasChange}
            placeholder="Empresa"
            className="p-column-filter mr-2"
            showClear
            style={{ minWidth: '12rem' }} />
          : ''}

        {accesos ?
          <Dropdown
            options={areas}
            optionLabel='nombre'
            value={areasFilter}
            onChange={onAreasChange}
            placeholder="Área"
            className="p-column-filter mr-2"
            showClear
            style={{ minWidth: '12rem' }} />
          : ''}

        {accesos ?
          <Dropdown
            options={tipoEmpleados}
            optionLabel='tipo'
            value={tipoEmpleadosFilter}
            onChange={onTipoPersonasChange}
            placeholder="Tipo"
            className="p-column-filter mr-2"
            showClear
            style={{ minWidth: '12rem' }} />
          : ''}

        {accesos ?
          <span className="p-input-icon-left mr-2">
            <i className="pi pi-search" />
            <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
          </span>
          : ''}
      </>

    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">

      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2">
        <Button type="button" icon="pi pi-file-excel" label='Exportar' rounded onClick={() => exportExcel()} data-pr-tooltip="EXCEL" />
      </div>
    );
  };



  const header = renderHeader();

  useEffect(() => {
    // Este bloque de código se ejecutará cada vez que cambie AuthService.isAuthenticated
    if (AuthService.isAuthenticated) {
      // Lógica cuando isAuthenticated es true
    } else {
      // Lógica cuando isAuthenticated es false
      window.location.reload();
    }
  }, [AuthService.isAuthenticated]);

  useEffect(() => {

  }, [filters]);

  useEffect(() => {

    if (load === true) {
      obtener();
      obtenerEmpresas();
      obtenerAreas();

      setLoad(false);
    }

  }, [load]);

  useEffect(() => {
    getCurrentDate();
  }, [])

  useEffect(() => {

  }, [fecha1])

  useEffect(() => {

  }, [fecha2])


  useEffect(() => {
    obtener();

  }, [selectedDates])


  return (
    <div className='row justify-content-center'>
      <div className='col-12'>
        <h1>Control de Accesos</h1>
        <p>Registro de accesos {fecha1} - {fecha2}</p>
        <div className='row'>
          <div className='col-12 my-1'>

            <Card>

              <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

              <DataTable
                ref={dt}
                value={accesos}
                header={header}
                exportFilename={`Control Accesos ${fecha1} - ${fecha2}`}
                csvSeparator=';'
                emptyMessage={`No existen registros de accesos para los días ${fecha1} - ${fecha2}`}
                filters={filters}
                globalFilterFields={['nombre_empleado', 'rut_dv']}
                paginator
                rows={20}
                rowsPerPageOptions={[10, 20, 50, 100]}
                size='small'
              >
                <Column field="nombre_empleado" header="Nombre"></Column>
                <Column body={rutTemplate} header="Rut"></Column>
                <Column field="rut_dv" header="Rut" style={{ display: 'none' }} exportable={true}></Column>
                <Column field="patente" header="Patente"></Column>
                <Column body={tipoPersonaTemplate} header="Tipo"></Column>
                <Column field="nombre_empresa" header="Empresa"></Column>
                <Column field="nombre_area" header="Area"></Column>
                <Column field="fecha" header="Fecha"></Column>
                <Column field="hora_ingreso" header="Ingreso"></Column>
                <Column field="hora_salida" header="Salida"></Column>
                <Column body={salidaEstadoTemplate} header="Estado" alignHeader={'center'} style={{ textAlign: 'center' }} exportable={false}></Column>
                <Column body={salidaTextoTemplate} header="Estado" style={{ display: 'none' }} exportable={true}></Column>
                <Column body={observacionTemplate} header="Observación" alignHeader={'center'} exportable={false}></Column>
                <Column field="observacion" header="Observación" style={{ display: 'none' }} exportable={true}></Column>
              </DataTable>

            </Card>

            {/* <DataForm
              statusDialog={statusDialog}
              onHideDialog={onHideDialog}
              onRefresh={onRefresh}
              dataForm={usuario}
              editing={editing}
              headerTitle={editing ? 'Editar empleado' : 'Nuevo empleado'}
              loadForm={true}
            /> */}

            {/* <DeleteForm
              visibleDeleteDialog={statusDeleteDialog}
              onHideDeleteDialog={onHideDeleteDialog}
              onDeleteRefresh={onDeleteRefresh}
              dataForm={usuario}
              deleting={deleting}
              loadForm={true}
            /> */}

            <Dialog header="Observaciones" maximizable visible={visibleMotivo} style={{ width: '50vw' }} onHide={() => setVisibleMotivo(false)}>
              <p className="m-0">
                <Editor value={motivoBloqueo} readOnly />

              </p>
            </Dialog>
          </div>
        </div>
      </div>



    </div>


  )
}
