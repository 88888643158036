import React, { useEffect, useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import EmpresasService from '../services/EmpresasService';
import EmpleadosService from '../services/EmpleadosService';
import TiposContratosService from '../services/TiposContratosService';
import UsuariosService from '../services/UsuariosService';
import { Editor } from "primereact/editor";
import { FileUpload } from 'primereact/fileupload';
import { Calendar } from 'primereact/calendar';
import AreasService from '../services/AreasService';
import moment from 'moment';

export const DataForm = ({ statusDialog, onHideDialog, onRefresh, dataForm, editing, headerTitle, loadForm }) => {

    const [firstLoad, setFirstLoad] = useState(false);
    const [empresaDialog, setEmpresaDialog] = useState(false);
    const [empresas, setEmpresas] = useState(null);
    const [tiposContratos, setTiposContratos] = useState(null);
    const [areas, setAreas] = useState(null);
    const [formMode, setFormMode] = useState('insert');
    const [fotoStatus, setFotoStatus] = useState('insert');

    const [selectedFile, setSelectedFile] = useState(null);
    const [empresaSelected, setEmpresaSelected] = useState(null);
    const [tipoContratoSelected, setTipoContratoSelected] = useState(null);
    const [terminoContratoSelected, setTerminoContratoSelected] = useState(null);
    const [areaSelected, setAreaSelected] = useState(null);
    const [foundUser, setFoundUser] = useState(false)

    const [chkChofer, setChkChofer] = useState(false);
    const [chkResidente, setChkResidente] = useState(false);
    const [chkBloqueado, setChkBloqueado] = useState(false);
    const [motivoBloqueo, setMotivoBloqueo] = useState(null);

    // validación de errores
    const [rutError, setRutError] = useState('');
    const [nombreError, setNombreError] = useState('');
    const [celularError, setCelularError] = useState('');
    const [empresaError, setEmpresaError] = useState('');
    const [areaError, setAreaError] = useState('');
    const [tcontratoError, setTcontratoError] = useState('');
    const [terminoContratoError, setTerminoContratoError] = useState('');
    const [motivoError, setMotivoError] = useState('');
    const [patente, setPatente] = useState('');

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);
    const patenteRef = useRef(null);

    const [formData, setFormData] = useState({
        id: '',
        nombre: '',
        rut: '',
        patente: '',
        telefono: '',
        foto: '',
        fotoStatus: '',
        id_empresa: '',
        area_id: '',
        contrato_tipo_id: null,
        termino_contrato: '',
        es_chofer: false,
        id_vehiculo: null,
        es_residente: false,
        bloqueado: false,
        motivo_bloqueo: null,
        created_id: user ? user.id : '',
        created_at: '',
        created_name: '',
        updated_id: '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: ''
    });

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);

        // setFormData({
        //     ...formData,
        //     foto: event.files[0],
        //   });

    };

    const hideDialog = () => {
        setEmpresaDialog(false);
        onHideDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };

    const formatRut = (rut) => {
        // Eliminar caracteres no numéricos
        const cleanedValue = rut.replace(/[^kK\d]/g, '');

        // Separar el RUT en parte numérica y dígito verificador
        const rutParteNumerica = cleanedValue.slice(0, -1);
        const rutDV = cleanedValue.slice(-1);

        // Aplicar formato con puntos y guión
        let formattedRut = '';
        let i = rutParteNumerica.length;

        while (i > 0) {
            formattedRut = rutParteNumerica.slice(Math.max(0, i - 3), i) + (formattedRut ? '.' + formattedRut : '');
            i -= 3;
        }

        return formattedRut + '-' + rutDV;
    };

    const handleBlur = async () => {

        setFoundUser(false);

        if (formData.rut.trim() !== '' && rutError.status === 'success') {
            //buscarEmpleado();
            const response = await UsuariosService.findUser(formData);

            if (response.data.status === 'success') {

                if (response && response.data.find_user) {

                    if (response.data.find_user.length > 0) {

                        setFoundUser(true);
                        const userData = response.data.find_user[0];

                        // setFormData((prevData) => ({ ...prevData, rut: userData.rut }));
                        // setFormData((prevData) => ({ ...prevData, dv: userData.dv }));
                        setFormData((prevData) => ({ ...prevData, nombre: userData.name }));
                        setFormData((prevData) => ({ ...prevData, telefono: userData.telefono_movil }));

                        const selEmpresa = empresas.find(item => item.id === userData.id_empresa);
                        setEmpresaError('');
                        setEmpresaSelected(selEmpresa);

                        // setChkChofer(false);
                        // setChkResidente(false);
                        // setChkBloqueado(false);
                        setMotivoBloqueo('');

                    }


                }
            } else if (response.data.status === 'not found') {

                // limpiar campos
                setFoundUser(false);
                setFormData((prevData) => ({ ...prevData, name: '' }));
                setFormData((prevData) => ({ ...prevData, telefono: '' }));
                setEmpresaSelected('');
                setChkChofer(false);
                setChkResidente(false);
                setChkBloqueado(false);
                setMotivoBloqueo('');

                // variable habiliatar campos
            }

        }
    };

    const handleDropdownChangeEmpresa = (e) => {
        //setFirstLoad(false);
        setEmpresaSelected(e.value);
    };

    const onChangeArea = (e) => {
        setAreaSelected(e.value);
    }

    const onChangeTerminoContrato = (e) => {

        setTerminoContratoSelected(e.target.value);

    }

    const onChangePatente = (e) => {
        const inputValue = e.target.value.toUpperCase();
        setFormData({ ...formData, patente: inputValue });
    }

    const onBlurPatente = () => {
        setPatente(patenteRef.current);

    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (name === 'rut') {

            // formatear RUT
            const inputRut = e.target.value;
            const formattedRut = formatRut(inputRut);

            // Actualizar el estado con el RUT formateado
            setFormData((prevData) => ({ ...prevData, rut: formattedRut }));

            // Validar el RUT al cambiar
            const isValid = validarRut(value);

            if (!isValid) {
                setRutError({ status: 'error', message: `RUT no válido ❌` });
            } else if (isValid) {
                setRutError({ status: 'success', message: `RUT válido ✅` });
            } else if (formData.rut === '') {
                setRutError({ status: 'success', message: `` });
            }
        }

    };

    const validarRut = (rut) => {
        rut = rut.replace(/\./g, '').replace(/-/g, '');

        if (!/^[0-9]+[0-9kK]{1}$/.test(rut)) {
            return false;
        }

        const rutNumerico = parseInt(rut.slice(0, -1), 10);
        const verificador = rut.slice(-1).toLowerCase();
        const dvEsperado = calcularDigitoVerificador(rutNumerico);

        if (rut.length > 7) { // este validador permite que no entregue true antes de tiempo
            return dvEsperado === verificador;
        } else {
            return false;
        }

    };

    const calcularDigitoVerificador = (rutNumerico) => {
        let m = 0;
        let s = 1;

        for (; rutNumerico; rutNumerico = Math.floor(rutNumerico / 10)) {
            s = (s + rutNumerico % 10 * (9 - m++ % 6)) % 11;
        }

        return (s ? s - 1 : 'k').toString();
    };

    const decodificarImagen = (foto) => {
        // Comprobar si la propiedad foto existe y es una cadena de texto
        if (foto && typeof foto === 'string') {
            const tipoArchivo = foto.split(';')[0].split(':')[1];
            const base64Data = foto.split(',')[1];
            return `data:${tipoArchivo};base64,${base64Data}`;
        } else {
            // Devolver una URL de imagen por defecto o gestionar el caso según tus necesidades
            return 'url_por_defecto';
        }
    };

    const cambiarFoto = (e) => {

        e.preventDefault();

        // Actualizar fotografía
        setFormData((prevData) => ({ ...prevData, foto: '' }));
        setFormData((prevData) => ({ ...prevData, fotoStatus: 'change' }));
        setFotoStatus('change');
    }

    const cancelar = (e) => {

        e.preventDefault();

        // Actualizar fotografía
        setFormData((prevData) => ({ ...prevData, foto: dataForm.foto }));
        setFormData((prevData) => ({ ...prevData, fotoStatus: 'asigned' }));
        setFotoStatus('asigned');

    }

    const changeTContrato = (e) => {
        setTipoContratoSelected(e.value);
    }

    const changeChofer = (e) => {
        setChkChofer(!chkChofer);
    }

    const changeResidente = (e) => {
        setChkResidente(!chkResidente);

    }

    const changeBloqueo = (e) => {
        setChkBloqueado(!chkBloqueado);
    }

    const changeMotivoBloqueo = (comentario) => {

        if (comentario !== null) {

            setMotivoBloqueo(comentario);
        }

    }

    const save = async (e) => {

        e.preventDefault();

        //setSubmitted(true);
        // valida rol

        // valida RUT
        if (formData.rut === '') {
            setRutError({ status: 'error', message: `Ingrese RUT ❌` });
            return;
        } else {
            // Validar el RUT al cambiar
            const isValid = validarRut(formData.rut);

            if (!isValid) {
                setRutError({ status: 'error', message: `RUT no válido ❌` });
                return;
            } else if (isValid) {
                setRutError({ status: 'success', message: `RUT válido ✅` });
            }
        }

        // validar nombre
        if (formData.nombre === '') {
            setNombreError({ status: 'error', message: `Ingrese nombre de usuario ❌` });
            return;
        } else {
            setNombreError({ status: '', message: `` });
        }


        // valida empresa
        if (formData.id_empresa === '') {
            setEmpresaError({ status: 'error', message: `Seleccione empresa ❌` });
            return;
        } else {
            setEmpresaError({ status: '', message: `` });
        }

        // valida área
        if (formData.area_id === '') {
            setAreaError({ status: 'error', message: `Seleccione área ❌` });
            return;
        } else {
            setAreaError({ status: '', message: `` });
        }

        // valida tipo contrato
        if (formData.contrato_tipo_id === '') {
            setTcontratoError({ status: 'error', message: `Seleccione tipo contrato ❌` });
            return;
        } else {
            setTcontratoError({ status: '', message: `` });
        }

        // valida tipo contrato
        if (formData.termino_contrato === '') {
            setTerminoContratoError({ status: 'error', message: `Seleccione fecha término de contrato ❌` });
            return;
        } else {
            setTerminoContratoError({ status: '', message: `` });
        }

        // validar celular
        if (formData.telefono === '') {
            setCelularError({ status: 'error', message: `Ingrese celular ❌` });
            return;
        } else {
            setCelularError({ status: '', message: `` });
        }

        // validar motivo en caso de tener el empleado con estado bloqueado
        if (formData.bloqueado && formData.motivo_bloqueo === null) {
            setMotivoError({ status: 'error', message: `Ingrese motivo de bloqueo ❌` });
            return;
        } else {
            setMotivoError({ status: '', message: `` });
        }



        // validación exitosa
        let api = '';
        if (editing) {
            api = await EmpleadosService.actualizar(formData, selectedFile);
        } else {
            api = await EmpleadosService.crear(formData, selectedFile);
        }

        if (api) {
            if (api.data.status === 'success') {
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(true);
                setEmpresaDialog(false);
                onHideDialog();
            } else {
                toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(false);
                setEmpresaDialog(false);
                onHideDialog();
            }
        }

    }

    const obtenerEmpresas = async () => {

        const response = await EmpresasService.obtener();

        if (response) {
            setEmpresas(response.data.empresas);
        }

    }

    const obtenerAreas = async () => {
        const response = await AreasService.obtener();

        if (response) {
            setAreas(response.data.areas);
        }

    }

    const obtenerTiposContratos = async () => {
        const response = await TiposContratosService.obtener();

        if (response) {
            setTiposContratos(response.data.tcontratos);
        }

    }

    const footerContent = (

        <div className='row'>

            <div className='col-md-7 text-muted' style={{ textAlign: 'left' }}>
                {dataForm && dataForm.created_id !== null ? <small><i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i> {dataForm.created_name} el {dataForm.created_at}<br /></small> : ''}
                {dataForm && dataForm.updated_id !== null ? <small><i className='pi pi-user-edit' style={{ fontSize: '1rem' }}></i> {dataForm.updated_name} el {dataForm.updated_at}</small> : ''}
            </div>
            <div className='col-md-5' style={{ textAlign: 'right' }}>
                <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button label="Grabar" icon="pi pi-save" onClick={save} />
            </div>
        </div>

    );

    const resetForm = () => {

        setFormData({
            id: '',
            nombre: '',
            rut: '',
            patente: '',
            telefono: '',
            foto: '',
            fotoStatus: '',
            id_empresa: '',
            area_id: '',
            contrato_tipo_id: '',
            termino_contrato: '',
            es_chofer: false,
            id_vehiculo: null,
            es_residente: false,
            bloqueado: false,
            motivo_bloqueo: null,
            created_id: user ? user.id : '',
            created_at: '',
            created_name: '',
            updated_id: '',
            updated_at: '',
            updated_name: '',
            deleted_id: '',
            deleted_at: '',
            deleted_name: ''
        });

        setRutError('');
        setNombreError('');
        setCelularError('');
        setEmpresaError('');
        setTcontratoError('');
        setMotivoError('');
        setFoundUser(false);

        setChkChofer(false);
        setChkResidente(false);
        setChkBloqueado(false);

        setEmpresas('');
        setEmpresaSelected('');
        setAreas('');
        setAreaSelected('');
        setTiposContratos('');
        setTipoContratoSelected('');
        setTerminoContratoSelected('');
    }

    useEffect(() => {

        setFirstLoad(loadForm);

    }, [loadForm]);


    useEffect(() => {

        resetForm();
        setEmpresaDialog(statusDialog);

        if (statusDialog && editing && dataForm) {

            obtenerEmpresas();
            obtenerAreas();
            obtenerTiposContratos();

            setFormMode('edit');
            //setFotoStatus('asigned');

            setFotoStatus(dataForm.foto === null || dataForm.foto === '' ? 'empty' : 'asigned');

            const fechaDesdeAPI = dataForm.termino_contrato;

            const fechaConvertida = fechaDesdeAPI === '' ? '' : moment(fechaDesdeAPI, 'DD/MM/YYYY').toDate();
            setTerminoContratoSelected(fechaConvertida);

            setFormData((prevData) => (
                {
                    ...prevData,
                    id: dataForm.id,
                    nombre: dataForm.nombre,
                    rut: `${dataForm.rut}-${dataForm.dv}`,
                    patente: dataForm.patente,
                    telefono: dataForm.telefono,
                    foto: dataForm.foto === '' ? '' : dataForm.foto,
                    fotoStatus: dataForm.foto === '' || dataForm.foto === null ? '' : 'asigned',
                    termino_contrato: dataForm.termino_contrato === '' ? '' : fechaConvertida,
                    es_chofer: parseInt(dataForm.es_chofer) === 1 ? true : false,
                    id_vehiculo: dataForm.id_vehiculo,
                    es_residente: parseInt(dataForm.es_residente) === 1 ? true : false,
                    bloqueado: parseInt(dataForm.bloqueado) === 1 ? true : false,
                    motivo_bloqueo: dataForm.motivo_bloqueo,
                    created_id: dataForm.created_id,
                    created_at: dataForm.created_at,
                    created_name: dataForm.created_name,
                    updated_id: user ? user.id : '',
                    updated_at: dataForm.updated_at,
                    updated_name: dataForm.updated_name,
                    deleted_id: dataForm.deleted_id,
                    deleted_at: dataForm.deleted_at,
                    deleted_name: dataForm.deleted_name
                }));

            if (firstLoad) {
                // const selTipoUsuario = usuariosTipos.find(item => item.id === dataForm.id_usuario_tipo);
                // setUsuariosTiposSelected(selTipoUsuario);

                const selEmpresa = empresas.find(item => item.id === dataForm.id_empresa);
                setEmpresaSelected(selEmpresa);

                const selArea = areas.find(item => item.id === dataForm.area_id);
                setAreaSelected(selArea);

                const selTCotrato = tiposContratos.find(item => item.id === dataForm.contrato_tipo_id);
                setTipoContratoSelected(selTCotrato);

                parseInt(dataForm.es_chofer) === 1 ? setChkChofer(true) : setChkChofer(false);
                parseInt(dataForm.es_residente) === 1 ? setChkResidente(true) : setChkResidente(false);
                parseInt(dataForm.bloqueado) === 1 ? setChkBloqueado(true) : setChkBloqueado(false);

            }


        } else {

            // limpiar campos
            resetForm();

            // obtener empresas
            obtenerEmpresas();
            obtenerAreas();

            // obtener tipos de contratos
            obtenerTiposContratos();

            setFormMode('insert');
            setFotoStatus('empty');

            setFormData((prevData) => ({ ...prevData, fotoStatus: 'empty' }));

        }

    }, [statusDialog, editing, dataForm]);

    useEffect(() => {

        // Actualizar el estado chofer
        setFormData((prevData) => ({ ...prevData, es_chofer: chkChofer }));

    }, [chkChofer])

    useEffect(() => {

        // Actualizar el estado residente
        setFormData((prevData) => ({ ...prevData, es_residente: chkResidente }));

    }, [chkResidente])

    useEffect(() => {

        // Actualizar el estado residente
        setFormData((prevData) => ({ ...prevData, patente: patente }));

    }, [patente])

    useEffect(() => {

        // Actualizar el estado bloqueado
        setFormData((prevData) => ({ ...prevData, bloqueado: chkBloqueado }));

        if (chkBloqueado === false) {
            setFormData((prevData) => ({ ...prevData, motivo_bloqueo: '' }));
        }

    }, [chkBloqueado])

    useEffect(() => {

        // Actualizar motivo de bloqueo
        setFormData((prevData) => ({ ...prevData, motivo_bloqueo: motivoBloqueo }));

    }, [motivoBloqueo])

    useEffect(() => {

        // Actualizar empresa change
        setFormData((prevData) => ({ ...prevData, id_empresa: empresaSelected }));

    }, [empresaSelected])

    useEffect(() => {

        // Actualizar empresa change
        setFormData((prevData) => ({ ...prevData, area_id: areaSelected }));

    }, [areaSelected])

    useEffect(() => {

        // Actualizar empresa change
        setFormData((prevData) => ({ ...prevData, contrato_tipo_id: tipoContratoSelected }));

    }, [tipoContratoSelected])

    useEffect(() => {

        // Actualizar tipo contrato change
        setFormData((prevData) => ({ ...prevData, termino_contrato: terminoContratoSelected }));

    }, [terminoContratoSelected])

    useEffect(() => {
        // Actualizar empresa change
        setFormData((prevData) => ({ ...prevData, foto: selectedFile }));

    }, [selectedFile])

    useEffect(() => {

        // Actualizar empresa change
        //setFormData((prevData) => ({ ...prevData, foto: selectedFile }));

    }, [formData])


    useEffect(() => {
        setFormData((prevData) => ({ ...prevData, id_empresa: empresaSelected }));
    }, [empresaSelected])



    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined', style: { display: 'none' } };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined' };

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header={headerTitle} maximizable visible={empresaDialog} footer={footerContent} onHide={hideDialog} style={{ width: '50vw' }}>

                <form>

                    <div className='row'>
                        <div className='col-6'>

                            {formMode === 'insert' || fotoStatus === 'change' ?
                                <>
                                    <FileUpload
                                        name="photo"
                                        accept="image/*"
                                        chooseOptions={chooseOptions}
                                        uploadOptions={uploadOptions}
                                        cancelOptions={cancelOptions}
                                        onSelect={onFileSelect}>
                                    </FileUpload>

                                </>

                                :
                                <>

                                    <div className="d-flex justify-content-center align-items-center">
                                        <img src={decodificarImagen(formData.foto)} style={{ width: '180px', height: 'auto', border: '1px solid #ccc' }} alt={`Foto de ${formData.nombre}`} />
                                    </div>
                                    <p></p>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Button label="Cambiar Fotografía" severity="help" rounded className='mr-1' onClick={(e) => cambiarFoto(e)} />
                                    </div>
                                </>


                            }

                            {formMode === 'edit' && fotoStatus === 'change' ?
                                <>
                                    <p></p>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Button severity="secondary" rounded onClick={(e) => cancelar(e)}>Cancelar</Button>
                                    </div>
                                </>

                                : ''
                            }

                            <div className="form-group mb-1">
                                <label htmlFor="rut" className="font-bold">Patente</label>
                                <InputText
                                    id='patente'
                                    name='patente'
                                    className='w-full font-weight-bold'
                                    style={{ fontSize: '20px' }}
                                    value={formData.patente}
                                    onChange={onChangePatente}
                                />
                            </div>

                        </div>


                        <div className='col-6'>

                            <div className="form-group mb-1">
                                <label htmlFor="rut" className="font-bold">RUT</label>
                                <InputText
                                    id="rut"
                                    name="rut"
                                    value={formData.rut}
                                    onChange={handleInputChange}
                                    required
                                    onBlur={handleBlur}
                                    readOnly={editing}
                                    className='form-control' />
                                {rutError.status === 'error' && <small className="p-error">{rutError.message}</small>}
                                {rutError.status === 'success' && <small className="p-messages-success" style={{ color: 'green' }}>{rutError.message}</small>}

                            </div>

                            <div className="form-group mb-1">
                                <label htmlFor="nombre" className="font-bold">Nombre</label>
                                <InputText
                                    id="nombre"
                                    name="nombre"
                                    value={formData.nombre}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, nombre: e.target.value }))}
                                    required
                                    disabled={foundUser}
                                    className='form-control'
                                />
                                {nombreError.status === 'error' && <small className="p-error">{nombreError.message}</small>}
                            </div>

                            <div className="form-group mb3">
                                <label htmlFor="id_empresa" className="font-bold">Empresa</label>
                                <Dropdown
                                    id="id_empresa"
                                    name="id_empresa"
                                    value={empresaSelected}
                                    onChange={handleDropdownChangeEmpresa}
                                    options={empresas}
                                    optionLabel="nombre"
                                    disabled={foundUser}
                                    filter
                                    placeholder="Seleccione empresa"
                                    className="w-full" />
                                {empresaError.status === 'error' && <small className="p-error">{empresaError.message}</small>}
                            </div>

                            <div className="form-group mb3">
                                <label htmlFor="id_empresa" className="font-bold">Área</label>
                                <Dropdown
                                    id="area"
                                    name="area"
                                    value={areaSelected}
                                    onChange={onChangeArea}
                                    options={areas}
                                    optionLabel="nombre"
                                    filter 
                                    placeholder="Seleccione área"
                                    className="w-full" />
                                {areaError.status === 'error' && <small className="p-error">{areaError.message}</small>}
                            </div>

                        </div>

                    </div>

                    <div className='row'>
                        <div className='col-3'>
                            <div className="form-group mb3">
                                <label htmlFor="contrato_tipo_id" className="font-bold">Tipo Contrato</label>
                                <Dropdown
                                    id="contrato_tipo_id"
                                    name="contrato_tipo_id"
                                    value={tipoContratoSelected}
                                    onChange={changeTContrato}
                                    options={tiposContratos}
                                    optionLabel="nombre"
                                    filter placeholder="Seleccione tipo de contrato"
                                    className="w-full" />
                                {tcontratoError.status === 'error' && <small className="p-error">{tcontratoError.message}</small>}
                            </div>
                        </div>

                        <div className='col-3'>
                            <div className="form-group mb3">
                                <label htmlFor="contrato_tipo_id" className="font-bold">Vencimiento Contrato</label>
                                <Calendar
                                    value={terminoContratoSelected}
                                    onChange={onChangeTerminoContrato}
                                    dateFormat="dd/mm/yy"
                                    showIcon
                                />
                                {terminoContratoError.status === 'error' && <small className="p-error">{terminoContratoError.message}</small>}
                            </div>
                        </div>

                        <div className='col-6'>
                            <div className="form-group mb3">
                                <label htmlFor="telefono" className="font-bold">Celular</label>
                                <InputText
                                    id="telefono"
                                    name="telefono"
                                    value={formData.telefono}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, telefono: e.target.value }))}
                                    required
                                    disabled={foundUser}
                                    className='form-control'
                                />
                                {celularError.status === 'error' && <small className="p-error">{celularError.message}</small>}
                            </div>
                        </div>

                    </div>

                    <div className='row'>

                        <div className='col-2'>
                            <div className="form-group mb3">
                                <label htmlFor="es_chofer" className="font-bold">Es Chofer?</label>
                                <div className="card flex justify-content-center">
                                    <InputSwitch
                                        id="es_chofer"
                                        name="es_chofer"
                                        value={formData.es_chofer}
                                        checked={chkChofer}
                                        onChange={(e) => changeChofer(e.value)} />
                                </div>

                            </div>
                        </div>

                        <div className='col-2'>
                            <div className="form-group mb3">
                                <label htmlFor="es_residente" className="font-bold">Es Residente?</label>
                                <div className="card flex justify-content-center">
                                    <InputSwitch
                                        id="es_residente"
                                        name="es_residente"
                                        value={formData.es_residente}
                                        checked={chkResidente}
                                        onChange={(e) => changeResidente(e.value)}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className='col-1'>
                            <div className="form-group mb3">
                                <label htmlFor="bloqueado" className="font-bold">Bloqueado?</label>
                                <div className="card flex justify-content-center">
                                    <InputSwitch
                                        id="bloqueado"
                                        name="bloqueado"
                                        value={formData.bloqueado}
                                        checked={chkBloqueado}
                                        onChange={(e) => changeBloqueo(e.value)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>

                    {chkBloqueado ?
                        <div className='row'>
                            <div className='col-12'>
                                <div className="form-group mb3">
                                    <label htmlFor="motivo_bloqueo" className="font-bold">Motivo de bloqueo</label>
                                    <div className="card flex justify-content-center">
                                        <Editor
                                            id="motivo_bloqueo"
                                            name="motivo_bloqueo"
                                            value={formData.motivo_bloqueo}
                                            onTextChange={(e) => changeMotivoBloqueo(e.htmlValue)}
                                            style={{ height: '120px' }} />
                                    </div>
                                    {motivoError.status === 'error' && <small className="p-error">{motivoError.message}</small>}
                                </div>
                            </div>
                        </div> : ''}
                </form>
            </Dialog>
        </div>
    )
}
