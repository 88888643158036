import React, { useEffect, useState, useRef } from 'react'
import CredencialesService from '../services/CredencialesService';
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Tag } from 'primereact/tag';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Card } from 'primereact/card';
import AuthService from '../services/AuthService';
import { Avatar } from 'primereact/avatar';
import { CredencialPDF } from './CredencialPDF';

import { NewForm } from './NewForm';
import { DataForm } from './DataForm';
import { DeleteForm } from './DeleteForm';


import 'primeflex/primeflex.css';
import { AsignaCredencialForm } from './AsignaCredencialForm';
import { DesasociarCredencialForm } from './DesasociarCredencialForm';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { ReporteCredencialPDF } from './ReporteCredencialesPDF';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Skeleton } from 'primereact/skeleton';

export const Credenciales = ({ tipo }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated);
  const [load, setLoad] = useState(true);
  const [loading, setLoading] = useState(false);
  const [credenciales, setCredenciales] = useState([]);
  const [empresa, setEmpresa] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [statusNewDialog, setStatusNewDialog] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusDeleteDialog, setStatusDeleteDialog] = useState(false);
  const [statusDesasociarDialog, setStatusDesasociarDialog] = useState(false);
  const [credencialSelected, setCredencialSelected] = useState('');
  const [credencialDesasociar, setCredencialDesasociar] = useState('');
  const [credencialDelete, setCredencialDelete] = useState('');
  const [generateCredencial, setGenerateCredencial] = useState('');
  const [credencialesFiltradas, setCredencialesFiltradas] = useState([]);

  const dt = useRef(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });


  const decodificarImagen = (foto) => {
    if (foto === null) {
      return '/assets/avatars/user.png'; // Devuelve una imagen por defecto para registros sin foto
    }
    if (typeof foto === 'string') {
      const tipoArchivo = foto.split(';')[0].split(':')[1];
      const base64Data = foto.split(',')[1];
      return `data:${tipoArchivo};base64,${base64Data}`;
    } else {
      return '/assets/avatars/user.png'; // Devuelve una imagen por defecto para casos inesperados
    }
  };

  const generaCredencial = (row) => {

    setGenerateCredencial({
      load: true,
      id: row.id,
      tipo_contrato: row.contrato_tipo_id,
      tipo_credencial: row.tipo_contrato,
      nombre: row.nombre,
      rut_dv: row.rut_dv,
      foto: row.foto,
      logo_empresa: row.logo_empresa,
      nombre_empresa: row.nombre_empresa,
      nombre_area: row.nombre_area === null ? '' : row.nombre_area,
      termino_contrato: row.termino_contrato === null ? '' : `FV: ${row.termino_contrato}`
    });
  }

  const fotografiaTemplate = (rowData) => {
    return (
      <div style={{ justifyContent: 'center', textAlign: 'center' }}>
        {
          rowData.foto !== '' && rowData !== null ?
            <Avatar image={decodificarImagen(rowData.foto)} size="xlarge" shape="circle" alt={rowData.name} />
            :
            <Avatar image="/assets/avatars/user.png" size="xlarge" shape="circle" alt={rowData.name} />
        }
      </div>
    )
  };

  const disponibilidadTemplate = (rowData) => {
    return (
      <>
        {parseInt(rowData.disponible) === 0 ? <Tag value="OCUPADA" severity="error"></Tag> : <Tag value="DISPONIBLE" severity="success"></Tag>}
      </>
    );
  };

  const codigoCredencialTemplate = (rowData) => {
    return (
      <>
        {String(rowData.id).padStart(7, '0')}
      </>
    );
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        {
          parseInt(rowData.disponible) === 1 ?
            <>
              <Button icon="pi pi-window-maximize" rounded severity="success" onClick={() => asignaCredencial(rowData)} className="mr-2" tooltip="Asignar Credencial" tooltipOptions={{ position: 'bottom' }}></Button>
              <Button icon="pi pi-trash" rounded severity='danger' onClick={() => confirmDeleteEmpresa(rowData)} tooltip="Eliminar Credencial" tooltipOptions={{ position: 'bottom' }} ></Button>
            </>
            :
            <>
              <Button icon="pi pi-id-card" rounded severity="warning" className="mr-2" onClick={() => generaCredencial(rowData)} tooltip="Imprimir Credencial" tooltipOptions={{ position: 'bottom' }}></Button>
              <Button icon="pi pi-window-minimize" rounded severity="help" className="mr-2" onClick={() => desasociarCredencial(rowData)} tooltip="Desasociar Credencial" tooltipOptions={{ position: 'bottom' }}></Button>
            </>

        }

      </React.Fragment>
    );
  };

  const obtener = async () => {

    setCredenciales([]); // limpiar

    if (isAuthenticated) {
      setLoading(true);
      const data = { tipo: tipo }
      const respuesta = await CredencialesService.obtener(data);

      if (respuesta) {
        setCredenciales(respuesta.data.credenciales);
        setLoading(false);
      }



    } else {

      window.location.reload();
    }

  }

  const addEmpresa = () => {
    setStatusNewDialog(true);
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setCredencialSelected(false);
    setStatusDesasociarDialog(false);
    setEditing(false);
    setEmpresa(null);

  }

  const onHideDialog = () => {
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setCredencialSelected(false);
    setStatusDesasociarDialog(false);
    setStatusDialog(false);
    setEmpresa(null);
  };

  const onHideNewDialog = () => {
    setStatusNewDialog(false);
    setStatusDesasociarDialog(false);
    setEmpresa(null);
  };

  const onHideDeleteDialog = () => {
    setStatusDeleteDialog(false);
    setStatusDesasociarDialog(false);
    setStatusDialog(false);
    setEmpresa(null);
  };

  const onHideDesasociarDialog = () => {
    setStatusDesasociarDialog(false);
    setStatusDialog(false);
    setEmpresa(null);
  };

  const onRefresh = (e) => {

    setLoad(e);
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setCredencialSelected(false);
    setDeleting(false);
    setEditing(false);
  }

  const onDeleteRefresh = (e) => {

    setLoad(e);
    setStatusDeleteDialog(false);
    setDeleting(false);
    setStatusDialog(false);
  }


  // const editEmpresa = (rowData) => {

  //   setStatusDialog(true);
  //   setStatusDeleteDialog(false);
  //   setEditing(true);
  //   setDeleting(false);
  //   setEmpresa(rowData);
  // }

  const confirmDeleteEmpresa = (rowData) => {

    setCredencialDelete({
      'status': true,
      'credencial_id': rowData.id
    })

  }

  const desasociarCredencial = (rowData) => {

    console.log(rowData);
    setCredencialDesasociar({
      'status': true,
      'credencial_id': rowData.id,
      'nombre': rowData.nombre,
      'empleado_id': rowData.empleado_id,
      'contrato_tipo_id': rowData.contrato_tipo_id,
      'rut_dv': rowData.rut_dv,
      'nombre': rowData.nombre
    })

  }

  const asignaCredencial = (row) => {
    console.log(row);
    setCredencialSelected({
      'status': true,
      'cod': row.id,
      'tipoC': tipo,
      'contrato_id': row.contrato_tipo_id
    });
  }

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
  };

  //const exportColumns = empresas.map((col) => ({ title: col.header, dataKey: col.field }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const exportToExcel = async () => {

    // Crear un nuevo libro de Excel
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Reporte de Credenciales');

    // Agregar encabezados
    worksheet.columns = [
      { header: 'Fotografía', key: 'foto', width: 20 },
      { header: 'Código Credencial', key: 'id', width: 20 },
      { header: 'Tipo Credencial', key: 'tipo_contrato', width: 20 },
      { header: 'Disponibilidad', key: 'disponible', width: 20 },
      { header: 'Nombre', key: 'nombre', width: 40 },
    ];

    // Agregar filas al worksheet
    console.log(credencialesFiltradas);
    const data = credencialesFiltradas.length > 0 ? credencialesFiltradas : credenciales;

    console.log(data);

    data.forEach((item, index) => {
      const row = worksheet.addRow({
        id: String(item.id).padStart(7, '0'),
        tipo_contrato: item.tipo_contrato,
        disponible: parseInt(item.disponible) === 0 ? 'Disponible' : 'Ocupada',
        nombre: item.nombre ? item.nombre : '',
      });

      // Convertir imagen base64 a buffer y agregarla al Excel
      if (item.foto) {
        const imageId = workbook.addImage({
          base64: item.foto,
          extension: 'jpeg',
        });

        // Ajustar la imagen a la celda específica
        worksheet.addImage(imageId, {
          tl: { col: 0, row: row.number - 1 }, // Esquina superior izquierda de la celda
          ext: { width: 20, height: 20 }, // Ajusta el tamaño de la imagen
        });

        // Ajustar la imagen a la celda específica
        // worksheet.addImage(imageId, `A${row.number}:A${row.number}`);
      }
    });

    // Generar el archivo Excel y guardarlo
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(new Blob([buffer]), 'reporte_credenciales.xlsx');
  }


  const exportCredencialesToPDF = () => {

    setGenerateCredencial({
      loadPDF: true,
      contratoTipo: tipo,
      credenciales: credencialesFiltradas.length ? credencialesFiltradas : credenciales
    });

  }


  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <span className="p-input-icon-left">
        <IconField iconPosition='left'>
          <InputIcon className="pi pi-search" />
          <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
        </IconField>
      </span>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button label="Nueva" icon="pi pi-plus" severity="success" onClick={() => addEmpresa()} />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2">
        {/* <Button type="button" icon="pi pi-file-excel" label='Exportar' rounded onClick={() => exportCSV()} data-pr-tooltip="CSV" /> */}
        <Button type="button" icon="pi pi-file-excel" label='Exportar' rounded onClick={() => exportToExcel()} data-pr-tooltip="CSV" />
        <Button type="button" icon="pi pi-file-pdf" severity='danger' label='Exportar PDF' rounded onClick={() => exportCredencialesToPDF()} data-pr-tooltip="PDF" />
      </div>
    );
  };

  const header = renderHeader();

  useEffect(() => {
    // Este bloque de código se ejecutará cada vez que cambie AuthService.isAuthenticated
    if (AuthService.isAuthenticated) {
      // Lógica cuando isAuthenticated es true
    } else {
      // Lógica cuando isAuthenticated es false
      window.location.reload();
    }
  }, [AuthService.isAuthenticated]);

  useEffect(() => {

    if (load === true) {
      setLoading(false);
      obtener();

      setLoad(false);
    }

  }, [load]);

  useEffect(() => {
    obtener();
  }, [tipo]);

  useEffect(() => {
    // reset form


  }, [statusNewDialog]);


  useEffect(() => {
    // reset form
    console.log(generateCredencial);

  }, [generateCredencial]);

  useEffect(() => {
    // reset form
    //console.log(generateCredencial);

  }, [desasociarCredencial]);

  useEffect(() => {
    // reset form
    //console.log(generateCredencial);

  }, [confirmDeleteEmpresa]);


  return (
    <div className='row justify-content-center'>
      <div className='col-12'>
        {parseInt(tipo) === 1 ?
          <>
            <h1>Credenciales Contratos Indefinidos</h1>
            <p>Lista de credenciales generadas para Contratos Indefinidos</p>
          </> : ''}

        {parseInt(tipo) === 2 ?
          <>
            <h1>Credenciales Contratos Plazo Fijo</h1>
            <p>Lista de credenciales generadas para Contratos Plazo Fijo</p>
          </> : ''}

        {parseInt(tipo) === 3 ?
          <>
            <h1>Credenciales para Proveedores</h1>
            <p>Lista de credenciales generadas para Proveedores</p>
          </>
          : ''}

        {parseInt(tipo) === 4 ?
          <>
            <h1>Credenciales para Clientes</h1>
            <p>Lista de credenciales generadas para Clientes</p>
          </>
          : ''}

        {parseInt(tipo) === 5 ?
          <>
            <h1>Credenciales para Visitas</h1>
            <p>Lista de credenciales generadas para Visitas</p>
          </>
          : ''}

        {parseInt(tipo) === 6 ?
          <>
            <h1>Credenciales para Descarte</h1>
            <p>Lista de credenciales generadas para Descarte</p>
          </>
          : ''}

        {parseInt(tipo) === 7 ?
          <>
            <h1>Credenciales para Consumo</h1>
            <p>Lista de credenciales generadas para Consumo</p>
          </>
          : ''}

        {parseInt(tipo) === 9999 ?
          <>
            <h1>Credenciales para Externos</h1>
            <p>Lista de credenciales generadas para Externos</p>
          </>
          : ''}

        <div className='row'>
          <div className='col-12 my-4'>

            <Card>

              <Toolbar className="mb-4" left={loading ? '' : leftToolbarTemplate} right={loading ? '' : rightToolbarTemplate}></Toolbar>

              <DataTable ref={dt} value={credenciales} tableStyle={{ minWidth: '50rem' }} header={header}
                exportFilename='Credenciales' csvSeparator=';'
                emptyMessage={loading ? <div style={{ textAlign: 'center' }}><ProgressSpinner style={{ width: '20px', height: '20px' }} strokeWidth="8" /><label className='ml-3'> Obteniendo credenciales</label></div> : 'No existen credenciales registradas'}
                filters={filters}
                onValueChange={(e) => setCredencialesFiltradas(e)}
                paginator rows={10} rowsPerPageOptions={[10, 25, 50]} >
                <Column body={fotografiaTemplate} header="Fotografía"></Column>
                <Column body={codigoCredencialTemplate} header="Código credencial"></Column>
                <Column field="tipo_contrato" header="Tipo Credencial"></Column>
                <Column body={disponibilidadTemplate} header="Disponibilidad"></Column>
                <Column field="nombre" header="Nombre"></Column>
                <Column body={actionBodyTemplate} header="Acciones" exportable={false} style={{ minWidth: '12rem' }} ></Column>
              </DataTable>

            </Card>


            <NewForm
              statusNewDialog={statusNewDialog}
              onHideNewDialog={onHideNewDialog}
              tipoContrato={tipo}
              onRefresh={onRefresh}
            />

            <DataForm
              statusDialog={statusDialog}
              onHideDialog={onHideDialog}
              onRefresh={onRefresh}
              dataForm={empresa}
              editing={editing}
              headerTitle={editing ? 'Editar Registro' : 'Nuevo Registro'}
            />

            <DeleteForm
              credencialDel={credencialDelete}
              onHideDeleteDialog={onHideDeleteDialog}
              onDeleteRefresh={onDeleteRefresh}
            />

            <AsignaCredencialForm
              credencialSel={credencialSelected}
              onHideDialog={onHideDialog}
              onRefresh={onRefresh}
            />


            <DesasociarCredencialForm
              credencialDes={credencialDesasociar}
              onHideDesasociarDialog={onHideDesasociarDialog}
              onRefresh={onRefresh}
            />


            <CredencialPDF
              generateCredencial={generateCredencial}
            />

            <ReporteCredencialPDF
              generateCredencial={generateCredencial}
            />

          </div>
        </div>
      </div>
    </div>
  )
}
