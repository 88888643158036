import React from 'react';
import cliente from '../services/baseURL.tsx';

class EmpresasService extends React.Component {
  
    obtener = async () => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empresas/obtener', {}, config);

            return response;

                
        } catch (error) {
            console.error('Error al obtener empresas', error);
            return false;
        }
  };

    crear = async (data, file) => {

        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();

            formData.append('id', data.id);
            formData.append('nombre', data.nombre);
            formData.append('rut', data.rut);
            formData.append('direccion', data.direccion);
            formData.append('telefono', data.telefono);
            formData.append('logo', file);
            formData.append('logoStatus', data.logoStatus);
            formData.append('created_id', data.created_id);

            const config = {
                headers: {
                    // Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empresas/crear', formData, config);

            return response;

                
        } catch (error) {
            console.error('Error al crear empresa', error);
            return false;
        }
    };


    actualizar = async (data, file) => {

        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();

            formData.append('id', data.id);
            formData.append('nombre', data.nombre);
            formData.append('rut', data.rut);
            formData.append('direccion', data.direccion);
            formData.append('telefono', data.telefono);
            formData.append('logo', file);
            formData.append('logoStatus', data.logoStatus);
            formData.append('updated_id', data.updated_id);
            
            const config = {
                headers: {
                    // Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empresas/actualizar', formData, config);

            return response;

                
        } catch (error) {
            console.error('Error al actualizar empresa', error);
            return false;
        }
    };
    
    
    eliminar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empresas/eliminar  ', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al eliminar empresa', error);
            return false;
        }
    };    

}

export default new EmpresasService();