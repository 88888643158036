import React, { useEffect, useState, useRef } from 'react'
import EmpresasService from '../services/EmpresasService'
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode, FilterOperator } from 'primereact/api';
import { Card } from 'primereact/card';
import AuthService from '../services/AuthService';

import { DataForm } from './DataForm';
import { DeleteForm } from './DeleteForm';
import { Avatar } from 'primereact/avatar';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

// import "primereact/resources/themes/lara-light-cyan/theme.css";

export const Empresas = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated);
  const [load, setLoad] = useState(true);
  const [empresas, setEmpresas] = useState([]);
  const [empresa, setEmpresa] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusDeleteDialog, setStatusDeleteDialog] = useState(false);

  const dt = useRef(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });


  const rutTemplate = (rowData) => {
    return <span>{rowData.rut}-{rowData.dv}</span>;
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editEmpresa(rowData)} />
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteEmpresa(rowData)} />
      </React.Fragment>
    );
  };


  const obtener = async () => {

    if (isAuthenticated) {

      const respuesta = await EmpresasService.obtener();
      setEmpresas(respuesta.data.empresas);
    } else {

      window.location.reload();
    }

  }

  const decodificarImagen = (foto) => {
    // Comprobar si la propiedad foto existe y es una cadena de texto
    if (foto && typeof foto === 'string') {
      const tipoArchivo = foto.split(';')[0].split(':')[1];
      const base64Data = foto.split(',')[1];
      return `data:${tipoArchivo};base64,${base64Data}`;
    } else {
      // Devolver una URL de imagen por defecto o gestionar el caso según tus necesidades
      return 'url_por_defecto';
    }
  };

  const logoTemplate = (rowData) => {
    return (
      <>
        {
          rowData.foto !== '' && rowData !== null ?
            <Avatar image={decodificarImagen(rowData.logo)} size="xlarge" alt={rowData.name} />
            :
            <Avatar image="/assets/avatars/user.png" size="xlarge" shape="circle" alt={rowData.name} />
        }
      </>
    )
  };

  const addEmpresa = () => {
    setStatusDialog(true);
    setStatusDeleteDialog(false);
    setEditing(false);
    setEmpresa(null);

  }

  const onHideDialog = () => {
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setStatusDialog(false);
  };

  const onHideDeleteDialog = () => {
    setStatusDeleteDialog(false);
    setStatusDialog(false);
  };

  const onRefresh = (e) => {

    setLoad(e);
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setDeleting(false);
    setEditing(false);
  }

  const onDeleteRefresh = (e) => {

    setLoad(e);
    setStatusDeleteDialog(false);
    setDeleting(false);
    setStatusDialog(false);
  }


  const editEmpresa = (rowData) => {

    setStatusDialog(true);
    setStatusDeleteDialog(false);
    setEditing(true);
    setDeleting(false);
    setEmpresa(rowData);
  }

  const confirmDeleteEmpresa = (rowData) => {

    setStatusDeleteDialog(true);
    setDeleting(true);
    setEmpresa(rowData);

  }

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
  };

  //const exportColumns = empresas.map((col) => ({ title: col.header, dataKey: col.field }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <span className="p-input-icon-left">
        <IconField iconPosition='left'>
          <InputIcon className="pi pi-search" />
          <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
        </IconField>
      </span>
    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button label="Nueva" icon="pi pi-plus" severity="success" onClick={() => addEmpresa()} />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2">
        <Button type="button" icon="pi pi-file-excel" label='Exportar' rounded onClick={() => exportCSV()} data-pr-tooltip="CSV" />
      </div>
    );
  };

  const header = renderHeader();

  useEffect(() => {
    // Este bloque de código se ejecutará cada vez que cambie AuthService.isAuthenticated
    if (AuthService.isAuthenticated) {
      // Lógica cuando isAuthenticated es true
    } else {
      // Lógica cuando isAuthenticated es false
      window.location.reload();
    }
  }, [AuthService.isAuthenticated]);

  useEffect(() => {

    if (load === true) {
      obtener();

      setLoad(false);
    }

  }, [load]);



  return (
    <div className='row justify-content-center'>
      <div className='col-12'>
        <h1>Empresas</h1>
        <p>Lista de empresas registradas</p>
        <div className='row'>
          <div className='col-12 my-4'>

            <Card>

              <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

              <DataTable ref={dt} value={empresas} tableStyle={{ minWidth: '50rem' }} header={header}
                exportFilename='Empresas' csvSeparator=';' emptyMessage="No existen empresas ingresadas" filters={filters} paginator rows={5} rowsPerPageOptions={[5, 10, 25, 50]} >
                <Column body={logoTemplate} header="Logo" alignHeader={'center'} />
                <Column field="nombre" header="Nombre"></Column>
                <Column body={rutTemplate} header="Rut"></Column>
                <Column field="rut" header="Rut" style={{ display: 'none' }} exportable={false}></Column>
                <Column field="telefono" header="Teléfono"></Column>
                <Column field="direccion" header="Dirección"></Column>
                <Column body={actionBodyTemplate} header="Acciones" exportable={false} style={{ minWidth: '12rem' }} ></Column>
              </DataTable>

            </Card>

            <DataForm
              statusDialog={statusDialog}
              onHideDialog={onHideDialog}
              onRefresh={onRefresh}
              dataForm={empresa}
              editing={editing}
              headerTitle={editing ? 'Editar Registro' : 'Nuevo Registro'}
            />

            <DeleteForm
              visibleDeleteDialog={statusDeleteDialog}
              onHideDeleteDialog={onHideDeleteDialog}
              onDeleteRefresh={onDeleteRefresh}
              dataForm={empresa}
              deleting={deleting}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
