import React, { useEffect, useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { Toast } from 'primereact/toast';
import UsuariosTipoService from '../services/UsuariosTipoService';
import EmpresasService from '../services/EmpresasService';
import UsuariosServices from '../services/UsuariosService';
import { Password } from 'primereact/password';
import { Divider } from 'primereact/divider';

export const DataForm = ({ statusDialog, onHideDialog, onRefresh, dataForm, editing, headerTitle, loadForm }) => {


    const [empresaDialog, setEmpresaDialog] = useState(false);
    const [usuariosTipos, setUsuariosTipos] = useState(null);
    const [usuariosTiposSelected, setUsuariosTiposSelected] = useState(null);
    const [empresas, setEmpresas] = useState(null);
    const [empresaSelected, setEmpresaSelected] = useState(null);

    // validación de errores
    const [rolError, setRolError] = useState('');
    const [rutError, setRutError] = useState('');
    const [nombreError, setNombreError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [celularError, setCelularError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [passwordRError, setPasswordRError] = useState('');
    const [empresaError, setEmpresaError] = useState('');

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const [formData, setFormData] = useState({
        id: '',
        id_usuario_tipo: '',
        name: '',
        rut: '',
        email: '',
        telefono_movil: '',
        id_empresa: '',
        password: '',
        password_repeat: '',
        created_id: user ? user.id : '',
        created_at: '',
        created_name: '',
        updated_id: '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: ''
    });


    const hideDialog = () => {
        setEmpresaDialog(false);
        onHideDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };

    const formatRut = (rut) => {
        // Eliminar caracteres no numéricos
        const cleanedValue = rut.replace(/[^kK\d]/g, '');

        // Separar el RUT en parte numérica y dígito verificador
        const rutParteNumerica = cleanedValue.slice(0, -1);
        const rutDV = cleanedValue.slice(-1);

        // Aplicar formato con puntos y guión
        let formattedRut = '';
        let i = rutParteNumerica.length;

        while (i > 0) {
            formattedRut = rutParteNumerica.slice(Math.max(0, i - 3), i) + (formattedRut ? '.' + formattedRut : '');
            i -= 3;
        }

        return formattedRut + '-' + rutDV;
    };

    const validarRut = (rut) => {
        rut = rut.replace(/\./g, '').replace(/-/g, '');

        if (!/^[0-9]+[0-9kK]{1}$/.test(rut)) {
            return false;
        }

        const rutNumerico = parseInt(rut.slice(0, -1), 10);
        const verificador = rut.slice(-1).toLowerCase();
        const dvEsperado = calcularDigitoVerificador(rutNumerico);

        if (rut.length > 7) { // este validador permite que no entregue true antes de tiempo
            return dvEsperado === verificador;
        } else {
            return false;
        }

    };

    const calcularDigitoVerificador = (rutNumerico) => {
        let m = 0;
        let s = 1;

        for (; rutNumerico; rutNumerico = Math.floor(rutNumerico / 10)) {
            s = (s + rutNumerico % 10 * (9 - m++ % 6)) % 11;
        }

        return (s ? s - 1 : 'k').toString();
    };


    const handleDropdownChangeUsuarioTipo = (e) => {
        setUsuariosTiposSelected(e.value);

    };


    const handleDropdownChangeEmpresa = (e) => {
        setEmpresaSelected(e.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        // Validar contraseñas mientras se escriben
        if (name === 'password_repeat') {
            validatePasswordRepeat(value);
        }

        if (name === 'email') {
            validateEmail(value);
        }

        if (name === 'rut') {

            // formatear RUT
            const inputRut = e.target.value;
            const formattedRut = formatRut(inputRut);

            // Actualizar el estado con el RUT formateado
            setFormData((prevData) => ({ ...prevData, rut: formattedRut }));

            // Validar el RUT al cambiar
            const isValid = validarRut(value);

            if (!isValid) {
                setRutError({ status: 'error', message: `RUT no válido ❌` });
            } else if (isValid) {
                setRutError({ status: 'success', message: `RUT válido ✅` });
            } else if (formData.rut === '') {
                setRutError({ status: 'success', message: `` });
            }
        }

    };


    const validatePasswordRepeat = (value) => {
        if (formData.password !== value) {
            setPasswordRError({ status: 'error', message: `Las contraseñas no coinciden ❌` });
            return false;
        } else {
            setPasswordRError({ status: 'success', message: `Las contraseñas coinciden ✅` });
            return true;
        }
    };

    const isValidEmail = (email) => {
        // Expresión regular para validar el formato de un correo electrónico
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validateEmail = (value) => {

        if (!value) {
            setEmailError({ status: 'error', message: `Correo electrónico es requerido` });
            return false;
        } else if (!isValidEmail(value)) {
            setEmailError({ status: 'error', message: `Formato de correo electrónico no válido ❌` });
            return false;
        } else if (isValidEmail(value)) {
            setEmailError({ status: 'success', message: `` });
            return true;
        }

    };

    const save = async (e) => {

        e.preventDefault();
        // valida rol

        if (formData.id_usuario_tipo === '') {
            setRolError({ status: 'error', message: `Seleccione ROL de usuario ❌` });
            return;
        } else {
            setRolError({ status: '', message: `` });
        }

        // valida RUT
        if (formData.rut === '') {
            setRutError({ status: 'error', message: `Ingrese RUT ❌` });
            return;
        } else {
            // Validar el RUT al cambiar
            const isValid = validarRut(formData.rut);

            if (!isValid) {
                setRutError({ status: 'error', message: `RUT no válido ❌` });
                return;
            } else if (isValid) {
                setRutError({ status: 'success', message: `RUT válido ✅` });
            }
        }

        // validar nombre
        if (formData.name === '') {
            setNombreError({ status: 'error', message: `Ingrese nombre de usuario ❌` });
            return;
        } else {
            setNombreError({ status: '', message: `` });
        }

        // Validar formato de correo electrónico
        const mailValid = validateEmail(formData.email);
        if (!mailValid) {
            return;
        }

        // validar celular
        if (formData.telefono_movil === '') {
            setCelularError({ status: 'error', message: `Ingrese celular ❌` });
            return;
        } else {
            setCelularError({ status: '', message: `` });
        }


        // validar contraseña
        if (!editing && formData.password === '') {
            setPasswordError({ status: 'error', message: `Ingrese contraseña ❌` });
            return;
        } else {
            setPasswordError({ status: '', message: `` });
        }

        // validar repita contraseña
        if (!editing && formData.password_repeat === '') {
            setPasswordRError({ status: 'error', message: `Repita contraseña ❌` });
            return;
        } else {
            setPasswordRError({ status: '', message: `` });
            const passValid = validatePasswordRepeat(formData.password_repeat);
            if (!passValid) {
                return;
            }
        }


        // valida empresa
        if (formData.id_empresa === '') {
            setEmpresaError({ status: 'error', message: `Seleccione empresa ❌` });
            return;
        } else {
            setEmpresaError({ status: '', message: `` });
        }


        // validación exitosa
        let api = '';
        if (editing) {
            api = await UsuariosServices.actualizar(formData);
        } else {
            api = await UsuariosServices.crear(formData);
        }

        if (api) {
            if (api.data.status === 'success') {
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(true);
                setEmpresaDialog(false);
                onHideDialog();
            } else {
                toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(false);
                setEmpresaDialog(false);
                onHideDialog();
            }
        }



    }

    const obtenerTipoUsuarios = async () => {

        const response = await UsuariosTipoService.obtener();

        if(response){
            setUsuariosTipos(response.data.usuariosTipos);
        }
        

    }

    const obtenerEmpresas = async () => {
        const response = await EmpresasService.obtener();

        if(response){
            setEmpresas(response.data.empresas);
        }
        

    }

    const footerContent = (

        <div className='row'>

            <div className='col-md-7 text-muted' style={{ textAlign: 'left' }}>
                {dataForm && dataForm.created_id !== null ? <small><i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i> {dataForm.created_name} el {dataForm.created_at}<br /></small> : ''}
                {dataForm && dataForm.updated_id !== null ? <small><i className='pi pi-user-edit' style={{ fontSize: '1rem' }}></i> {dataForm.updated_name} el {dataForm.updated_at}</small> : ''}
            </div>
            <div className='col-md-5' style={{ textAlign: 'right' }}>
                <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button label="Grabar" icon="pi pi-save" onClick={ save } />
            </div>
        </div>

    );

    const footerFormChangePassword = (
        <>
          <Divider />
          <p className="mt-2">Sugerencias contraseña fuerte</p>
          <ul className="pl-2 ml-2 mt-0 line-height-3">
            <li>Al menos una minúscula</li>
            <li>Al menos una mayúscula</li>
            <li>Al menos un número</li>
            <li>Mínimo 8 characters</li>
          </ul>
        </>
      );


    useEffect(() => {

    }, [loadForm]);

    useEffect(() => {
        // ... el resto de tu código
        setFormData((prevData) => ({ ...prevData, id_usuario_tipo: usuariosTiposSelected }));

    }, [usuariosTiposSelected]);


    useEffect(() => {
        // ... el resto de tu código
        setFormData((prevData) => ({ ...prevData, id_empresa: empresaSelected }));

    }, [empresaSelected]);

    useEffect(() => {
        setEmpresaDialog(statusDialog);
        
        if (statusDialog && editing && dataForm) {

            obtenerTipoUsuarios();

            obtenerEmpresas();

            setFormData((prevData) => (
                {
                    ...prevData,
                    id: dataForm.id,
                    name: dataForm.name,
                    rut: `${dataForm.rut}-${dataForm.dv}`,
                    email: dataForm.email,
                    telefono_movil: dataForm.telefono_movil,
                    password: '',
                    password_repeat: '',
                    created_id: dataForm.created_id,
                    created_at: dataForm.created_at,
                    created_name: dataForm.created_name,
                    updated_id: user ? user.id : '',
                    updated_at: dataForm.updated_at,
                    updated_name: dataForm.updated_name,
                    deleted_id: dataForm.deleted_id,
                    deleted_at: dataForm.deleted_at,
                    deleted_name: dataForm.deleted_name
                }));

            if (loadForm) {

                const selTipoUsuario = usuariosTipos.find(item => item.id === dataForm.id_usuario_tipo);
                setUsuariosTiposSelected(selTipoUsuario);

                const selEmpresa = empresas.find(item => item.id === dataForm.id_empresa);
                setEmpresaSelected(selEmpresa);
            }


        } else {
            // Estás en modo nuevo registro, puedes reiniciar el formulario si es necesario
            setUsuariosTiposSelected('');
            setUsuariosTipos('');
            setEmpresaSelected('');
            setEmpresas('');

            obtenerTipoUsuarios();
            obtenerEmpresas();

            setFormData({
                id: '',
                id_usuario_tipo: usuariosTiposSelected,
                name: '',
                rut: '',
                email: '',
                telefono_movil: '',
                id_empresa: empresaSelected,
                password: '',
                password_repeat: '',
                created_id: user ? user.id : '',
                created_at: '',
                created_name: '',
                updated_id: null,
                updated_at: '',
                updated_name: '',
                deleted_id: '',
                deleted_at: '',
                deleted_name: ''
            });

            // limpiar mensajes
            setRolError('');
            setRutError('');
            setNombreError('');
            setEmailError('');
            setCelularError('');
            setPasswordError('');
            setPasswordRError('');
            setEmpresaError('');

        }

    }, [statusDialog, editing, dataForm]);

    useEffect(() => {

        // Actualizar empresa change
        //setFormData((prevData) => ({ ...prevData, foto: selectedFile }));

    }, [formData])


    return (
        <div>
            <Toast ref={toast} />
            <Dialog header={headerTitle} maximizable visible={empresaDialog} footer={footerContent} onHide={hideDialog} style={{ width: '55vw' }}>

                <form>

                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-1">
                                <label htmlFor="id_usuario_tipo" className="font-bold">ROL</label>
                                <Dropdown
                                    id="id_usuario_tipo"
                                    name="id_usuario_tipo"
                                    value={usuariosTiposSelected}
                                    onChange={handleDropdownChangeUsuarioTipo}
                                    options={usuariosTipos}
                                    optionLabel="nombre"
                                    filter placeholder="Seleccione un rol de usuario" className="w-full" />
                                {rolError.status === 'error' && <small className="p-error">{rolError.message}</small>}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-1">
                                <label htmlFor="rut" className="font-bold">RUT</label>
                                <InputText
                                    id="rut"
                                    name="rut"
                                    value={formData.rut}
                                    onChange={handleInputChange}
                                    required
                                    readOnly={editing}
                                    className='form-control' />
                                {rutError.status === 'error' && <small className="p-error">{rutError.message}</small>}
                                {rutError.status === 'success' && <small className="p-messages-success" style={{ color: 'green' }}>{rutError.message}</small>}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-1">
                                <label htmlFor="nombre" className="font-bold">Nombre</label>
                                <InputText
                                    id="nombre"
                                    name="nombre"
                                    value={formData.name}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, name: e.target.value }))}
                                    required
                                    autoFocus
                                    className='form-control'
                                />
                                {nombreError.status === 'error' && <small className="p-error">{nombreError.message}</small>}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mb2">
                                <label htmlFor="rut" className="font-bold">Correo Electrónico</label>
                                <InputText
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formData.email}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, email: e.target.value }))}
                                    required
                                    className='form-control' />
                                {emailError.status === 'error' && <small className="p-error">{emailError.message}</small>}
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mb2">
                                <label htmlFor="telefono_movil" className="font-bold">Celular</label>
                                <InputText
                                    id="telefono_movil"
                                    name="telefono_movil"
                                    value={formData.telefono_movil}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, telefono_movil: e.target.value }))}
                                    required
                                    autoFocus
                                    className='form-control'
                                />
                                {celularError.status === 'error' && <small className="p-error">{celularError.message}</small>}
                            </div>

                        </div>
                    </div>
                    {!editing ? 
                    <div className='row'>
                        <div className='col-4'>
                            <div className="form-group mb3">
                                <label htmlFor="password" className="font-bold col-12">Contraseña</label>
                                <Password
                                    id='password'     
                                    name='password'                           
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    unstyled={false}
                                    toggleMask={true}
                                    weakLabel="Débil"
                                    mediumLabel="Moderada"
                                    strongLabel="Fuerte"
                                    footer={footerFormChangePassword}
                                    className='col-12'
                                />
                                {/* <InputText
                                    id="password"
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleInputChange}
                                    required
                                    className='form-control' /> */}
                                {passwordError.status === 'error' && <small className="p-error">{passwordError.message}</small>}
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className="form-group mb3">
                                <label htmlFor="password_repeat" className="font-bold col-12">Repita Contraseña</label>
                                <Password
                                    id="password_repeat"
                                    name="password_repeat"
                                    value={formData.password_repeat}
                                    onChange={handleInputChange}
                                    unstyled={false}
                                    toggleMask={true}
                                    weakLabel="Débil"
                                    mediumLabel="Moderada"
                                    strongLabel="Fuerte"
                                    footer={footerFormChangePassword}
                                    className='col-12'
                                />
                                {/* <InputText
                                    id="password_repeat"
                                    name="password_repeat"
                                    type="password"
                                    value={formData.password_repeat}
                                    onChange={handleInputChange}
                                    required
                                    className='form-control' /> */}
                                {passwordRError.status === 'error' && <small className="p-error">{passwordRError.message}</small>}
                                {passwordRError.status === 'success' && <small className="p-messages-success" style={{ color: 'green' }}>{passwordRError.message}</small>}
                            </div>
                        </div>
                        <div className='col-4'>
                            <div className="form-group mb3">
                                <label htmlFor="id_empresa" className="font-bold col-12">Empresa</label>
                                <Dropdown
                                    id="id_empresa"
                                    name="id_empresa"
                                    value={empresaSelected}
                                    onChange={handleDropdownChangeEmpresa}
                                    options={empresas}
                                    optionLabel="nombre"
                                    filter placeholder="Seleccione una empresa" 
                                    className="w-full mt-2" />
                                {empresaError.status === 'error' && <small className="p-error">{empresaError.message}</small>}
                            </div>
                        </div>
                    </div> : '' }
                    <div className='row'>

                    </div>
                </form>
            </Dialog>
        </div>
    )
}
