
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import './App.css';

// import { ListBox } from 'primereact/listbox';
// import { Fieldset } from 'primereact/fieldset'; 
       

// import logo from './logo.svg';

// importación de componentes
import AuthService  from './components/services/AuthService';
import { Home } from './components/home/Home.jsx';
import { AppRouter } from './router/AppRouter.jsx';
import { Login }  from './components/login/Login.jsx';


function App() {

  // const [count,setCount] = useState(0); 

  return ( 
    <>
        <AppRouter />
        <div>
          {AuthService.isAuthenticated() ? <Home /> : <Login />}
        </div>
    </>

    
  );
}

export default App;
