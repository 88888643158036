import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import VehiculosService from '../services/VehiculosService'

export const CantidadAsientosForm = ({ asientosDialog, onHideDialog, dataForm, onRefresh }) => {

    const [statusDialog, setStatusDialog] = useState(false);
    const [cantidad, setCantidad] = useState(true);

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const [formData, setFormData] = useState({
        id: null,
        patente: null,
        tipo_vehiculo: null,
        cantidad: 0,
        created_id: user ? user.id : '',
        created_at: '',
        created_name: '',
        updated_id: '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: ''
    });

    const hideDialog = () => {
        //setSubmitted(false);
        //setEmpresaDialog(false);
        onHideDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };

    const onChangeCantidad = (e) => {

        setCantidad(e.value);
    }

    const save = async () => {
        console.log(formData);

        let api = '';

        api = await VehiculosService.asignarAsientos(formData);

        console.log(api);

        if (api) {
            if (api.data.status === 'success') {
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 }); 
                onRefresh(true);
                onHideDialog();
            }
        }
    }

    const footerContent = (

        <div className='row'>

            <div className='col-md-7 text-muted' style={{ textAlign: 'left' }}>
                {dataForm && dataForm.created_id ? <small><i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i> {dataForm.created_name} el {dataForm.created_at}<br /></small> : ''}
                {dataForm && dataForm.updated_id !== null ? <small><i className='pi pi-user-edit' style={{ fontSize: '1rem' }}></i> {dataForm.updated_name} el {dataForm.updated_at}</small> : ''}
            </div>
            <div className='col-md-5' style={{ textAlign: 'right' }}>
                <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button label="Grabar" icon="pi pi-save" onClick={() => save()} />
            </div>
        </div>

    );

    useEffect(() => {
        setFormData((prevData) => ({...prevData, cantidad: cantidad }));
    }, [cantidad])

    useEffect(() => {
        setStatusDialog(asientosDialog);
        
        setFormData((prevData) => (
            {
                ...prevData,
                id: dataForm.id,
                patente: dataForm.placa_patente,
                tipo_vehiculo: dataForm.tipo_vehiculo,
                cantidad: dataForm.total_asientos === null ? 0 : dataForm.total_asientos
            }
        ));
        console.log(formData);
    }, [asientosDialog, dataForm])

    return (
        <div>
            <Toast ref={toast} />
            <Dialog
                header="Cantidad de asientos"
                visible={statusDialog}
                footer={footerContent}
                maximizable
                onHide={hideDialog}
                style={{ width: '30vw' }}
            >

                <form>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-1">
                                <label htmlFor="rut" className="font-bold">Patente de {formData.tipo_vehiculo}</label>
                                <InputText 
                                    value={formData.patente}
                                    readOnly={true}
                                    className='w-full'
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-1">
                                <label htmlFor="rut" className="font-bold">Cantidad total de asientos</label>
                                <InputNumber
                                    className='w-full'
                                    min={0}
                                    value={formData.cantidad}
                                    onValueChange={onChangeCantidad}
                                    showButtons
                                />
                            </div>
                        </div>
                    </div>
                </form>

            </Dialog>
        </div>
    )
}
