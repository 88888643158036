import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode'; // Cambiado de qrcode.react a qrcode
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from '../../assets/images/alpina.png'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const CredencialPDF = ({ generateCredencial }) => {

  const [codigo, setCodigo] = useState('');
  const [tipoContrato, setTipoContrato] = useState('');
  const [tipoCredencial, setTipoCredencial] = useState('');
  const [nombreEmpleado, setNombreEmpleado] = useState('');
  const [rutEmpleado, setRutEmpleado] = useState('');
  const [fotoEmpleado, setFotoEmpleado] = useState('');
  const [logoEmpresa, setLogoEmpresa] = useState('');
  const [nombreEmpresa, setNombreEmpresa] = useState('');
  const [nombreArea, setNombreArea] = useState('');
  const [terminoContrato, setTerminoContrato] = useState('');
  const [generaState, setGeneraState] = useState(false);


  const formatRut = (rut) => {
    // Eliminar caracteres no numéricos
    const cleanedValue = rut.replace(/[^kK\d]/g, '');

    // Separar el RUT en parte numérica y dígito verificador
    const rutParteNumerica = cleanedValue.slice(0, -1);
    const rutDV = cleanedValue.slice(-1);

    // Aplicar formato con puntos y guión
    let formattedRut = '';
    let i = rutParteNumerica.length;

    while (i > 0) {
      formattedRut = rutParteNumerica.slice(Math.max(0, i - 3), i) + (formattedRut ? '.' + formattedRut : '');
      i -= 3;
    }

    return formattedRut + '-' + rutDV;
  };

  const generatePDF = async () => {
    try {
      // Generar el código QR como un data URL
      const qrDataURL = await QRCode.toDataURL(codigo.toString(), {
        errorCorrectionLevel: 'H', // Nivel de corrección de errores alto para mayor calidad
        margin: 1, // Margen mínimo
        scale: 8, // Ajusta la escala para obtener un código QR de alta calidad
      });

      // Convertir las dimensiones de centímetros a puntos
      const anchoEnCentimetros = 6.5;
      const altoEnCentimetros = 10.3;

      const anchoEnPuntos = (anchoEnCentimetros / 2.54) * 72;
      const altoEnPuntos = (altoEnCentimetros / 2.54) * 72;

      // Convertir las dimensiones de las columnas y filas a puntos
      const anchosColumnasEnCm = [0.03, 1.9, 1.9, 0.03, 0.3, 0.05, 0.04];
      const altoFilasEnCm = [1.2, 2.4, 2.3, 0.1, 0.01, 0.25, 0.25, 0.25, 0.25, 0.25];

      const anchosColumnasEnPuntos = anchosColumnasEnCm.map(ancho => (ancho / 2.54) * 72);
      const altoFilasEnPuntos = altoFilasEnCm.map(alto => (alto / 2.54) * 72);

      // Definir la estructura del documento PDF
      const documentDefinition = {
        pageSize: {
          width: anchoEnPuntos,
          height: altoEnPuntos,
        },
        pageMargins: [0, 0, 0, 0],
        content: [
          // { text: `Nombre: ${nombreEmpleado}`, fontSize: 14, margin: [0, 0, 0, 20] },
          // { image: qrDataURL, width: 100, margin: [0, 0, 0, 20] },
          {
            table: {
              widths: anchosColumnasEnPuntos,
              heights: altoFilasEnPuntos,
              margin: [0, 0, 0, 0],
              padding: [0, 0, 0, 0],
              body: [
                [
                  {
                    image: logoEmpresa,
                    colSpan: 4,
                    width: 142,
                    height: 28,
                    alignment: 'center',
                    margin: [0, 5, 0, 0],
                    padding: [0, 0, 0, 0],
                    border: [true, true, false, false]
                  },
                  '',
                  '',
                  '',
                  { text: '', fillColor: '#007634', border: [false, true, false, false] },
                  { text: '', border: [false, true, false, false] },
                  { text: '', fillColor: '#CD2529', border: [false, true, true, false] }
                ],
                [
                  { text: '', border: [true, false, false, false] },

                  // tipo contrato 1 indefinido, 2 plazo fijo y 3 proveedor
                  (parseInt(tipoContrato) === 1 ?

                    (fotoEmpleado !== null ?
                      {
                        image: fotoEmpleado,
                        width: 112,
                        height: 140.2,
                        margin: [1, 3, 0, 0],
                        padding: [0, 0, 0, 0],
                        rowSpan: 2,
                        colSpan: 2,
                        border: [false, false, false, false]
                      }
                      :
                      {
                        text: '', border: [false, false, false, false]
                      }
                    )
                    :
                    {
                      image: qrDataURL,
                      colSpan: 3,
                      rowSpan: 5,
                      width: 125,
                      margin: [0, 25, 8, 0],
                      alignment: 'center',
                      border: [false, false, false, false]
                    }

                  ),
                  { text: '', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  {
                    text: '',
                    fillColor: '#007634',
                    color: 'white',
                    border: [false, false, false, false]
                  },
                  { text: '', border: [false, false, false, false] },
                  {
                    text: '',
                    fillColor: '#CD2529',
                    border: [false, false, true, false]
                  }
                ],
                [
                  { text: '', border: [true, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', fillColor: '#CD2529', border: [false, false, true, false] }
                ],
                [
                  (parseInt(tipoContrato) === 1 ?
                    {
                      text: `${nombreArea.toUpperCase()}`,
                      colSpan: 4,
                      fontSize: 10,
                      alignment: 'center',
                      border: [true, false, false, false]
                    }
                    :
                    {
                      text: `${String(codigo).padStart(7, '0')}`,
                      colSpan: 4,
                      fontSize: 8,
                      alignment: 'center',
                      margin: [0, 15, 8, 10],
                      border: [true, false, false, false]
                    }
                  ),
                  { text: '', border: [false, false, false, false], colSpan: 3 },
                  '',
                  '',
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', border: [false, false, false, false] },
                  { text: '', fillColor: '#CD2529', border: [false, false, true, false] }
                ],
                [
                  (parseInt(tipoContrato) === 1 ?
                    {
                      text: `${nombreEmpleado.toUpperCase()}`,
                      fillColor: '#007634',
                      color: 'white',
                      fontSize: 11,
                      bold: true,
                      colSpan: 3,
                      rowSpan: 2,
                      alignment: 'center',
                      margin: [0, 3, 0, 0],
                      border: [true, false, false, false]
                    }
                    :
                    (parseInt(tipoContrato) === 2 ?
                      {
                        text: `PLAZO FIJO`,
                        fillColor: '#007634',
                        color: 'white',
                        fontSize: 20,
                        colSpan: 4,
                        rowSpan: 2,
                        alignment: 'center',
                        bold: true,
                        margin: [0, 4, 0, 0],
                        border: [true, false, false, false]
                      }
                      :
                      (nombreEmpleado === null ?
                        {
                          text: `${tipoCredencial.toUpperCase()}`,
                          fillColor: '#007634',
                          color: 'white',
                          fontSize: 20,
                          colSpan: 4,
                          rowSpan: 2,
                          alignment: 'center',
                          bold: true,
                          margin: [0, 4, 0, 0],
                          border: [true, false, false, false]
                        }
                        :
                        {
                          text: `${tipoCredencial.toUpperCase()}`,
                          fillColor: '#007634',
                          color: 'white',
                          fontSize: 12,
                          colSpan: 4,
                          rowSpan: 2,
                          alignment: 'center',
                          bold: true,
                          margin: [0, 4, 0, 0],
                          border: [true, false, false, false]
                        }
                      )
                    )
                  ),
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  {
                    image: qrDataURL,
                    colSpan: 4,
                    rowSpan: 5,
                    width: 50,
                    height: 50,
                    alignment: 'center',
                    fillColor: '#007634',
                    margin: [0, 4, 4, 0],
                    border: [false, false, true, false]
                  },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, true, false] },
                ],
                [
                  { text: '', fillColor: '#007634', border: [true, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, true, false] }
                ],
                [
                  (parseInt(tipoContrato) === 1 ?
                    {
                      text: `C.I: ${formatRut(rutEmpleado)}`,
                      fillColor: '#007634',
                      colSpan: 3,
                      color: 'white',
                      alignment: 'center',
                      fontSize: 10,
                      border: [true, false, false, false]
                    }
                    :
                    (rutEmpleado !== null ?
                      {
                        text: `${nombreEmpleado.toUpperCase()}`,
                        fillColor: '#007634',
                        colSpan: 3,
                        color: 'white',
                        alignment: 'center',
                        fontSize: 8,
                        border: [true, false, false, false]
                      }
                      :
                      {
                        text: ``,
                        fillColor: '#007634',
                        colSpan: 3,
                        color: 'white',
                        alignment: 'center',
                        fontSize: 7,
                        border: [true, false, false, false]
                      }
                    )
                  ),
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, true, false] }
                ],
                [
                  (parseInt(tipoContrato) === 1 ?
                    {
                      text: `${nombreEmpresa}`,
                      fillColor: '#007634',
                      colSpan: 3,
                      rowSpan: 2,
                      color: 'white',
                      alignment: 'center',
                      fontSize: 9,
                      border: [true, false, false, false]
                    }
                    :
                    (rutEmpleado !== null ?
                      {
                        text: `C.I.: ${formatRut(rutEmpleado)}`,
                        fillColor: '#007634',
                        colSpan: 4,
                        rowSpan: 2,
                        color: 'white',
                        alignment: 'center',
                        fontSize: 9,
                        bold: false,
                        border: [true, false, false, false]
                      }
                      :
                      {
                        text: `LA ALPINA`,
                        fillColor: '#007634',
                        colSpan: 4,
                        rowSpan: 2,
                        color: 'white',
                        alignment: 'center',
                        fontSize: 16,
                        bold: false,
                        border: [true, false, false, false]
                      }
                    )
                  ),
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, true, false] }
                ],
                [
                  { text: '', fillColor: '#007634', border: [true, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, false, false] },
                  { text: '', fillColor: '#007634', border: [false, false, true, false] }
                ],
                [
                  (parseInt(tipoContrato) === 1 ?
                    {
                      text: `${terminoContrato}`,
                      fillColor: '#007634',
                      border: [true, false, false, true],
                      colSpan: 3,
                      fontSize: 6,
                      color: 'white',
                      alignment: 'center',
                    }
                    :
                    {
                      text: ``,
                      fillColor: '#007634',
                      border: [true, false, false, true],
                      colSpan: 3,
                      fontSize: 6,
                      color: 'white',
                      alignment: 'center',
                    }
                  ),
                  { text: '', fillColor: '#007634', border: [false, false, false, true] },
                  { text: '', fillColor: '#007634', border: [false, false, false, true] },
                  (parseInt(tipoContrato) === 1 ?
                    {
                      text: `${String(codigo).padStart(7, '0')}`,
                      fillColor: '#007634',
                      colSpan: 4,
                      color: 'white',
                      alignment: 'center',
                      fontSize: 6,
                      border: [false, false, true, true]
                    }
                    :
                    {
                      text: ``,
                      fillColor: '#007634',
                      colSpan: 4,
                      color: 'white',
                      alignment: 'center',
                      fontSize: 6,
                      border: [false, false, true, true]
                    }
                  ),
                  { text: '', fillColor: '#007634', border: [false, false, false, true] },
                  { text: '', fillColor: '#007634', border: [false, false, false, true] },
                  { text: '', fillColor: '#007634', border: [false, false, true, true] }
                ],
              ],
            },
          },
        ]
      };

      // Generar el PDF y abrirlo en una nueva ventana
      pdfMake.createPdf(documentDefinition).open();
      setGeneraState(false);
    } catch (error) {
      //console.error('Error al generar el código QR y el PDF', error);
      setGeneraState(false);
    }
  };

  useEffect(() => {

    if (generateCredencial.load === true) {
      setCodigo(generateCredencial.id);
      setTipoContrato(generateCredencial.tipo_contrato);
      setTipoCredencial(generateCredencial.tipo_credencial);
      setNombreEmpleado(generateCredencial.nombre);
      setRutEmpleado(generateCredencial.rut_dv);
      setFotoEmpleado(generateCredencial.foto);
      setLogoEmpresa(generateCredencial.logo_empresa);
      setNombreEmpresa(generateCredencial.nombre_empresa);
      setNombreArea(generateCredencial.nombre_area);
      setTerminoContrato(generateCredencial.termino_contrato);
      setGeneraState(true);

    }

  }, [generateCredencial]);

  useEffect(() => {
    // reset form
    if (generaState) {
      generatePDF();
    }

  }, [generaState]);


}



