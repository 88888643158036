import React from 'react';
import cliente from './baseURL.tsx';

class UsuariosService extends React.Component {
  
    obtener = async () => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('users/obtener', {}, config);

            return response;

                
        } catch (error) {
            console.error('Error al obtener usuarios', error);
            return false;
        }
  };

    crear = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('users/crear', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al crear usuario', error);
            return false;
        }
    };


    actualizar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('users/actualizar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al actualizar usuario', error);
            return false;
        }
    };
    
    
    eliminar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('users/eliminar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al eliminar empresa', error);
            return false;
        }
    }; 
    
    findUser = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('users/find', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al buscar usuario', error);
            return false;
        }
    };

}

export default new UsuariosService();