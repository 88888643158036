import { Dropdown } from 'primereact/dropdown'
import { InputMask } from 'primereact/inputmask'
import React, { useEffect, useRef, useState } from 'react'
import { ToggleButton } from 'primereact/togglebutton'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button'
import VehiculosTipoService from '../services/VehiculosTipoService'
import VehiculosService from '../services/VehiculosService'
import { Toast } from 'primereact/toast'

export const DataForm = ({ statusDialog, onHideDialog, dataForm, onRefresh, editing, headerTitle, loadForm }) => {

    const [firstLoad, setFirstLoad] = useState(false);
    const [dialog, setDialog] = useState(false);
    const [patente, setPatente] = useState('');
    const [estado, setEstado] = useState(true);
    const [formMode, setFormMode] = useState('insert');
    const [tipoVehiculos, setTipoVehiculos] = useState(null);
    const [tvehiculoSelected, setTVehiculoSelected] = useState(null);
    const patenteRef = useRef(null);

    // validaciones de error
    const [patenteError, setPatenteError] = useState('');
    const [tVehiculoError, setTVehiculoError] = useState('');


    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);


    const [formData, setFormData] = useState({
        id: null,
        patente: '',
        tipo_vehiculo: null,
        estado: estado,
        created_id: user ? user.id : '',
        created_at: '',
        created_name: '',
        updated_id: '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: ''
    });

    const resetForm = () => {
        setFormData({
            id: null,
            patente: '',
            tipo_vehiculo: null,
            estado: estado,
            created_id: user ? user.id : '',
            created_at: '',
            created_name: '',
            updated_id: '',
            updated_at: '',
            updated_name: '',
            deleted_id: '',
            deleted_at: '',
            deleted_name: ''
        });

        setPatente('');
        setTVehiculoSelected('');
        setEstado(true);
    }

    const onChangePatente = (e) => {
        // Convierte el valor a mayúsculas y establece el estado
        const inputValue = e.value.toUpperCase();
        patenteRef.current = inputValue;
    };

    const onBlurPatente = () => {
        setPatente(patenteRef.current);
    };

    const hideDialog = () => {
        //setSubmitted(false);
        setDialog(false);
        onHideDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };

    const obtenerTipoVehiculos = async () => {

        const response = await VehiculosTipoService.obtener();

        if (response) {
            setTipoVehiculos(response.data.tvehiculos);
        }

    }

    const onChangeTVehiculo = (e) => {
        setTVehiculoSelected(e.value);
    }

    const onChangeEstado = (e) => {
        setEstado(e.value);
    }

    useEffect(() => {
        setFormData((prevData) => ({ ...prevData, patente: patente }));
    }, [patente])

    useEffect(() => {
        setFormData((prevData) => ({ ...prevData, tipo_vehiculo: tvehiculoSelected }));
    }, [tvehiculoSelected])


    useEffect(() => {
        setFormData((prevData) => ({ ...prevData, estado: estado }));
    }, [estado])

    const onSave = async (e) => {

        e.preventDefault();

        //setSubmitted(true);
        console.log(formData);

        // valida patente
        if (formData.patente === '') {
            setPatenteError({ status: 'error', message: `Ingrese patente ❌` });
            return;
        } else {
            setPatenteError({ status: '', message: `` });
        }

        // valida tipo vehículo
        if (formData.tipo_vehiculo === null) {
            setTVehiculoError({ status: 'error', message: `Seleccione tipo de vehículo ❌` });
            return;
        } else {
            setTVehiculoError({ status: '', message: `` });
        }

        let api = '';
        if (editing) {
            api = await VehiculosService.actualizar(formData);
        } else {
            api = await VehiculosService.crear(formData);
        }


        if (api) {
            if (api.data.status === 'success') {
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(true);
                onHideDialog();
            }
        }
    }

    useEffect(() => {

        setFirstLoad(loadForm);

    }, [loadForm]);

    useEffect(() => {

        setDialog(statusDialog);
        obtenerTipoVehiculos();

        if (statusDialog && editing && dataForm) {

            setFormMode('edit');
            setPatente(dataForm.placa_patente);
            setEstado(dataForm.vigente);

            setFormData((prevData) => (
                {
                    ...prevData,
                    id: dataForm.id,
                    patente: dataForm.placa_patente,
                    estado: dataForm.vigente,
                    created_id: dataForm.created_id,
                    created_at: dataForm.created_at,
                    created_name: null,
                    updated_id: user ? user.id : '',
                }));

            if (firstLoad) {
                const selTVehiculo = tipoVehiculos.find(item => item.id === dataForm.tipo_id);
                setTVehiculoSelected(selTVehiculo);
            }


        } else {
            // limpiar campos
            resetForm();
        }



    }, [statusDialog, editing, dataForm])


    const footerContent = (

        <div className='row'>

            <div className='col-md-7 text-muted' style={{ textAlign: 'left' }}>
                {dataForm && dataForm.created_id !== null ? <small><i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i> {dataForm.created_name} el {dataForm.created_at}<br /></small> : ''}
                {dataForm && dataForm.updated_id !== null ? <small><i className='pi pi-user-edit' style={{ fontSize: '1rem' }}></i> {dataForm.updated_name} el {dataForm.updated_at}</small> : ''}

            </div>
            <div className='col-md-5' style={{ textAlign: 'right' }}>
                {/* <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button label="Grabar" icon="pi pi-save"  /> */}
                <div className='row'>
                    <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} className="p-button-text mr-1" />
                    <Button label="Grabar" icon="pi pi-save" onClick={onSave} />
                </div>

            </div>
        </div>

    );

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header={headerTitle} footer={footerContent} maximizable visible={dialog} onHide={hideDialog} style={{ width: '30vw' }}>
                <form>
                    <div className='row'>
                        <div className='col-6'>

                            <div className="form-group mb-1">
                                <label htmlFor="rut" className="font-bold">Patente</label>
                                <InputMask
                                    id='placa_patente'
                                    name='placa_patente'
                                    value={ patente }
                                    mask='****-**-9'
                                    className='w-full font-weight-bold'
                                    style={{ fontSize: '30px' }}
                                    placeholder='AA-BB-99'
                                    onBlur={ onBlurPatente }
                                    onChange={ onChangePatente }
                                    readOnly={ editing }
                                />
                                {patenteError.status === 'error' && <small className="p-error">{patenteError.message}</small>}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-3">
                                <label htmlFor="rut" className="font-bold">Tipo vehículo</label>
                                <Dropdown
                                    id='tipo_vehiculo'
                                    name='tipo_vehiculo'
                                    value={tvehiculoSelected}
                                    options={tipoVehiculos}
                                    onChange={onChangeTVehiculo}
                                    optionLabel='nombre'
                                    filter
                                    placeholder='Seleccione tipo de vehículo'
                                    className='w-full'
                                />
                                {tVehiculoError.status === 'error' && <small className="p-error">{tVehiculoError.message}</small>}
                            </div>


                            <div className="form-group mb-3">
                                <label htmlFor="activo" className="font-bold">Estado</label>
                                <ToggleButton
                                    id="estado"
                                    name="estado"
                                    onLabel="Activo"
                                    offLabel="Inactivo"
                                    onIcon="pi pi-check"
                                    offIcon="pi pi-times"
                                    checked={estado}
                                    onChange={onChangeEstado}
                                    className='w-full' />

                            </div>


                        </div>


                    </div>
                </form>
            </Dialog>
        </div>
    )
}
