import { Button } from 'primereact/button'
import { Dialog } from 'primereact/dialog'
import { InputNumber } from 'primereact/inputnumber'
import { InputText } from 'primereact/inputtext'
import { Toast } from 'primereact/toast'
import React, { useEffect, useRef, useState } from 'react'
import VehiculosService from '../services/VehiculosService'

export const LectorCredencialForm = ({ lectorDialog, onHideDialog, dataForm, onRefresh }) => {

    const [statusDialog, setStatusDialog] = useState(false);
    const [lectorId, setLectorId] = useState(null);

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const [formData, setFormData] = useState({
        id: null,
        patente: null,
        lector_id: null,
        tipo_vehiculo: null,
        created_id: '',
        created_at: '',
        created_name: '',
        updated_id: user ? user.id : '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: ''
    });

    const hideDialog = () => {
        //setSubmitted(false);
        //setEmpresaDialog(false);
        onHideDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };

    const onChangeLector= (e) => {
        setLectorId(e.target.value);
    }

    const save = async () => {
        console.log(formData);

        let api = '';

        api = await VehiculosService.asociarLector(formData);

        console.log(api);

        if (api) {
            if (api.data.status === 'success') {
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(true);
                onHideDialog();
            }

            if (api.data.status === 'error') {
                toast.current.show({ severity: 'error', summary: 'Error', detail: `${api.data.message}`, life: 3000 });
                onRefresh(true);
                onHideDialog();
            }
        }
    }

    const footerContent = (

        <div className='row'>

            <div className='col-md-7 text-muted' style={{ textAlign: 'left' }}>
                {dataForm && dataForm.created_id ? <small><i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i> {dataForm.created_name} el {dataForm.created_at}<br /></small> : ''}
                {dataForm && dataForm.updated_id !== null ? <small><i className='pi pi-user-edit' style={{ fontSize: '1rem' }}></i> {dataForm.updated_name} el {dataForm.updated_at}</small> : ''}
            </div>
            <div className='col-md-5' style={{ textAlign: 'right' }}>
                <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button label="Grabar" icon="pi pi-save" onClick={() => save()} />
            </div>
        </div>

    );

    useEffect(() => {
        console.log(lectorId);
        setFormData((prevData) => ({ ...prevData, lector_id: lectorId }));
    }, [lectorId])

    useEffect(() => {
        setStatusDialog(lectorDialog);

        setFormData((prevData) => (
            {
                ...prevData,
                id: dataForm.id,
                patente: dataForm.placa_patente,
                lector_id: dataForm.lector_id,
                tipo_vehiculo: dataForm.tipo_vehiculo
            }
        ));
        console.log(dataForm);
    }, [lectorDialog, dataForm])

    return (
        <div>
            <Toast ref={toast} />
            <Dialog
                header="Lector credenciales asociado a Bus"
                visible={statusDialog}
                footer={footerContent}
                maximizable
                onHide={hideDialog}
                style={{ width: '35vw' }}
            >

                <form>
                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-1">
                                <label htmlFor="patente" className="font-bold">Patente de Bus</label>
                                <InputText
                                    value={formData.patente}
                                    readOnly={true}
                                    className='w-full'
                                    style={{fontWeight: 'bold'}}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-1">
                                <label htmlFor="rut" className="font-bold">ID Lector de Credencial</label>
                                <InputText
                                    value={formData.lector_id}
                                    onChange={onChangeLector}
                                    readOnly={false}
                                    className='w-full'
                                />
                            </div>
                        </div>
                    </div>
                </form>

            </Dialog>
        </div>
    )
}
