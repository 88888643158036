import { useState } from "react";
import LoginService from "../services/LoginService.jsx";
import { Message } from 'primereact/message';
import banner from '../../assets/images/banner2.png';
import logo from '../../assets/images/LOGO_WEB_CAP.png';
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Password } from "primereact/password";
import { Dialog } from "primereact/dialog";

export const Login = () => {

  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailRecovery, setEmailRecovery] = useState('');
  const [password, setPassword] = useState('');
  const [recoveryView, setRecoveryView] = useState(false);
  const [recoveryMessage, setRecoveryMessage] = useState({
    visible: false,
    text: '',
    icon: ''
  });

  // const ingresar = () => {

  // }

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
  };

  const onRecovery = (e) => {
    setRecoveryView(true);
  }
  
  const onRecoveryCancel = (e) => {
    setRecoveryView(false);
  }

  const onChangeEmailRecovery = (e) => {
    setEmailRecovery(e.target.value);
  };

  const styles = {
    fullWidth: {
      width: '100%',
    },
    fullWidthPassword: {
      width: '120%',
    },
    loginContainer: {
      maxWidth: '400px', // Ajusta este valor según sea necesario
      margin: '0 auto',
      padding: '2rem',
      border: '1px solid #ccc',
      borderRadius: '4px',
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    },
    flex1: {
      display: 'flex',
      flexDirection: 'column',
    },
    marginTopBottom: {
      marginTop: '1rem',
      marginBottom: '1rem',
    },
    colContainer: {
      display: 'flex',
      alignItems: 'center',
      // height: '100%',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  };


  const onFormSubmit = async (event) => {

    setLoading(true);
    event.preventDefault(); // evita refresco de navegador

    const formLogin = {
      email: email,
      password: password
    }

    const api = await LoginService.ingresar(formLogin);

    // retornar resultado
    console.log(api);
    if (api.data && api.data.status) {
      if (api.data.status === 'error') {
        setError(api.data.message);
        setLoading(false);
      } else if (api.data.status === 'success') {
        setError('');

        // guardar token en storage
        localStorage.setItem('token', api.data.token);

        // Guardar el usuario en localStorage (si es necesario)
        localStorage.setItem('usuario', JSON.stringify(api.data.usuario));

        // Guardar la fecha de expiración en localStorage
        localStorage.setItem('expiracion', api.data.expiracion);
        setLoading(false);
        console.log("Ingresando a Home");
        window.location.reload();


      } else {
        setError('');
        setLoading(false);
      }

    } else {
      setError('');
      setLoading(false);
    }


  }

  const onFormSubmitRecovery = async (event) => {

    setLoading(true);
    event.preventDefault(); // evita refresco de navegador

    const formLogin = {
      email: emailRecovery
    }

    const api = await LoginService.recovery(formLogin);

    // retornar resultado
    console.log(api);
    if (api.data && api.data.status) {
      if (api.data.status === 'error') {
        setRecoveryMessage({
          visible: true,
          text: `${api.data.message}`,
          icon: 'error'
        });
        setLoading(false);
      } else if (api.data.status === 'success') {

        setRecoveryMessage({
          visible: true,
          text: `Se ha enviado una nueva contraseña a su correo electrónico <b> ${emailRecovery}</b>`,
          icon: 'success'
        });

        setLoading(false);

      } else {
        setRecoveryMessage({
          visible: true,
          text: `${api.data.message}`,
          icon: 'error'
        });
        setLoading(false);
      }

    } else {
        setRecoveryMessage({
          visible: true,
          text: `No ha sido posible enviar un correo de recuperación de contraseña`,
          icon: 'error'
        });
      setLoading(false);
    }


  }

  return (

    <div className="wrapper vh-100 d-flex ml-0 pl-0">

      <div className="col-lg-7 col-md-8 pl-0 d-flex align-items-center justify-content-center">
        {/* Aquí puedes colocar tu imagen */}
        <img src={banner} className="img-fluid w-100" alt="Descripción de la imagen" />
      </div>

      {!recoveryView ?
        <div className="col-lg-5 col-md-8">
          <div className="row align-items-center h-100">
            <div className="col-lg-7 col-md-4 col-10 mx-auto text-center" style={styles.colContainer}>

              <a className="navbar-brand mx-auto mt-2 flex-fill text-center mb-5">
                <img src={logo} className="w-10" alt="Descripción de la imagen" />
              </a>

              {/* <h1 className="h4 mb-3">SISTEMA CONTROL DE ACCESOS</h1> */}
              <div className="row col-12" style={styles.fullWidth}>
                <InputText
                  type="email"
                  id="email"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  placeholder="Correo Electrónico"
                  required
                  autoFocus
                  className="p-inputtext p-component"
                  style={styles.fullWidth}
                />
              </div>

              <div className="row col-12" style={{ ...styles.fullWidth, ...styles.marginTopBottom }}>
                <Password
                  id="password"
                  name="password"
                  feedback={false}
                  toggleMask
                  value={password}
                  onChange={onChangePassword}
                  placeholder="Contraseña"
                  unstyled={true}
                  style={styles.fullWidth}
                  inputStyle={styles.fullWidth}
                />
              </div>

              {/* <Button label="Login" className="p-button p-component" style={styles.fullWidth} /> */}
              {/* Mostrar el mensaje de error si existe */}
              {error &&
                <div className="mb-2">
                  <Message severity="error" text={error} />
                </div>
              }

              <div className="checkbox mb-3">
                <label>
                  <input type="checkbox" value="remember-me" /> No cerrar sesión </label>
              </div>
              <button
                className="btn btn-lg btn-block"
                style={{ backgroundColor: '#00823b', color: 'white' }}
                onClick={onFormSubmit}
              >
                {loading ?
                  <div className="spinner-border text-light spinner-border-sm mr-2 mb-1" role="status">
                  </div>
                  : ''}
                Ingresar
              </button>
              <Button
                label="Recuperar contraseña"
                link
                className="mt-2"
                onClick={onRecovery}
              />
              <p className="mt-5 mb-3 text-muted">LA ALPINA © 2023</p>
            </div>
          </div>
        </div>

        :

        <div className="col-lg-5 col-md-8">
          <div className="row align-items-center h-100">
            <div className="col-lg-7 col-md-4 col-10 mx-auto text-center" style={styles.colContainer}>

              <a className="navbar-brand mx-auto mt-2 flex-fill text-center mb-5">
                <img src={logo} className="w-10" alt="Descripción de la imagen" />
              </a>


              <div style={styles.fullWidth}>
                <label>Ingrese su correo electrónico para enviar un mail de recuperación de contraseña.</label>
                <InputText
                  type="email"
                  id="email"
                  name="email"
                  value={emailRecovery}
                  onChange={onChangeEmailRecovery}
                  placeholder="Correo Electrónico"
                  required
                  autoFocus
                  className="p-inputtext p-component"
                  style={styles.fullWidth}
                />
              </div>

              <button
                className="btn btn-lg btn-block mt-3"
                style={{ backgroundColor: '#00823b', color: 'white' }}
                onClick={onFormSubmitRecovery}
              >
                {loading ?
                  <div className="spinner-border text-light spinner-border-sm mr-2 mb-1" role="status">
                  </div>
                  : ''}
                Recuperar contraseña
              </button>
              <Button
                label="Cancelar"
                link
                className="mt-2"
                onClick={onRecoveryCancel}
              />
              <p className="mt-5 mb-3 text-muted">LA ALPINA © 2023</p>

            </div>
          </div>
        </div>
      }


      <Dialog
        header="Notificación"
        visible={recoveryMessage.visible}
        style={{ width: '50vw' }}
        onHide={() => setRecoveryMessage({ ...recoveryMessage, visible: false })}
      >
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {recoveryMessage.icon === 'success' ?
            <i className="pi pi-check" style={{ color: 'green', fontSize: '1.5rem', fontWeight: 'bold', marginRight: '10px' }}></i>
            :
            <i className="pi pi-times" style={{ color: 'red', fontSize: '1.5rem', fontWeight: 'bold', marginRight: '10px' }}></i>
          }
          <span dangerouslySetInnerHTML={{ __html: recoveryMessage.text }} />
        </div>
      </Dialog>

    </div>



  )
}