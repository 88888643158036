import React, { useEffect, useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { InputSwitch } from 'primereact/inputswitch';
import { Toast } from 'primereact/toast';
import EmpresasService from '../services/EmpresasService';
import EmpleadosService from '../services/EmpleadosService';
import UsuariosService from '../services/UsuariosService';
import { Editor } from "primereact/editor";

export const DataForm = ({ statusDialog, onHideDialog, onRefresh, dataForm, editing, headerTitle, loadForm }) => {


    const [firstLoad, setFirstLoad] = useState(false);
    const [empresaDialog, setEmpresaDialog] = useState(false);
    const [empresas, setEmpresas] = useState(null);

    const [empresaSelected, setEmpresaSelected] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [foundUser, setFoundUser] = useState(false)

    const [chkChofer, setChkChofer] = useState(false);
    const [chkResidente, setChkResidente] = useState(false);
    const [chkBloqueado, setChkBloqueado] = useState(false);
    const [motivoBloqueo, setMotivoBloqueo] = useState(null);

    // validación de errores
    const [rutError, setRutError] = useState('');
    const [nombreError, setNombreError] = useState('');
    const [celularError, setCelularError] = useState('');
    const [empresaError, setEmpresaError] = useState('');
    const [motivoError, setMotivoError] = useState('')

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const [formData, setFormData] = useState({
        id: '',
        nombre: '',
        rut: '',
        telefono: '',
        id_empresa: '',
        es_chofer: '',
        id_vehiculo: null,
        es_residente: '',
        bloqueado: '',
        motivo_bloqueo: null,
        created_id: user ? user.id : '',
        created_at: '',
        created_name: '',
        updated_id: '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: ''
    });


    const hideDialog = () => {
        setSubmitted(false);
        setEmpresaDialog(false);
        onHideDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };


    const formatRut = (rut) => {
        // Eliminar caracteres no numéricos
        const cleanedValue = rut.replace(/[^kK\d]/g, '');

        // Separar el RUT en parte numérica y dígito verificador
        const rutParteNumerica = cleanedValue.slice(0, -1);
        const rutDV = cleanedValue.slice(-1);

        // Aplicar formato con puntos y guión
        let formattedRut = '';
        let i = rutParteNumerica.length;

        while (i > 0) {
            formattedRut = rutParteNumerica.slice(Math.max(0, i - 3), i) + (formattedRut ? '.' + formattedRut : '');
            i -= 3;
        }

        return formattedRut + '-' + rutDV;
    };

    const handleBlur = async () => {

        setFoundUser(false);

        if (formData.rut.trim() !== '' && rutError.status === 'success') {
            //buscarEmpleado();
            const response = await UsuariosService.findUser(formData);

            if (response.data.status === 'success') {

                if (response && response.data.find_user) {

                    if (response.data.find_user.length > 0) {

                        setFoundUser(true);
                        const userData = response.data.find_user[0];
                        console.log(userData);

                        // setFormData((prevData) => ({ ...prevData, rut: userData.rut }));
                        // setFormData((prevData) => ({ ...prevData, dv: userData.dv }));
                        setFormData((prevData) => ({ ...prevData, name: userData.name }));
                        setFormData((prevData) => ({ ...prevData, telefono: userData.telefono_movil}));

                        const selEmpresa = empresas.find(item => item.id === userData.id_empresa);
                        setFormData((prevData) => ({ ...prevData, id_empresa: selEmpresa.id }));
                        setEmpresaError('');
                        setEmpresaSelected(selEmpresa);

                        // setChkChofer(false);
                        // setChkResidente(false);
                        // setChkBloqueado(false);
                        setMotivoBloqueo('');

                    }


                }
            } else if (response.data.status === 'not found') {

                // limpiar campos
                setFoundUser(false);
                setFormData((prevData) => ({ ...prevData, name: '' }));
                setFormData((prevData) => ({ ...prevData, telefono: '' }));
                setEmpresaSelected('');
                setChkChofer(false);
                setChkResidente(false);
                setChkBloqueado(false);
                setMotivoBloqueo('');

                // variable habiliatar campos
            }

        }
    };


    const handleDropdownChangeEmpresa = (e) => {
        //setFirstLoad(false);
        setEmpresaSelected(e.value);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));

        if (name === 'rut') {

            // formatear RUT
            const inputRut = e.target.value;
            const formattedRut = formatRut(inputRut);

            // Actualizar el estado con el RUT formateado
            setFormData((prevData) => ({ ...prevData, rut: formattedRut }));

            // Validar el RUT al cambiar
            const isValid = validarRut(value);

            if (!isValid) {
                setRutError({ status: 'error', message: `RUT no válido ❌` });
            } else if (isValid) {
                setRutError({ status: 'success', message: `RUT válido ✅` });
            } else if (formData.rut === '') {
                setRutError({ status: 'success', message: `` });
            }
        }

    };


    const validarRut = (rut) => {
        rut = rut.replace(/\./g, '').replace(/-/g, '');

        if (!/^[0-9]+[0-9kK]{1}$/.test(rut)) {
            return false;
        }

        const rutNumerico = parseInt(rut.slice(0, -1), 10);
        const verificador = rut.slice(-1).toLowerCase();
        const dvEsperado = calcularDigitoVerificador(rutNumerico);

        if (rut.length > 8) { // este validador permite que no entregue true antes de tiempo
            return dvEsperado === verificador;
        } else {
            return false;
        }

    };

    const calcularDigitoVerificador = (rutNumerico) => {
        let m = 0;
        let s = 1;

        for (; rutNumerico; rutNumerico = Math.floor(rutNumerico / 10)) {
            s = (s + rutNumerico % 10 * (9 - m++ % 6)) % 11;
        }

        return (s ? s - 1 : 'k').toString();
    };


    const changeChofer = (e) => {
        setChkChofer(!chkChofer);
    }

    const changeResidente = (e) => {
        setChkResidente(!chkResidente);

    }


    const changeBloqueo = (e) => {
        setChkBloqueado(!chkBloqueado);
    }

    const changeMotivoBloqueo = (comentario) => {

        if(comentario !== null){
            console.log(comentario);
            setMotivoBloqueo(comentario);
        }
        
    }


    const save = async (e) => {

        e.preventDefault();

        //setSubmitted(true);
        console.log(formData);
        // valida rol


        // valida RUT
        if (formData.rut === '') {
            setRutError({ status: 'error', message: `Ingrese RUT ❌` });
            return;
        } else {
            // Validar el RUT al cambiar
            const isValid = validarRut(formData.rut);

            if (!isValid) {
                setRutError({ status: 'error', message: `RUT no válido ❌` });
                return;
            } else if (isValid) {
                setRutError({ status: 'success', message: `RUT válido ✅` });
            }
        }

        // validar nombre
        if (formData.name === '') {
            setNombreError({ status: 'error', message: `Ingrese nombre de usuario ❌` });
            return;
        } else {
            setNombreError({ status: '', message: `` });
        }


        // valida empresa
        if (formData.id_empresa === '') {
            setEmpresaError({ status: 'error', message: `Seleccione empresa ❌` });
            return;
        } else {
            setEmpresaError({ status: '', message: `` });
        }

        // validar celular
        if (formData.telefono === '') {
            setCelularError({ status: 'error', message: `Ingrese celular ❌` });
            return;
        } else {
            setCelularError({ status: '', message: `` });
        }

        // validar motivo en caso de tener el empleado con estado bloqueado
        if (formData.bloqueado && formData.motivo_bloqueo === null) {
            setMotivoError({ status: 'error', message: `Ingrese motivo de bloqueo ❌` });
            return;
        } else {
            setMotivoError({ status: '', message: `` });
        }



        // validación exitosa
        let api = '';
        if (editing) {
            api = await EmpleadosService.actualizar(formData);
        } else {
            api = await EmpleadosService.crear(formData);
        }

        if (api) {
            if (api.data.status === 'success') {
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(true);
                setSubmitted(false);
                setEmpresaDialog(false);
                onHideDialog();
            } else {
                toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(false);
                setSubmitted(false);
                setEmpresaDialog(false);
                onHideDialog();
            }
        }



    }


    const obtenerEmpresas = async () => {
        const response = await EmpresasService.obtener();

        if (response) {
            setEmpresas(response.data.empresas);
        }


    }


    const footerContent = (

        <div className='row'>

            <div className='col-md-7 text-muted' style={{ textAlign: 'left' }}>
                {dataForm && dataForm.created_id !== null ? <small><i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i> {dataForm.created_name} el {dataForm.created_at}<br /></small> : ''}
                {dataForm && dataForm.updated_id !== null ? <small><i className='pi pi-user-edit' style={{ fontSize: '1rem' }}></i> {dataForm.updated_name} el {dataForm.updated_at}</small> : ''}
            </div>
            <div className='col-md-5' style={{ textAlign: 'right' }}>
                <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button label="Grabar" icon="pi pi-save" onClick={save} />
            </div>
        </div>

    );

    useEffect(() => {
        // ... el resto de tu código

        setFirstLoad(loadForm);
        console.log(`status change ${firstLoad}`)
    }, [loadForm]);



    // useEffect(() => {
    //     // ... el resto de tu código

    //     console.log(empresaSelected);
    //     setFormData((prevData) => ({ ...prevData, id_empresa: empresaSelected }));

    // }, [empresaSelected]);

    useEffect(() => {
        setEmpresaDialog(statusDialog);

        if (statusDialog && editing && dataForm) {

            obtenerEmpresas();

            setFormData((prevData) => (
                {
                    ...prevData,
                    id: dataForm.id,
                    nombre: dataForm.nombre,
                    rut: `${dataForm.rut}-${dataForm.dv}`,
                    telefono: dataForm.telefono,
                    es_chofer: dataForm.es_chofer,
                    id_vehiculo: dataForm.id_vehiculo,
                    es_residente: dataForm.es_residente,
                    bloqueado: dataForm.bloqueado,
                    motivo_bloqueo: dataForm.motivo_bloqueo,
                    created_id: dataForm.created_id,
                    created_at: dataForm.created_at,
                    created_name: dataForm.created_name,
                    updated_id: user ? user.id : '',
                    updated_at: dataForm.updated_at,
                    updated_name: dataForm.updated_name,
                    deleted_id: dataForm.deleted_id,
                    deleted_at: dataForm.deleted_at,
                    deleted_name: dataForm.deleted_name
                }));

            if (firstLoad) {
                // const selTipoUsuario = usuariosTipos.find(item => item.id === dataForm.id_usuario_tipo);
                // setUsuariosTiposSelected(selTipoUsuario);

                // const selEmpresa = empresas.find(item => item.id === dataForm.id_empresa);
                // setEmpresaSelected(selEmpresa);

                console.log("FIRST LOAD");

            }


        } else {
            // Estás en modo nuevo registro, puedes reiniciar el formulario si es necesario
            obtenerEmpresas();

            // setFormData({
            //     id: '',
            //     nombre: '',
            //     rut: '',
            //     telefono: '',
            //     id_empresa: '',
            //     es_chofer: '',
            //     id_vehiculo: '',
            //     es_residente: '',
            //     bloqueado: '',
            //     motivo_bloqueo: '',
            //     created_id: user ? user.id : '',
            //     created_at: '',
            //     created_name: '',
            //     updated_id: '',
            //     updated_at: '',
            //     updated_name: '',
            //     deleted_id: '',
            //     deleted_at: '',
            //     deleted_name: ''
            // });
        }

    }, [statusDialog, editing, dataForm]);

    useEffect(() => {

        // Actualizar el estado chofer
        setFormData((prevData) => ({ ...prevData, es_chofer: chkChofer }));

    }, [chkChofer])

    useEffect(() => {

        // Actualizar el estado residente
        setFormData((prevData) => ({ ...prevData, es_residente: chkResidente }));

    }, [chkResidente])

    useEffect(() => {

        // Actualizar el estado bloqueado
        setFormData((prevData) => ({ ...prevData, bloqueado: chkBloqueado }));

    }, [chkBloqueado])


    useEffect(() => {

        // Actualizar motivo de bloqueo
        setFormData((prevData) => ({ ...prevData, motivo_bloqueo: motivoBloqueo }));

    }, [motivoBloqueo])

    useEffect(() => {

        // Actualizar empresa change
        setFormData((prevData) => ({ ...prevData, id_empresa: empresaSelected }));

    }, [empresaSelected])

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header={headerTitle} maximizable visible={empresaDialog} footer={footerContent} onHide={hideDialog} style={{ width: '50vw' }}>

                <form>

                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-1">
                                <label htmlFor="rut" className="font-bold">RUT</label>
                                <InputText
                                    id="rut"
                                    name="rut"
                                    value={formData.rut}
                                    onChange={handleInputChange}
                                    required
                                    onBlur={handleBlur}
                                    readOnly={editing}
                                    className='form-control' />
                                {rutError.status === 'error' && <small className="p-error">{rutError.message}</small>}
                                {rutError.status === 'success' && <small className="p-messages-success" style={{ color: 'green' }}>{rutError.message}</small>}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-12'>
                            <div className="form-group mb-1">
                                <label htmlFor="nombre" className="font-bold">Nombre</label>
                                <InputText
                                    id="nombre"
                                    name="nombre"
                                    value={formData.name}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, name: e.target.value }))}
                                    required
                                    disabled={foundUser}
                                    className='form-control'
                                />
                                {nombreError.status === 'error' && <small className="p-error">{nombreError.message}</small>}
                            </div>
                        </div>
                    </div>

                    <div className='row'>
                        <div className='col-6'>
                            <div className="form-group mb3">
                                <label htmlFor="id_empresa" className="font-bold">Empresa</label>
                                <Dropdown
                                    id="id_empresa"
                                    name="id_empresa"
                                    value={empresaSelected}
                                    onChange={handleDropdownChangeEmpresa}
                                    options={empresas}
                                    optionLabel="nombre"
                                    disabled={foundUser}
                                    filter placeholder="Seleccione una empresa"
                                    className="w-full" />
                                {empresaError.status === 'error' && <small className="p-error">{empresaError.message}</small>}
                            </div>
                        </div>
                        <div className='col-6'>
                            <div className="form-group mb3">
                                <label htmlFor="telefono" className="font-bold">Celular</label>
                                <InputText
                                    id="telefono"
                                    name="telefono"
                                    value={formData.telefono}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, telefono: e.target.value }))}
                                    required
                                    disabled={foundUser}
                                    className='form-control'
                                />
                                {celularError.status === 'error' && <small className="p-error">{celularError.message}</small>}
                            </div>

                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-3'>
                            <div className="form-group mb3">
                                <label htmlFor="es_chofer" className="font-bold">Es Chofer?</label>
                                <div className="card flex justify-content-center">
                                    <InputSwitch
                                        id="es_chofer"
                                        name="es_chofer"
                                        value={formData.es_chofer}
                                        checked={chkChofer}
                                        onChange={(e) => changeChofer(e.value)} />
                                </div>

                            </div>
                        </div>
                        <div className='col-3'>
                            <div className="form-group mb3">
                                <label htmlFor="es_residente" className="font-bold">Es Residente?</label>
                                <div className="card flex justify-content-center">
                                    <InputSwitch
                                        id="es_residente"
                                        name="es_residente"
                                        value={formData.es_residente}
                                        checked={chkResidente}
                                        onChange={(e) => changeResidente(e.value)} 
                                        />
                                </div>
                            </div>
                        </div>

                        <div className='col-3'>
                            <div className="form-group mb3">
                                <label htmlFor="bloqueado" className="font-bold">Bloqueado?</label>
                                <div className="card flex justify-content-center">
                                    <InputSwitch
                                        id="bloqueado"
                                        name="bloqueado"
                                        value={formData.bloqueado}
                                        checked={chkBloqueado}
                                        onChange={(e) => changeBloqueo(e.value)}
                                    />
                                </div>
                            </div>
                        </div>

                    </div>
                    {chkBloqueado ?
                        <div className='row'>
                            <div className='col-12'>
                                <div className="form-group mb3">
                                    <label htmlFor="motivo_bloqueo" className="font-bold">Motivo de bloqueo</label>
                                    <div className="card flex justify-content-center">
                                        <Editor 
                                            id="motivo_bloqueo"
                                            name="motivo_bloqueo"
                                            value={formData.motivo_bloqueo} 
                                            onTextChange={(e) => changeMotivoBloqueo(e.htmlValue)} 
                                            style={{ height: '120px' }} />
                                    </div>
                                    {motivoError.status === 'error' && <small className="p-error">{motivoError.message}</small>}
                                </div>
                            </div>
                        </div> : ''}
                </form>
            </Dialog>
        </div>
    )
}
