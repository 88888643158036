import React from 'react';
import cliente from './baseURL.tsx';

class AreasService extends React.Component {
  
    obtener = async () => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('areas/obtener', {}, config);

            return response;

                
        } catch (error) {
            console.error('Error al obtener áreas', error);
            return false;
        }
  };


}

export default new AreasService();