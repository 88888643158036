import React from 'react';
import cliente from './baseURL.tsx';

class VehiculosTipoService extends React.Component {
  
    obtener = async () => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('vehiculos/tipos/obtener', {}, config);

            return response;

                
        } catch (error) {
            console.error('Error al obtener tipos de vehiculos', error);
            return false;
        }
    };

    crear = async (data, file) => {

        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();

            const fechaOriginal = new Date(data.termino_contrato);

            const año = fechaOriginal.getFullYear();
            const mes = ('0' + (fechaOriginal.getMonth() + 1)).slice(-2);
            const dia = ('0' + fechaOriginal.getDate()).slice(-2);
    
            // Formateamos la fecha como yyyy-mm-dd
            const fechaFormateada = `${año}-${mes}-${dia}`;

            // Agrega los campos del objeto data al FormData
            formData.append('nombre', data.nombre);
            formData.append('rut', data.rut);
            formData.append('telefono', data.telefono);
            formData.append('id_empresa', data.id_empresa.id);
            formData.append('area_id', data.area_id.id);
            formData.append('contrato_tipo_id', data.contrato_tipo_id.id);
            formData.append('termino_contrato', fechaFormateada);
            formData.append('es_chofer', data.es_chofer);
            formData.append('es_residente', data.es_residente);
            formData.append('bloqueado', data.bloqueado);
            formData.append('motivo_bloqueo', data.motivo_bloqueo);
            formData.append('created_id', data.created_id);
            formData.append('foto', file);
            formData.append('fotoStatus', data.fotoStatus);

            const config = {
                headers: {
                    // Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            };

            console.log(formData);

            const response = await cliente.post('empleados/crear', formData, config);

            return response;

                
        } catch (error) {
            console.error('Error al crear empresa', error);
            return false;
        }
    };


    actualizar = async (data, file) => {

        try {
            const token = localStorage.getItem('token');
            const formData = new FormData();

            const fechaOriginal = new Date(data.termino_contrato);

            const año = fechaOriginal.getFullYear();
            const mes = ('0' + (fechaOriginal.getMonth() + 1)).slice(-2);
            const dia = ('0' + fechaOriginal.getDate()).slice(-2);
    
            // Formateamos la fecha como yyyy-mm-dd
            const fechaFormateada = `${año}-${mes}-${dia}`;

            // Agrega los campos del objeto data al FormData
            formData.append('id', data.id);
            formData.append('nombre', data.nombre);
            formData.append('rut', data.rut);
            formData.append('telefono', data.telefono);
            formData.append('id_empresa', data.id_empresa.id);
            formData.append('area_id', data.area_id.id);
            formData.append('contrato_tipo_id', data.contrato_tipo_id.id);
            formData.append('termino_contrato', fechaFormateada);
            formData.append('es_chofer', data.es_chofer);
            formData.append('es_residente', data.es_residente);
            formData.append('bloqueado', data.bloqueado);
            formData.append('motivo_bloqueo', data.motivo_bloqueo);
            formData.append('updated_id', data.updated_id);
            formData.append('foto', file);
            formData.append('fotoStatus', data.fotoStatus);

            console.log(formData);

            const config = {
                headers: {
                    // Accept: 'application/json',
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empleados/actualizar', formData, config);

            return response;

                
        } catch (error) {
            console.error('Error al actualizar empresa', error);
            return false;
        }
    };
    
    
    eliminar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empleados/eliminar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al eliminar empresa', error);
            return false;
        }
    };   
    
    obtenerCTCDisponibles = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empleados/CTCDisponibles', { 'contratoTipoId' : data }, config);

            return response;

                
        } catch (error) {
            console.error('Error al obtener empleados sin credenciales', error);
            return false;
        }
    };

}

export default new VehiculosTipoService();