import React, { useEffect, useRef, useState } from 'react';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import CredencialesService from '../services/CredencialesService';

export const DesasociarCredencialForm = ({ credencialDes, onHideDesasociarDialog, onRefresh }) => {

    const [desasociarDialog, setDesasociarDialog] = useState(false);
    const [credencialId, setCredencialId] = useState('');
    const [empleadoId, setEmpleadoId] = useState('');
    const [nombre, setNombre] = useState('');
    const [rutDV, setRutDV] = useState('');
    const [contratoTipoId, setContratoTipoId] = useState('');
    const [submitted, setSubmitted] = useState(false);
    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const [formData, setFormData] = useState({
        credencial_id: '',
        empleado_id: '',
        updated_id: user ? user.id : ''
      });

    const hideDialogD = () => {
        setSubmitted(false);
        setDesasociarDialog(false);
        onHideDesasociarDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };

    const accept = async () => {

        //event.preventDefault(); // evita refresco de navegador
        //setSubmitted(true);
        console.log("desasociando");

        const params = {
            'credencial_id': credencialId,
            'empleado_id': empleadoId,
            'contrato_tipo_id': contratoTipoId,
            'rut_dv': rutDV,
            'nombre': nombre,
            'updated_id': user ? user.id : ''
        }

        const api = await CredencialesService.desasociar(params);

        console.log(api);
        if (api.data.status === 'success') {
            console.log(api.data);

            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });


            onRefresh(true);
        } else {

            toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });

            onRefresh(false);
        }

        //setSubmitted(false);
        onHideDesasociarDialog();

    };

    const reject = () => {
        onHideDesasociarDialog();
    };


    useEffect(() => {

        setDesasociarDialog(credencialDes.status);

    }, [credencialDes]);


    useEffect(() => {

        console.log(credencialDes);
        setCredencialId(credencialDes.credencial_id);
        setEmpleadoId(credencialDes.empleado_id);
        setNombre(credencialDes.nombre);
        setRutDV(credencialDes.rut_dv);
        setContratoTipoId(credencialDes.contrato_tipo_id);

    }, [desasociarDialog]);

    useEffect(() => {
        setFormData((prevData) => ({ ...prevData, credencial_id: credencialId }));
    }, [credencialId]);

    useEffect(() => {

    }, [nombre]);

    useEffect(() => {

    }, [empleadoId]);

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog 
                visible={desasociarDialog} 
                onHide={hideDialogD} 
                message={parseInt(contratoTipoId) > 2 ? nombre !== null ? `¿Desea desasociar la credencial externa ${String(credencialId).padStart(7, '0')} del empleado ${nombre}?` : `¿Desea desasociar la credencial externa ${String(credencialId).padStart(7, '0')}?` : `¿Desea desasociar la credencial ${String(credencialId).padStart(7, '0')} del empleado ${nombre}?`} 
                header="Confirmación" 
                rejectClassName='p-button-danger mr-3'
                acceptLabel='Sí'
                rejectLabel='No'
                icon='pi pi-info-circle'
                accept={accept}
                reject={reject}
            />
        </div>
    );
};
