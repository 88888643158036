import React, { useEffect, useRef, useState } from 'react'
import { Card } from 'primereact/card';
import VehiculosService from '../services/VehiculosService';
import AuthService from '../services/AuthService';
import { DataTable } from 'primereact/datatable';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';
import { Message } from 'primereact/message';
import { Button } from 'primereact/button';
import { Toolbar } from 'primereact/toolbar';
import { DataForm } from './DataForm';
import { Column } from 'jspdf-autotable';
import { CantidadAsientosForm } from './CantidadAsientosForm';
import { DeleteForm } from './DeleteForm';
import { InputIcon } from 'primereact/inputicon';
import { IconField } from 'primereact/iconfield';
import { LectorCredencialForm } from './LectorCredencialForm';
import { CredencialBusPDF } from './CredencialBusPDF';

export const Vehiculos = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated);
  const [load, setLoad] = useState(true);
  const [editing, setEditing] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [asientosDialog, setAsientosDialog] = useState(false);
  const [lectorDialog, setLectorDialog] = useState(false);
  const [vehiculos, setVehiculos] = useState([]);
  const [vehiculo, setVehiculo] = useState([]);
  const [statusDeleteDialog, setStatusDeleteDialog] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [modeDelete, setModeDelete] = useState('');
  const [generateCredencial, setGenerateCredencial] = useState('');

  const dt = useRef(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS }
  });

  const obtener = async () => {

    if (isAuthenticated) {

      const respuesta = await VehiculosService.obtener();

      if (respuesta) {
        setVehiculos(respuesta.data.vehiculos);
      }

    } else {

      window.location.reload();
    }
  }

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
  };

  const onHideDialog = () => {
    setStatusDialog(false);
    setLectorDialog(false);
    setStatusDeleteDialog(false);
    setStatusDialog(false);
  };

  const onHideDeleteDialog = () => {
    setStatusDeleteDialog(false);
    setLectorDialog(false);
    setStatusDialog(false);
  };

  const onDeleteRefresh = (e) => {

    setLoad(e);
    setStatusDeleteDialog(false);
    setDeleting(false);
    setStatusDialog(false);
  }

  const onRefresh = (e) => {

    setLoad(e);
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setDeleting(false);
    setEditing(false);
  }

  const addVehiculo = () => {
    setStatusDialog(true);
    setEditing(false);
  }

  const editAsientos = (rowData) => {
    setVehiculo(rowData);
    setAsientosDialog(true);
    setEditing(true);
  }

  const editVehiculo = (rowData) => {
    setStatusDialog(true);
    setStatusDeleteDialog(false);
    setEditing(true);
    setDeleting(false);
    setVehiculo(rowData);
  }

  const asociarLector = (rowData) => {
    setVehiculo(rowData);
    setLectorDialog(true);
    setEditing(true);
  }

  const deleteVehiculo = (rowData) => {

    setStatusDeleteDialog(true);
    setDeleting(true);
    setVehiculo(rowData);
    setModeDelete('vehiculo');
  }

  const deleteLector = (rowData) => {

    setStatusDeleteDialog(true);
    setDeleting(true);
    setVehiculo(rowData);
    setModeDelete('lector');

  }

  const generarCredencialBus = (row) => {
    console.log(row);
    setGenerateCredencial({
      load: true,
      id: row.id,
      placa_patente: row.placa_patente
    });
  }

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <span className="p-input-icon-left">
        <IconField iconPosition='left'>
          <InputIcon className="pi pi-search" />
          <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
        </IconField>
      </span>
    );
  };

  const header = renderHeader();

  const emptyData = () => {
    return (<div className='text-center'>
      No existen vehículos ingresados
    </div>)
  }

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button label="Nuevo" icon="pi pi-plus" severity="success" rounded onClick={() => addVehiculo()} />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2">
        <Button type="button" icon="pi pi-file-excel" label='Exportar' rounded onClick={() => exportCSV()} data-pr-tooltip="CSV" />
      </div>
    );
  };

  const asientosTemplate = (rowData) => {
    return (<> {rowData.total_asientos === null ? 0 : rowData.total_asientos}</>);
  }

  const lectorIdTemplate = (rowData) => {

    return (
      <>
        {rowData.lector_id === null && parseInt(rowData.tipo_id) === 2 ? <Button icon="pi pi-plus" rounded text label='Asociar lector credencial' severity="success" className="mr-2" onClick={() => asociarLector(rowData)} tooltip='ASOCIAR LECTOR DE CREDENCIALES' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} /> : ''}
        {rowData.lector_id !== null && parseInt(rowData.tipo_id) === 2 ? <Button icon="pi pi-pencil" rounded text label={rowData.lector_id || "No encontrado"} severity="info" className="mr-2" onClick={() => asociarLector(rowData)} tooltip='MODIFICAR LECTOR DE CREDENCIALES' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} /> : ''}
        {rowData.lector_id !== null && parseInt(rowData.tipo_id) === 2 ? <Button icon="pi pi-trash" rounded text severity="danger" className="mr-2" onClick={() => deleteLector(rowData)} tooltip='ELIMINAR LECTOR DE CREDENCIALES' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} /> : ''}
      </>
    )
  }

  const vigenteTemplate = (rowData) => {
    return (<> {rowData.vigente === 0 ? <Message severity="error" style={{ paddingTop: 5, paddingBottom: 5 }} text="Bloqueado" /> : <Message style={{ paddingTop: 5, paddingBottom: 5 }} severity="success" text="Activo" />}</>);
  }

  const actionTemplate = (rowData) => {
    return (
      <React.Fragment>
        {parseInt(rowData.tipo_id) === 2 ? <Button icon="pi pi-car" rounded severity="warning" className="mr-2" onClick={() => editAsientos(rowData)} tooltip='CANTIDAD DE ASIENTOS' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} /> : ''}
        {parseInt(rowData.tipo_id) === 2 ? <Button icon="pi pi-qrcode" rounded severity="secondary" className="mr-2" onClick={() => generarCredencialBus(rowData)} tooltip='GENERAR CREDENCIAL PARA BUS' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} /> : ''}
        <Button icon="pi pi-pencil" rounded severity="info" className="mr-2" onClick={() => editVehiculo(rowData)} tooltip='MODIFICAR VEHÍCULO' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
        <Button icon="pi pi-trash" rounded severity="danger" onClick={() => deleteVehiculo(rowData)} tooltip='ELIMINAR VEHÍCULO' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />

      </React.Fragment>
    );
  };

  useEffect(() => {

  }, [editing]);

  useEffect(() => {

  }, [statusDialog]);

  useEffect(() => {

  }, [onRefresh]);

  useEffect(() => {
    // Este bloque de código se ejecutará cada vez que cambie AuthService.isAuthenticated
    if (AuthService.isAuthenticated) {
      // Lógica cuando isAuthenticated es true
    } else {
      // Lógica cuando isAuthenticated es false
      window.location.reload();
    }
  }, [AuthService.isAuthenticated]);

  useEffect(() => {

    if (load === true) {
      obtener();

      setLoad(false);
    }

  }, [load]);

  return (
    <div className='row justify-content-center'>
      <div className='col-12'>
        <h1>Vehículos</h1>
        <p>Lista de vehículos registrados</p>
        <div className='row'>
          <div className='col-12 my-4'>

            <Card>
              <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>
              <DataTable
                ref={dt}
                header={header}
                value={vehiculos}
                filters={filters}
                exportFilename='Vehículos'
                csvSeparator=';'
                emptyMessage={emptyData}
                paginator
                rows={5}
                rowsPerPageOptions={[5, 10, 25, 50]}
                size='small'
              >
                <Column field="placa_patente" header="Patente" />
                <Column field="tipo_vehiculo" header="Tipo" />
                <Column
                  body={asientosTemplate}
                  header="Asientos"
                  alignHeader={'right'}
                  bodyStyle={{ textAlign: 'right' }}
                />
                <Column
                  body={lectorIdTemplate}
                  header="ID Lector Credencial"
                  alignHeader={'center'}
                  bodyStyle={{ textAlign: 'center' }}
                />
                <Column
                  body={vigenteTemplate}
                  header="Estado"
                  alignHeader={'center'}
                  bodyStyle={{ textAlign: 'center' }}
                />
                <Column
                  body={actionTemplate}
                  header="Acciones"
                  exportable={false}
                  style={{ minWidth: '12rem' }}
                  alignHeader={'right'}
                  bodyStyle={{ textAlign: 'right' }}
                />
              </DataTable>
            </Card>

            <DataForm
              statusDialog={statusDialog}
              onHideDialog={onHideDialog}
              onRefresh={onRefresh}
              dataForm={vehiculo}
              editing={editing}
              headerTitle={editing ? 'Editar vehículo' : 'Nuevo vehículo'}
              loadForm={true}
            />

            <CantidadAsientosForm
              asientosDialog={asientosDialog}
              onHideDialog={onHideDialog}
              dataForm={vehiculo}
              onRefresh={onRefresh}
            />

            <LectorCredencialForm
              lectorDialog={lectorDialog}
              onHideDialog={onHideDialog}
              dataForm={vehiculo}
              onRefresh={onRefresh}
            />

            <DeleteForm
              visibleDeleteDialog={statusDeleteDialog}
              onHideDeleteDialog={onHideDeleteDialog}
              onDeleteRefresh={onDeleteRefresh}
              dataForm={vehiculo}
              mode={modeDelete}
              deleting={deleting}
              loadForm={true}
            />

            <CredencialBusPDF
              generateCredencial={generateCredencial}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
