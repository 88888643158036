import React, { useEffect, useState, useRef } from 'react'
import { Dialog } from 'primereact/dialog'
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Toast } from 'primereact/toast';
import EmpresasService from '../services/EmpresasService';
import { FileUpload } from 'primereact/fileupload';

export const DataForm = ({ statusDialog, onHideDialog, onRefresh, dataForm, editing, headerTitle }) => {


    const [empresaDialog, setEmpresaDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [rut, setRut] = useState('');
    const [formMode, setFormMode] = useState('insert');
    const [fotoStatus, setFotoStatus] = useState('insert');

    const [selectedFile, setSelectedFile] = useState(null);

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const [formData, setFormData] = useState({
        id: '',
        nombre: '',
        rut: '',
        direccion: '',
        telefono: '',
        logo: '',
        logoStatus: '',
        created_id: '',
        created_at: '',
        created_name: '',
        updated_id: '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: '',
    });


    const hideDialog = () => {
        setSubmitted(false);
        setEmpresaDialog(false);
        onHideDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };

    const handleRutChange = (event) => {
        const inputRut = event.target.value;
        const formattedRut = formatRut(inputRut);

        console.log(inputRut);

        // Actualizar el estado con el RUT formateado
        setFormData((prevData) => ({ ...prevData, rut: formattedRut }));
    };

    const decodificarImagen = (foto) => {
        // Comprobar si la propiedad foto existe y es una cadena de texto
        if (foto && typeof foto === 'string') {
            const tipoArchivo = foto.split(';')[0].split(':')[1];
            const base64Data = foto.split(',')[1];
            return `data:${tipoArchivo};base64,${base64Data}`;
        } else {
            // Devolver una URL de imagen por defecto o gestionar el caso según tus necesidades
            return 'url_por_defecto';
        }
    };

    const cambiarFoto = (e) => {

        e.preventDefault();

        // Actualizar fotografía
        setFormData((prevData) => ({ ...prevData, logo: '' }));
        setFormData((prevData) => ({ ...prevData, logoStatus: 'change' }));
        setFotoStatus('change');
    }

    const onFileSelect = (event) => {
        setSelectedFile(event.files[0]);
    };

    const cancelar = (e) => {

        e.preventDefault();

        // Actualizar fotografía
        setFormData((prevData) => ({ ...prevData, foto: dataForm.foto }));
        setFormData((prevData) => ({ ...prevData, fotoStatus: 'asigned' }));
        setFotoStatus('asigned');

    }

    const formatRut = (rut) => {
        // Eliminar caracteres no numéricos
        const cleanedValue = rut.replace(/[^\d]/g, '');

        // Separar el RUT en parte numérica y dígito verificador
        const rutParteNumerica = cleanedValue.slice(0, -1);
        const rutDV = cleanedValue.slice(-1);

        // Aplicar formato con puntos y guión
        let formattedRut = '';
        let i = rutParteNumerica.length;

        while (i > 0) {
            formattedRut = rutParteNumerica.slice(Math.max(0, i - 3), i) + (formattedRut ? '.' + formattedRut : '');
            i -= 3;
        }

        return formattedRut + '-' + rutDV;
    };

    const save = async () => {

        //event.preventDefault(); // evita refresco de navegador
        setSubmitted(true);
        let api = '';

        if (editing) {
            api = await EmpresasService.actualizar(formData, selectedFile);
        } else {
            api = await EmpresasService.crear(formData, selectedFile);
        }


        console.log(api);
        if (api) {
            if (api.data.status === 'success') {
                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(true);
                setSubmitted(false);
                setEmpresaDialog(false);
                onHideDialog();
            } else {
                toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onRefresh(false);
                setSubmitted(false);
                setEmpresaDialog(false);
                onHideDialog();
            }
        }

    }

    const footerContent = (

        <div className='row'>

            <div className='col-md-7 text-muted' style={{ textAlign: 'left' }}>
                {dataForm && dataForm.created_id ? <small><i className='pi pi-user-plus' style={{ fontSize: '1rem' }}></i> {dataForm.created_name} el {dataForm.created_at}<br /></small> : ''}
                {dataForm && dataForm.updated_id !== null ? <small><i className='pi pi-user-edit' style={{ fontSize: '1rem' }}></i> {dataForm.updated_name} el {dataForm.updated_at}</small> : ''}
            </div>
            <div className='col-md-5' style={{ textAlign: 'right' }}>
                <Button label="Cancelar" icon="pi pi-times" onClick={hideDialog} className="p-button-text" />
                <Button label="Grabar" icon="pi pi-save" onClick={() => save()} />
            </div>
        </div>

    );

    useEffect(() => {
        setEmpresaDialog(statusDialog);

        if (statusDialog && editing && dataForm) {
            // Carga los datos de selectedRecord a formData
            setFormMode('edit');
            setFotoStatus(dataForm.logo === null || dataForm.logo === '' ? 'empty' : 'asigned');
            setFormData((prevData) => (
                {
                    ...prevData,
                    id: dataForm.id,
                    nombre: dataForm.nombre,
                    rut: `${dataForm.rut}-${dataForm.dv}`,
                    telefono: dataForm.telefono,
                    direccion: dataForm.direccion,
                    logo: dataForm.logo === '' ? '' : dataForm.logo,
                    logoStatus: dataForm.logo === '' || dataForm.logo === null ? '' : 'asigned',
                    created_id: dataForm.created_id,
                    created_at: dataForm.created_at,
                    created_name: dataForm.created_name,
                    updated_id: user ? user.id : '',
                    updated_at: dataForm.updated_at,
                    updated_name: dataForm.updated_name,
                    deleted_id: dataForm.deleted_id,
                    deleted_at: dataForm.deleted_at,
                    deleted_name: dataForm.deleted_name
                }));

        } else {
            // Estás en modo nuevo registro, puedes reiniciar el formulario si es necesario
            setFormMode('insert');
            setFotoStatus('empty');

            setFormData({
                id: '',
                nombre: '',
                rut: '',
                telefono: '',
                direccion: '',
                logo: '',
                logoStatus: '',
                created_id: user ? user.id : '',
                created_at: '',
                created_name: '',
                updated_id: '',
                updated_at: '',
                updated_name: '',
                deleted_id: '',
                deleted_at: '',
                deleted_name: ''
            });
        }

    }, [statusDialog, editing, dataForm]);

    useEffect(() => {
        // Actualizar empresa change
        setFormData((prevData) => ({ ...prevData, logo: selectedFile }));
    }, [selectedFile])

    useEffect(() => {

        // Actualizar empresa change
        //setFormData((prevData) => ({ ...prevData, foto: selectedFile }));

    }, [formData])

    const chooseOptions = { icon: 'pi pi-fw pi-images', iconOnly: true, className: 'custom-choose-btn p-button-rounded p-button-outlined' };
    const uploadOptions = { icon: 'pi pi-fw pi-cloud-upload', iconOnly: true, className: 'custom-upload-btn p-button-success p-button-rounded p-button-outlined', style: { display: 'none' } };
    const cancelOptions = { icon: 'pi pi-fw pi-times', iconOnly: true, className: 'custom-cancel-btn p-button-danger p-button-rounded p-button-outlined', style: { display: 'none' } };

    return (
        <div>
            <Toast ref={toast} />
            <Dialog header={headerTitle} maximizable visible={empresaDialog} footer={footerContent} onHide={hideDialog} style={{ width: '50vw' }}>

                <form>

                    <div className='row'>
                        <div className='col-6'>
                            {formMode === 'insert' || fotoStatus === 'change' ?
                                <>
                                    <FileUpload
                                        name='logo'
                                        accept='image/*'
                                        chooseOptions={chooseOptions}
                                        uploadOptions={uploadOptions}
                                        cancelOptions={cancelOptions}
                                        onSelect={onFileSelect}
                                    />
                                </>
                                :
                                <>

                                    <div className="d-flex justify-content-center align-items-center">
                                        <img src={decodificarImagen(formData.logo)} style={{ width: '180px', height: 'auto', border: '1px solid #ccc' }} alt={`Logo de ${formData.nombre}`} />
                                    </div>
                                    <p></p>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Button label="Cambiar Fotografía" severity="help" rounded className='mr-1' onClick={(e) => cambiarFoto(e)} />
                                    </div>
                                </>
                            }

                            {formMode === 'edit' && fotoStatus === 'change' ?
                                <>
                                    <p></p>
                                    <div className="d-flex justify-content-center align-items-center">
                                        <Button severity="secondary" rounded onClick={(e) => cancelar(e)}>Cancelar</Button>
                                    </div>
                                </>

                                : ''
                            }

                        </div>

                        <div className='col-6'>
                            <div className="form-group mb3">
                                <label htmlFor="rut" className="font-bold">RUT</label>
                                <InputText
                                    id="rut"
                                    name="rut"
                                    value={formData.rut}
                                    onChange={handleRutChange}
                                    required
                                    readOnly={editing}
                                    className='form-control' />
                                {submitted && !formData.rut && <small className="p-error">RUT es requerido.</small>}
                            </div>

                            <div className="form-group mb3">
                                <label htmlFor="nombre" className="font-bold">Nombre</label>
                                <InputText
                                    id="nombre"
                                    name="nombre"
                                    value={formData.nombre}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, nombre: e.target.value }))}
                                    required
                                    autoFocus
                                    className='form-control'
                                />
                                {submitted && !formData.nombre && <small className="p-error">Nombre es requerido.</small>}
                            </div>

                            <div className="form-group mb3">
                                <label htmlFor="direccion" className="font-bold">Dirección</label>
                                <InputText
                                    id="direccion"
                                    name="direccion"
                                    value={formData.direccion}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, direccion: e.target.value }))}
                                    required
                                    className='form-control' />
                                {submitted && !formData.direccion && <small className="p-error">Dirección es requerido.</small>}
                            </div>

                            <div className="form-group mb3">
                                <label htmlFor="telefono" className="font-bold">Teléfono</label>
                                <InputText
                                    id="telefono"
                                    name="telefono"
                                    value={formData.telefono}
                                    onChange={(e) => setFormData((prevData) => ({ ...prevData, telefono: e.target.value }))}
                                    required
                                    className='form-control' />
                                {submitted && !formData.telefono && <small className="p-error">Teléfono es requerido.</small>}
                            </div>
                        </div>
                    </div>
                </form>
            </Dialog>
        </div>
    )
}
