import React, { useEffect, useState, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import EmpresasService from '../services/EmpresasService';

export const DeleteForm = ({ visibleDeleteDialog, onHideDeleteDialog, onDeleteRefresh, dataForm, deleting }) => {

    const [empresaDeleteDialog, setEmpresaDeleteDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const reject = () => {
        onHideDeleteDialog();
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const accept = async() => {

        //event.preventDefault(); // evita refresco de navegador
        setSubmitted(true);
        console.log("eliminando");
        console.log(formData);

        setFormData((prevData) => (
            {
                ...prevData,
                id: dataForm.id,
                nombre: dataForm.nombre,
                rut: `${dataForm.rut}-${dataForm.dv}`,
                telefono: dataForm.telefono,
                direccion: dataForm.direccion,
                created_id: dataForm.created_id,
                created_at: dataForm.created_at,
                created_name: dataForm.created_name,
                updated_id: dataForm.updated_id,
                updated_at: dataForm.updated_at,
                updated_name: dataForm.updated_name,
                deleted_id: user.id,
                deleted_at: dataForm.deleted_at,
                deleted_name: dataForm.deleted_name
            })); 
            
        const api = await EmpresasService.eliminar(formData);

        console.log(api);
        if (api.data.status === 'success') {

            onDeleteRefresh(true);
            setSubmitted(false);
            setEmpresaDeleteDialog(false);
            onHideDeleteDialog();

            toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
        } else {
            toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
            onDeleteRefresh(false);
            setSubmitted(false);
            setEmpresaDeleteDialog(false);
            onHideDeleteDialog();
        }

        
    }

    const confirm2 = (nombre) => {
        confirmDialog({
            message: `¿Desea eliminar la empresa ${nombre}?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject
        });
    };

    const [formData, setFormData] = useState({
        id: '',
        nombre: '',
        rut: '',
        direccion: '',
        telefono: '',
        created_id: '',
        created_at: '',
        created_name: '',
        updated_id: '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: '',
    });




    const hideDialog = () => {
        setSubmitted(false);
        setEmpresaDeleteDialog(false);
        onHideDeleteDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };



    useEffect(() => {
        
        setEmpresaDeleteDialog(visibleDeleteDialog);

        if (visibleDeleteDialog && dataForm && deleting) {

            // Carga los datos de selectedRecord a formData


                    setFormData((prevData) => (
                        {
                            ...prevData,
                            id: dataForm.id,
                            nombre: dataForm.nombre,
                            rut: `${dataForm.rut}-${dataForm.dv}`,
                            telefono: dataForm.telefono,
                            direccion: dataForm.direccion,
                            created_id: dataForm.created_id,
                            created_at: dataForm.created_at,
                            created_name: dataForm.created_name,
                            updated_id: dataForm.updated_id,
                            updated_at: dataForm.updated_at,
                            updated_name: dataForm.updated_name,
                            deleted_id: user.id,
                            deleted_at: dataForm.deleted_at,
                            deleted_name: dataForm.deleted_name
                        })); 
                        
                    confirm2(dataForm.nombre);

                
        } else {
            // Estás en modo nuevo registro, puedes reiniciar el formulario si es necesario
            setFormData({});
        }


    }, [visibleDeleteDialog, dataForm, deleting]);

    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog visible={empresaDeleteDialog} onHide={hideDialog} />
        </div>
    )
}
