import React from 'react';
import cliente from '../services/baseURL.tsx';

class LoginService extends React.Component {

    ingresar = async (userData) => {

        try {

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };

            const response = await cliente.post('users/login',
                {
                    "email": userData.email,
                    "password": userData.password
                },
                config);

            return response;


        } catch (error) {
            console.error('Error al ingresar:', error);
            return false;
        }
    };

    recovery = async (userData) => {

        try {

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
            };

            const response = await cliente.post('users/recovery',
                {
                    "email": userData.email
                },
                config);

            return response;


        } catch (error) {
            console.error('Error al intentar recuperar la contraseña:', error);
            return false;
        }
    };

    change = async (userData) => {

        try {

            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('users/change',
                {
                    "email"     : userData.email,
                    "password"  : userData.password
                },
                config);

            return response;


        } catch (error) {
            console.error('Error al intentar cambiar la contraseña:', error);
            return false;
        }
    };
}

export default new LoginService();


