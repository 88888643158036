import React, { useEffect, useState, useRef } from 'react'
import EmpleadosService from '../services/EmpleadosService'
import { Toolbar } from 'primereact/toolbar';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { FilterMatchMode } from 'primereact/api';
import { Card } from 'primereact/card';
import { Message } from 'primereact/message';
import { Dialog } from 'primereact/dialog';
import { Editor } from "primereact/editor";

import AuthService from '../services/AuthService';
import { DataForm } from './DataForm';
import { DeleteForm } from './DeleteForm';
import { Avatar } from 'primereact/avatar';
import { Dropdown } from 'primereact/dropdown';
import { IconField } from 'primereact/iconfield';
import { InputIcon } from 'primereact/inputicon';

// import "primereact/resources/themes/lara-light-cyan/theme.css";

export const Externos = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(AuthService.isAuthenticated);
  const [load, setLoad] = useState(true);
  const [empleados, setEmpleados] = useState([]);
  const [usuario, setUsuario] = useState([]);
  const [editing, setEditing] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [statusDialog, setStatusDialog] = useState(false);
  const [statusDeleteDialog, setStatusDeleteDialog] = useState(false);
  const [visibleMotivo, setVisibleMotivo] = useState(false);
  const [motivoBloqueo, setMotivoBloqueo] = useState('');
  const [fotoFilter, setFotoFilter] = useState(null);
  const [statusFoto] = useState([
    { name: 'Todos', value: 'todos' }, // Opción para filtrar registros sin foto
    { name: 'Con Fotografía', value: 'con_foto' }, // Opción para filtrar registros con foto
    { name: 'Sin Fotografía', value: 'sin_foto' }, // Opción para filtrar registros con foto
  ]);

  const dt = useRef(null);

  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    valida_foto: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });


  const rutTemplate = (rowData) => {
    return <span>{rowData.rut}-{rowData.dv}</span>;
  };

  const motivoTemplate = (rowData) => {
    return (<>
      { parseInt(rowData.bloqueado) === 1 ?
        <Button label='Ver' icon="pi pi-external-link" onClick={() => {setMotivoBloqueo(rowData.motivo_bloqueo); setVisibleMotivo(true)}}/> :
        ''
      }
      </>);
  }

  const choferTemplate = (rowData) => {
    return (
      <>
       { parseInt(rowData.es_chofer) === 0 ? <Message severity="error" text="" /> : <Message severity="success" text="" />}
      </>);

  }

  const residenteTemplate = (rowData) => {
    return (
      <> 
        { parseInt(rowData.es_residente) === 0 ? <Message severity="error" text="" /> : <Message severity="success" text="" />}
      </>);
  }

  const bloqueadoTemplate = (rowData) => {
    return (
      <> 
        {parseInt(rowData.bloqueado) === 1 ? <Message severity="error" text="BLOQUEADO" /> : <Message severity="success" text="ACTIVO" />}
      </>);
  }

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" rounded severity="info" className="mr-2" onClick={() => editEmpresa(rowData)} tooltip='MODIFICAR EMPLEADO' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />
        <Button icon="pi pi-trash" rounded severity="danger" onClick={() => confirmDeleteUsuario(rowData)} tooltip='ELIMINAR EMPLEADO' tooltipOptions={{ position: 'bottom', mouseTrack: true, mouseTrackTop: 15 }} />

      </React.Fragment>
    );
  };


  const obtener = async () => {

    if (isAuthenticated) {

      const respuesta = await EmpleadosService.obtener();

      if (respuesta) {
        setEmpleados(respuesta.data.empleados);
      }

    } else {

      window.location.reload();
    }
  }

  const addUsuario = () => {
    setStatusDialog(true);
    setStatusDeleteDialog(false);
    setEditing(false);
    setUsuario(null);

  }

  const onHideDialog = () => {
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setStatusDialog(false);
  };

  const onHideDeleteDialog = () => {
    setStatusDeleteDialog(false);
    setStatusDialog(false);
  };

  const onRefresh = (e) => {

    setLoad(e);
    setStatusDialog(false);
    setStatusDeleteDialog(false);
    setDeleting(false);
    setEditing(false);
  }

  const onDeleteRefresh = (e) => {

    setLoad(e);
    setStatusDeleteDialog(false);
    setDeleting(false);
    setStatusDialog(false);
  }


  const editEmpresa = (rowData) => {

    setStatusDialog(true);
    setStatusDeleteDialog(false);
    setEditing(true);
    setDeleting(false);
    setUsuario(rowData);
  }

  const confirmDeleteUsuario = (rowData) => {

    setStatusDeleteDialog(true);
    setDeleting(true);
    setUsuario(rowData);

  }

  const onFotoFilterChange = (e) => {
    console.log(e.value);
    setFotoFilter(e.value);
    let _filters = { ...filters };

    if(e.value === 'todos'){
      _filters['valida_foto'].value = 'foto';
      _filters['valida_foto'].matchMode = FilterMatchMode.CONTAINS;

    } else if (e.value === 'sin_foto'){
      _filters['valida_foto'].value = e.value
      _filters['valida_foto'].matchMode = FilterMatchMode.CONTAINS;
    } else {
      _filters['valida_foto'].value = 'con_foto'
      _filters['valida_foto'].matchMode = FilterMatchMode.CONTAINS;
    }
    
    setFilters(_filters);

  };

  const onGlobalFilterChange = (event) => {
    const value = event.target.value;
    let _filters = { ...filters };

    _filters['global'].value = value;

    setFilters(_filters);
    console.log(filters);
  };

  //const exportColumns = empresas.map((col) => ({ title: col.header, dataKey: col.field }));

  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const renderHeader = () => {
    const value = filters['global'] ? filters['global'].value : '';

    return (
      <>

        <span className="p-input-icon-left mr-2">
        <IconField iconPosition='left'>
          <InputIcon className="pi pi-search" />
          <InputText type="search" value={value || ''} onChange={(e) => onGlobalFilterChange(e)} placeholder="Buscar" />
          </IconField>
        </span>

        <Dropdown 
          options={statusFoto} 
          optionLabel='name' 
          value={fotoFilter}
          onChange={onFotoFilterChange} 
          placeholder="Filtro Foto" 
          className="p-column-filter" 
          showClear 
          style={{ minWidth: '12rem' }} />
      </>

    );
  };

  const leftToolbarTemplate = () => {
    return (
      <div className="flex flex-wrap gap-2">
        <Button label="Nuevo" icon="pi pi-plus" severity="success" onClick={() => addUsuario()} />
      </div>
    );
  };

  const rightToolbarTemplate = () => {
    return (
      <div className="flex align-items-center justify-content-end gap-2">
        <Button type="button" icon="pi pi-file-excel" label='Exportar' rounded onClick={() => exportCSV()} data-pr-tooltip="CSV" />
      </div>
    );
  };

  const decodificarImagen = (foto) => {
    if (foto === null) {
      return '/assets/avatars/user.png'; // Devuelve una imagen por defecto para registros sin foto
    }
    if (typeof foto === 'string') {
      const tipoArchivo = foto.split(';')[0].split(':')[1];
      const base64Data = foto.split(',')[1];
      return `data:${tipoArchivo};base64,${base64Data}`;
    } else {
      return '/assets/avatars/user.png'; // Devuelve una imagen por defecto para casos inesperados
    }
  };
  
  const fotografiaTemplate = (rowData) => {
    return (
      <div style={{ justifyContent: 'center', textAlign: 'center'}}>
        {
          rowData.foto !== '' && rowData !== null ?
            <Avatar image={decodificarImagen(rowData.foto)} size="xlarge" shape="circle" alt={rowData.name} />
            :
            <Avatar image="/assets/avatars/user.png" size="xlarge" shape="circle" alt={rowData.name} />
        }
      </div>
    )
  };

  const fotografiaValidaTemplate = (rowData) => {
    return (
      <>
        {
          rowData.foto !== '' && rowData.foto !== null ?
            <span>si</span>
            :
            <span>no</span>
            // <Avatar image="/assets/avatars/user.png" size="xlarge" shape="circle" alt={rowData.name} />
        }
      </>
    )
  };


  const header = renderHeader();

  useEffect(() => {
    // Este bloque de código se ejecutará cada vez que cambie AuthService.isAuthenticated
    if (AuthService.isAuthenticated) {
      // Lógica cuando isAuthenticated es true
    } else {
      // Lógica cuando isAuthenticated es false
      window.location.reload();
    }
  }, [AuthService.isAuthenticated]);

  useEffect(() => {

  }, [filters]);

  useEffect(() => {

    if (load === true) {
      obtener();

      setLoad(false);
    }

  }, [load]);

  return (
    <div className='row justify-content-center'>
      <div className='col-12'>
        <h1>Externos</h1>
        <p>Lista de personas externas registradas</p>
        <div className='row'>
          <div className='col-12 my-1'>

            <Card>

              <Toolbar className="mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar>

              <DataTable
                ref={dt}
                value={empleados}
                header={header}
                exportFilename='Empleados'
                csvSeparator=';'
                emptyMessage="No existen empleados ingresados"
                filters={filters}
                globalFilterFields={['nombre', 'rut', 'fotoValida', 'foto']}
                paginator 
                rows={10}
                rowsPerPageOptions={[5, 10, 25, 50]}
                size='small'
              >
                <Column 
                  body={fotografiaTemplate} 
                  field='foto' 
                  header="Fotografía" 
                  showFilterMenu={false}
                  style={{ minWidth: '6rem' }} 
                  exportable={false}
                  alignHeader={'center'}
                />

                <Column 
                  body={fotografiaValidaTemplate} 
                  field='fotoValida' 
                  header="fotoValida" 
                  style={{ display: 'none' }}
                  exportable={false} 
                />

                <Column field="nombre" header="Nombre"></Column>
                <Column body={rutTemplate} header="Rut"></Column>
                <Column field="rut" header="Rut" style={{ display: 'none' }} exportable={false}></Column>
                <Column field="telefono" header="Teléfono" style={{ display: 'none' }}></Column>
                <Column field="nombre_empresa" header="Empresa"></Column>
                <Column field="contrato_tipo_nombre" header="Tipo Contrato"></Column>
                <Column
                  body={choferTemplate}
                  header="Chofer?"
                  alignHeader={'center'}
                  bodyStyle={{ textAlign: 'center' }}
                />
                <Column
                  body={residenteTemplate}
                  header="Residente?"
                  alignHeader={'center'}
                  bodyStyle={{ textAlign: 'center' }}
                />
                <Column 
                  body={bloqueadoTemplate} 
                  header="Estado"
                  alignHeader={'center'}
                  bodyStyle={{ textAlign: 'center' }}
                />
                <Column 
                  body={motivoTemplate} 
                  header="Motivo bloqueo"
                  headerStyle={{}}
                  alignHeader={'center'}
                  bodyStyle={{ textAlign: 'center' }}
                />
                <Column
                  body={actionBodyTemplate}
                  header="Acciones"
                  exportable={false}
                  style={{ minWidth: '12rem' }}
                  alignHeader={'center'}
                  bodyStyle={{ textAlign: 'center' }}
                />
              </DataTable>

            </Card>

            <DataForm
              statusDialog={statusDialog}
              onHideDialog={onHideDialog}
              onRefresh={onRefresh}
              dataForm={usuario}
              editing={editing}
              headerTitle={editing ? 'Editar empleado' : 'Nuevo empleado'}
              loadForm={true}
            />

            <DeleteForm
              visibleDeleteDialog={statusDeleteDialog}
              onHideDeleteDialog={onHideDeleteDialog}
              onDeleteRefresh={onDeleteRefresh}
              dataForm={usuario}
              deleting={deleting}
              loadForm={true}
            />

            <Dialog header="Motivo de bloqueo" maximizable  visible={visibleMotivo} style={{ width: '50vw' }} onHide={() => setVisibleMotivo(false)}>
            <p className="m-0">
              <Editor value={motivoBloqueo} readOnly/>
                
            </p>
            </Dialog>
          </div>
        </div>
      </div>
    </div>
  )
}
