import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode'; // Cambiado de qrcode.react a qrcode
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import logo from '../../assets/images/alpina.png'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const ReporteCredencialPDF = ({ generateCredencial }) => {
  const [credenciales, setCredenciales] = useState([]);
  const [tipoContrato, setTipoContrato] = useState('');
  const [generaState, setGeneraState] = useState(false);


  const formatRut = (rut) => {
    // Eliminar caracteres no numéricos
    const cleanedValue = rut.replace(/[^kK\d]/g, '');

    // Separar el RUT en parte numérica y dígito verificador
    const rutParteNumerica = cleanedValue.slice(0, -1);
    const rutDV = cleanedValue.slice(-1);

    // Aplicar formato con puntos y guión
    let formattedRut = '';
    let i = rutParteNumerica.length;

    while (i > 0) {
      formattedRut = rutParteNumerica.slice(Math.max(0, i - 3), i) + (formattedRut ? '.' + formattedRut : '');
      i -= 3;
    }

    return formattedRut + '-' + rutDV;
  };

  const generatePDF = async () => {
    try {

      // Definir la estructura del documento PDF
      const documentDefinition = {
        content: [
          { text: `Listado de Credenciales ${tipoContrato}`, style: 'header' },
          {
            table: {
              widths: [30, 50, 50, 70, 200, 60],
              margin: [0, 0, 0, 0],
              padding: [0, 0, 0, 0],
              body: [
                [ 
                  { text: 'Foto', fontSize: 10, bold: true, alignment: 'center', fillColor: '#f4f4f4', margin: [0, 5]  }, 
                  { text: 'Código credencial', fontSize: 10, bold: true, alignment: 'center', fillColor: '#f4f4f4' }, 
                  { text: 'Tipo credencial', fontSize: 10, bold: true, alignment: 'center', fillColor: '#f4f4f4' }, 
                  { text: 'Disponibilidad', fontSize: 10, bold: true, alignment: 'center', fillColor: '#f4f4f4', margin: [0, 5] }, 
                  { text: 'Nombre', fontSize: 10, bold: true, fillColor: '#f4f4f4', margin: [0, 5] }, 
                  { text: 'RUT', fontSize: 10, bold: true, fillColor: '#f4f4f4', margin: [0, 5] },                   
                ],
                ...credenciales.map(row => {
                    return [
                      row.foto ? {image: row.foto, width: 15, height: 15, alignment: 'center' } : '',
                      { text: String(row.id).padStart(7, '0'), fontSize: 10, alignment: 'center', margin: [0, 5] },
                      { text: row.tipo_contrato, fontSize: 10, alignment: 'center', margin: [0, 5] },
                      parseInt(row.disponible) === 0 ? 
                        { text: 'OCUPADA', fillColor: '#2097f3', color: 'white', bold: true, alignment: 'center', fontSize: 10, margin: [0, 5] } 
                      : 
                        { text: 'DISPONIBLE', fillColor: '#63a239', color: 'white', bold: true, alignment: 'center', fontSize: 10, margin: [0, 5] },
                      { text: row.nombre, fontSize: 10, margin: [0, 5] },
                      { text: row.rut ? `${row.rut}-${row.dv}`: '', fontSize: 10, margin: [0, 5] },                      
                    ]
                  })
                ]
            },
            layout: {
              // Cambia el color de las líneas horizontales de la tabla
              hLineColor: function (i, node) {
                return '#e8e8e8'; // Color de las líneas horizontales (rojo anaranjado)
              },
              // Cambia el color de las líneas verticales de la tabla
              vLineColor: function (i, node) {
                return '#e8e8e8'; // Color de las líneas verticales (rojo anaranjado)
              },
              // Cambia el grosor de las líneas horizontales
              hLineWidth: function (i, node) {
                return 1; // Grosor de las líneas horizontales
              },
              // Cambia el grosor de las líneas verticales
              vLineWidth: function (i, node) {
                return 1; // Grosor de las líneas verticales
              }
            }
          },
        ],
        styles: {
          header: {
              fontSize: 18,
              bold: true,
              margin: [0, 0, 0, 10]
          }, 
          tableHeader: {
            color: '#e8e8e8'
          }
      }
      };

      // Generar el PDF y abrirlo en una nueva ventana
      pdfMake.createPdf(documentDefinition).open();
      setGeneraState(false);
    } catch (error) {
      //console.error('Error al generar el código QR y el PDF', error);
      setGeneraState(false);
    }
  };

  useEffect(() => {

    if (generateCredencial.loadPDF === true) {
      
      setCredenciales(generateCredencial.credenciales);
      setTipoContrato(parseInt(generateCredencial.contratoTipo) === 1 ? 'Contrato Indefinido' : parseInt(generateCredencial.contratoTipo) === 2 ? 'Contrato Plazo Fijo' : 'Externos');
      setGeneraState(true);

    }

  }, [generateCredencial]);

  useEffect(() => {
    // reset form
    if (generaState) {
      generatePDF();
    }

  }, [generaState]);


}



