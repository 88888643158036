import axios from "axios";


//const urlApi = 'http://api.accesos:8080/api/'; //cambiar en producción
const urlApi = 'https://api.empresaslaalpina.cl/api/'; //cambiar en producción

const cliente = axios.create({ baseURL: urlApi, responseType: 'json' });

export default cliente;



