
class AuthService {
    static isAuthenticated() {
      const token = localStorage.getItem('token');
      
      const expiracion = localStorage.getItem('expiracion');
      const tokenExpirationDate = new Date(expiracion);
      const currentDate = new Date(); // Obtener la fecha actual
      
      // Comparar las fechas

      if(token){
        
        if (currentDate > tokenExpirationDate) {
              // La fecha almacenada ha pasado
              localStorage.removeItem('token');
              localStorage.removeItem('usuario');
              localStorage.removeItem('expiracion');
              return false;
            } else {
              // La fecha almacenada aún no ha pasado
              return true;
            }

      } else {
        return false;
      }

    }
  
  
    static logout() {
      localStorage.removeItem('token');
      localStorage.removeItem('usuario');
      localStorage.removeItem('expiracion');
    }
  }
  
  export default AuthService;