import React, { useEffect, useState, useRef } from 'react';
import { ConfirmDialog, confirmDialog } from 'primereact/confirmdialog';
import { Toast } from 'primereact/toast';
import UsuariosService from '../services/UsuariosService';

export const DeleteForm = ({ visibleDeleteDialog, onHideDeleteDialog, onDeleteRefresh, dataForm, deleting }) => {

    const [usuarioDeleteDialog, setUsuarioDeleteDialog] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [formData, setFormData] = useState({
        id: '',
        nombre: '',
        rut: '',
        direccion: '',
        telefono: '',
        created_id: '',
        created_at: '',
        created_name: '',
        updated_id: '',
        updated_at: '',
        updated_name: '',
        deleted_id: '',
        deleted_at: '',
        deleted_name: '',
    });

    const user = JSON.parse(localStorage.getItem('usuario'));
    const toast = useRef(null);

    const reject = () => {
        onHideDeleteDialog();
        //toast.current.show({ severity: 'warn', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
    }

    const accept = async () => {

        //event.preventDefault(); // evita refresco de navegador
        setSubmitted(true);

        const data = {
            id: dataForm.id,
            deleted_id: user ? user.id : '',
        }


        if (data.id !== "") {
            const api = await UsuariosService.eliminar(data);

            if (api.data.status === 'success') {

                onDeleteRefresh(true);
                setSubmitted(false);
                setUsuarioDeleteDialog(false);
                onHideDeleteDialog();

                toast.current.show({ severity: 'success', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
            } else {
                toast.current.show({ severity: 'error', summary: 'Mensaje', detail: `${api.data.message}`, life: 3000 });
                onDeleteRefresh(false);
                setSubmitted(false);
                setUsuarioDeleteDialog(false);
                onHideDeleteDialog();
            }
        }




    }

    const confirm2 = (nombre) => {
        confirmDialog({
            message: `¿Desea eliminar al usuario ${nombre}?`,
            header: 'Confirmación',
            icon: 'pi pi-info-circle',
            acceptClassName: 'p-button-danger',
            accept,
            reject,
            rejectLabel: 'Cancelar',
            acceptLabel: 'Eliminar'
        });
    };

    const hideDialog = () => {
        setSubmitted(false);
        setUsuarioDeleteDialog(false);
        onHideDeleteDialog(); // Notifica al módulo padre que el Dialog se ha ocultado
    };


    useEffect(() => {

        setUsuarioDeleteDialog(visibleDeleteDialog);

        if (visibleDeleteDialog && dataForm && deleting) {

            // Carga los datos de selectedRecord a formData
            confirm2(dataForm.name);

        }


    }, [visibleDeleteDialog, dataForm, deleting]);


    return (
        <div>
            <Toast ref={toast} />
            <ConfirmDialog visible={usuarioDeleteDialog} onHide={hideDialog} />
        </div>
    )
}
