import React from 'react';
import cliente from './baseURL.tsx';

class UsuariosTipoService extends React.Component {
  
    obtener = async () => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('usuarios/tipos', {}, config);

            return response;

                
        } catch (error) {
            console.error('Error al obtener tipos de usuarios', error);
            return false;
        }
  };

    crear = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empresas/crear', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al crear empresa', error);
            return false;
        }
    };


    actualizar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empresas/actualizar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al actualizar empresa', error);
            return false;
        }
    };
    
    
    eliminar = async (data) => {

        try {
            const token = localStorage.getItem('token');

            const config = {
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`
                },
            };

            const response = await cliente.post('empresas/eliminar', {
                data
            }, config);

            return response;

                
        } catch (error) {
            console.error('Error al eliminar empresa', error);
            return false;
        }
    };    

}

export default new UsuariosTipoService();